import { useEffect, useState } from 'react';

const getStorageValue = <T>(key: string) => {
  const saved = localStorage.getItem(key);
  if (saved) {
    return JSON.parse(saved) as T;
  }
  return null;
};

const useLocalStorage = <T>(key: string, defaultStorageState: T) => {
  const initStorageState = getStorageValue<T>(key);
  const [value, setValue] = useState<T>(initStorageState ?? defaultStorageState);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};

export { useLocalStorage };
