import React from 'react';
import Checkbox from '@ingka/checkbox';
import RadioButtonGroup from '@ingka/radio-button-group';
import { FilterInput, SortInput } from '__generated__/types';
import { HFBDropdown } from './HFBDropdown';
import { SortDropdown } from './SortDropdown';
import * as Styled from './styled';

type ValidityFilterProps = {
  type: string;
  filter: FilterInput;
  sort: SortInput;
  onFilterChange: <K extends keyof FilterInput>(key: K, value: FilterInput[K]) => void;
  onSortChange: (sort: SortInput) => void;
};

export const ValidityFilter: React.FC<ValidityFilterProps> = ({ type, filter, sort, onFilterChange, onSortChange }) => {
  const optimizedArray = [
    {
      id: 'optimized=none',
      name: 'optimized',
      label: 'None',
      text: 'optimized=none',
      onChange: () => onFilterChange('optimized', null),
      checked: filter.optimized === null,
    },
    {
      id: 'optimized=filter',
      name: 'optimized',
      label: type !== 'filter' ? 'Optimized' : 'Published',
      text: 'optimized=filter',
      onChange: () => onFilterChange('optimized', true),
      checked: filter.optimized === true,
    },
    {
      id: 'nonOptimized=filter',
      name: 'optimized',
      label: type !== 'filter' ? 'Non-Optimized' : 'Not Published',
      text: 'nonOptimized=filter',
      onChange: () => onFilterChange('optimized', false),
      checked: filter.optimized === false,
    },
  ];

  const validArray = [
    {
      id: 'valid=none',
      name: 'valid',
      label: 'None',
      text: 'valid=none',
      onChange: () => onFilterChange('valid', null),
      checked: filter.valid === null,
    },
    {
      id: 'valid=filter',
      name: 'valid',
      label: 'Valid Length of Title & Description',
      text: 'valid=filter',
      onChange: () => onFilterChange('valid', true),
      checked: filter.valid === true,
    },
    {
      id: 'invalid=filter',
      name: 'valid',
      label: 'Invalid Length of Title & Description',
      text: 'invalid=filter',
      onChange: () => onFilterChange('valid', false),
      checked: filter.valid === false,
    },
  ];

  const selfCanonicalArray = [
    {
      id: 'selfCanonical=none',
      name: 'selfCanonical',
      label: 'None',
      text: 'selfCanonical=none',
      onChange: () => onFilterChange('selfCanonicalized', null),
      checked: filter.selfCanonicalized === null,
    },
    {
      id: 'selfCanonical=filter',
      name: 'selfCanonical',
      label: 'Self Canonicalized',
      text: 'selfCanonical=filter',
      onChange: () => onFilterChange('selfCanonicalized', true),
      checked: filter.selfCanonicalized === true,
    },
    {
      id: 'nonselfCanonical=filter',
      name: 'selfCanonical',
      label: 'Non-self Canonicalized',
      text: 'nonselfCanonical=filter',
      onChange: () => onFilterChange('selfCanonicalized', false),
      checked: filter.selfCanonicalized === false,
    },
  ];

  const hasProductsArray = [
    {
      id: 'hasProducts=none',
      name: 'hasProducts',
      label: 'None',
      text: 'hasProducts=none',
      onChange: () => onFilterChange('hasProducts', null),
      checked: filter.hasProducts === null,
    },
    {
      id: 'hasProducts=filter',
      name: 'hasProducts',
      label: 'Has Products',
      text: 'hasProducts=filter',
      onChange: () => onFilterChange('hasProducts', true),
      checked: filter.hasProducts === true,
    },
    {
      id: 'hasNoProducts=filter',
      name: 'hasProducts',
      label: 'Has No Products',
      text: 'hasNoProducts=filter',
      onChange: () => onFilterChange('hasProducts', false),
      checked: filter.hasProducts === false,
    },
  ];

  return (
    <>
      <Styled.ValidityFilterWrapper>
        <div>
          <RadioButtonGroup name={type !== 'filter' ? 'Optimized' : 'Published'} list={optimizedArray} />
          <Styled.ValidityFilterCheckboxWrapper>
            <Checkbox
              name={'needsReview=filter'}
              key={'needsReview=filter'}
              id={'needsReview=filter'}
              checked={!!filter.needsReview}
              label={'Needs Review'}
              value={'needsReview'}
              onChange={() => onFilterChange('needsReview', !filter.needsReview)}
            />
          </Styled.ValidityFilterCheckboxWrapper>
        </div>

        <RadioButtonGroup name={'Valid'} list={validArray} />
        <RadioButtonGroup name={'Self Canonicalized'} list={selfCanonicalArray} />
        {type === 'PLP' && <RadioButtonGroup name={'Has Products'} list={hasProductsArray} />}
      </Styled.ValidityFilterWrapper>

      {type === 'PIP' && (
        <Styled.ValidityFilterDropdown>
          <HFBDropdown value={filter.homeFurnishingBusinessId ?? ''} onChange={({ value }) => onFilterChange('homeFurnishingBusinessId', value)} />
        </Styled.ValidityFilterDropdown>
      )}

      {type === 'PLP' && (
        <Styled.ValidityFilterDropdown>
          <SortDropdown sort={sort} onSortChange={onSortChange} />
        </Styled.ValidityFilterDropdown>
      )}
    </>
  );
};
