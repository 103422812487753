import { AnyAction, combineReducers, configureStore, getDefaultMiddleware, ThunkDispatch } from '@reduxjs/toolkit';
import { instance } from 'gaxios';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { effects, LoginState } from './reducers/login';
import reducers from './reducers';

const combinedReducers = combineReducers(reducers);
const persistConfig = {
  whitelist: ['login'],
  version: 1,
  storage: sessionStorage,
  key: 'root',
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

instance.defaults = {
  validateStatus: (status: number) => {
    if (status === 401) {
      store.dispatch(effects.logoutAsync());
    }
    return true;
  },
};

export const persistor = persistStore(store);

export interface RootState {
  login: LoginState;
}

export default store;
