import React, { useState } from 'react';
import globe from '@ingka/ssr-icon/paths/globe';
import { useCountry } from 'hooks/useCountry';
import { CountrySelectModal } from './CountrySelectModal';
import * as Styled from './styles';

type SelectCountryButtonProps = {
  inverseTheme?: boolean;
};

export const CountrySelectButton: React.FC<SelectCountryButtonProps> = ({ inverseTheme }) => {
  const [country] = useCountry();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Styled.CountrySelectButton
        text={country?.countryName ?? 'Select Country'}
        type={'secondary'}
        inverseTheme={!!inverseTheme}
        size={'small'}
        ssrIcon={globe}
        onClick={() => setIsOpen(true)}
      />
      <CountrySelectModal isOpen={isOpen} toggleIsOpen={() => setIsOpen(!isOpen)} />
    </>
  );
};
