import React, { useState } from 'react';
import Button from '@ingka/button';
import plus from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';
import { v4 as uuid } from 'uuid';
import { MetaDataEntryInput, useSaveNewEntriesMutation } from '__generated__/types';
import { FilteredPagesInputCard } from './FilteredPagesInputCard';
import { InfoModalButton } from './InfoModalButton';
import * as Styled from './styled';

export type FilteredPageURL = {
  key: string;
  inputUrl: string;
  h1: string;
  urlSlug: string;
  title: string;
  preview: string;
  globalId: string;
  isValid?: boolean;
};

export const FilteredPages: React.FC = () => {
  const createNewEmptyEntry = () => ({
    key: uuid(),
    inputUrl: '',
    h1: '',
    urlSlug: '',
    title: '',
    preview: '',
    globalId: '',
    isValid: true,
  });

  const [filteredPageEntries, setFilteredPageEntries] = useState<FilteredPageURL[]>([createNewEmptyEntry()]);
  const [saveEntries, { loading, error }] = useSaveNewEntriesMutation();

  const onChangedEntry = (entry: FilteredPageURL) => {
    setFilteredPageEntries((filteredPageEntries) => {
      const newFiltered = [...filteredPageEntries];
      const index = newFiltered.findIndex((e) => e.key === entry.key);
      newFiltered[index] = { ...entry };
      return newFiltered;
    });
  };

  const clearEntryFields = (entry: FilteredPageURL) => {
    setFilteredPageEntries((filteredPageEntries) => {
      const newFiltered = [...filteredPageEntries];
      newFiltered[newFiltered.indexOf(entry)] = { ...createNewEmptyEntry(), key: entry.key };
      return newFiltered;
    });
  };

  const addEmptyEntry = () => {
    setFilteredPageEntries([...filteredPageEntries, { ...createNewEmptyEntry() }]);
  };

  const save = async (entries: FilteredPageURL[]) => {
    const filteredInputs: MetaDataEntryInput[] = entries.map((entry) => ({
      canonical: entry.preview,
      categoryId: entry.globalId.split('-')[0],
      filterId: entry.globalId.split('-')[1],
      id: entry.globalId,
      h1: entry.h1,
      pageTitle: entry.title,
      referenceUrl: entry.inputUrl,
      urlSlug: entry.urlSlug,
    }));
    return await saveEntries({ variables: { entries: filteredInputs } });
  };

  const isDeleteButtonDisabled = (entry: FilteredPageURL, index: number) =>
    index === 0 && filteredPageEntries.length === 1
      ? Object.entries(entry)
          .filter(([key]) => key !== 'key')
          .every(([, value]) => !value)
      : false;

  const hasAllEntriesFieldsFilled = filteredPageEntries.every((entry) => {
    if (entry.isValid) {
      return Object.values(entry).every((field) => field);
    }
  });
  return (
    <>
      <Text tagName="h1" headingSize="s">
        Add URLs for Filtered Pages
      </Text>
      <Text tagName="span" bodySize="m">
        To create entries for Filtered Pages please add the URL in the form below. In order to add more than one URL at a time, use the Add More
        button. The new entries will be added to Country Zero as master entries and then made available in the respective markets for localization and
        editing according to the market&apos;s specific needs.
      </Text>
      <Styled.InputContainer>
        {filteredPageEntries.map((entry, index) => (
          <FilteredPagesInputCard
            key={entry.key}
            entry={entry}
            disabled={loading}
            index={index}
            onChangedEntry={onChangedEntry}
            isDeleteButtonDisabled={isDeleteButtonDisabled(entry, index)}
            onClickDelete={() => {
              if (filteredPageEntries.length === 1) {
                clearEntryFields(entry);
              } else {
                setFilteredPageEntries(filteredPageEntries.filter((e) => e.key !== entry.key));
              }
            }}
          />
        ))}
      </Styled.InputContainer>
      <Styled.ButtonRow>
        <Button type="secondary" size={'small'} text="Add page" ssrIcon={plus} onClick={addEmptyEntry} />
        <InfoModalButton
          buttonText={`Save ${filteredPageEntries.length > 1 ? 'pages' : 'page'}`}
          disabled={!hasAllEntriesFieldsFilled}
          onClick={async () => {
            const res = await save(filteredPageEntries);
            if (!res) {
              return;
            }
            setFilteredPageEntries([createNewEmptyEntry()]);
          }}
          isLoading={loading}
          errorMessage={error?.message}
        />
      </Styled.ButtonRow>
    </>
  );
};
