import { MetaDataCompleteFragment } from './__generated__/types';

export interface ValidationOptions {
  min?: number;
  max?: number;
}

type ValidationRules = {
  [P in keyof Omit<MetaDataCompleteFragment, '__typename'>]?: ValidationOptions;
};
const envValidation = JSON.parse(process.env.REACT_APP_VALIDATION_OPTIONS || '{}');
const validation: ValidationRules = {
  pageTitle: { min: 1, max: 60 },
  description: { min: 1 },
  ...envValidation,
};
export const environment = {
  serviceUrl: process.env.REACT_APP_OPTIMERA_SERVICE_URL || '/api',
  backendLoginPath: '/auth/login',
  validation: validation,
};
