import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'components/common/Container';
import { LineChart } from 'components/sitemaps/LineChart';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';

interface Market {
  country: string;
  language: string;
}

interface Detail {
  total?: number;
  products?: number;
  editorials?: number;
  catalogs?: number;
  series?: number;
  collections?: number;
  planners?: number;
  homepages?: number;
  contentgalleries?: number;
  catalogfilters?: number;
  knowledge?: number;
}

interface DetailsResponse {
  timestamp: string;
  detail: Detail;
}

export const SitemapStatsDetailView: React.FC = () => {
  const marketParams = useParams() as Market;
  const { country, language } = marketParams;
  const { data: details } = useRequestWithTokenAndTransformer<[timestamp: string, details: Detail][], DetailsResponse[]>(
    { url: `/api/sitemaps/stats/aggregated/${country}/${language}` },
    [country, language],
    (res) => {
      return res.map(([timestamp, detail]) => ({
        timestamp,
        detail,
      }));
    }
  );

  const labels = details?.map((d) => d.timestamp) || [];

  const value = (prop: keyof Detail) => (details || []).map((v) => v.detail[prop] || 0);
  return details ? (
    <Container page='sitemaps'>
      <h1>Statistics</h1>
      <h2>
        for {language}-{country}
      </h2>
      <LineChart data={value('total')} labels={labels} title={'Total urls'} />
      <LineChart data={value('products')} labels={labels} title={'Product urls'} />
      <LineChart data={value('editorials')} labels={labels} title={'Editorial urls'} />
      <LineChart data={value('catalogs')} labels={labels} title={'Catalog urls'} />
      <LineChart data={value('collections')} labels={labels} title={'Collections urls'} />
      <LineChart data={value('series')} labels={labels} title={'Series urls'} />
      <LineChart data={value('planners')} labels={labels} title={'Planner urls'} />
      <LineChart data={value('homepages')} labels={labels} title={'Homepage urls'} />
      <LineChart data={value('catalogfilters')} labels={labels} title={'Catalog filter urls'} />
      <LineChart data={value('knowledge')} labels={labels} title={'Knowledge Base (VETA) urls'} />
    </Container>
  ) : null;
};
