import React, { Component, ReactNode } from 'react';
import InlineMessage from '@ingka/inline-message';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <InlineMessage
          ssrIcon={warningTriangleIcon}
          variant={'cautionary'}
          title={'Oops! Something went wrong :('}
          body={'Something went wrong with Optimera, please contact support.'}
          link={'https://ingka.slack.com/archives/C03098HRAJW'}
          newWindow
          linkLabel={'Support Slack Channel'}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
