import React from 'react';
import Pill from '@ingka/pill';
import crossIcon from '@ingka/ssr-icon/paths/cross-small';
import Text from '@ingka/text';
import { FilterInput, SortInput } from '__generated__/types';
import { getHFBFieldLabel, getSortFieldLabel } from './fieldlabels';
import * as Styled from './styled';

type ActiveFiltersRowProps = {
  filter: FilterInput;
  sort: SortInput;
  defaultFilters: FilterInput;
  defaultSort: SortInput;
  deselectFilter: (key: keyof FilterInput) => void;
  clearFilter: () => void;
  clearSort: () => void;
};

export const ActiveFiltersRow: React.FC<ActiveFiltersRowProps> = ({
  filter,
  sort,
  defaultFilters,
  defaultSort,
  deselectFilter,
  clearFilter,
  clearSort,
}) => {
  const { homeFurnishingBusinessId, valid, selfCanonicalized, hasProducts, optimized, needsReview, pageSize } = filter;
  const comparableFilterFields = {
    homeFurnishingBusinessId,
    valid,
    selfCanonicalized,
    hasProducts,
    optimized,
    needsReview,
    pageSize,
  };
  const isFiltersVisible = Object.entries(comparableFilterFields).some(([key, value]) => defaultFilters[key as keyof FilterInput] !== value);
  const isSortVisible = !(sort.field === defaultSort.field && sort.direction === defaultSort.direction);
  if (!isFiltersVisible && !isSortVisible) return null;
  return (
    <Styled.ActiveFiltersRow gap={0.3} align="center" data-cy={'active-filters-row'}>
      <Text>Active filter:</Text>
      {isFiltersVisible && (
        <>
          {filter.optimized !== null && (
            <Pill
              ssrIcon={crossIcon}
              onClick={() => deselectFilter('optimized')}
              label={filter.optimized === true ? 'Optimized' : 'Non-Optimized'}
              size="small"
            />
          )}

          {filter.selfCanonicalized !== null && (
            <Pill
              ssrIcon={crossIcon}
              onClick={() => deselectFilter('selfCanonicalized')}
              label={filter.selfCanonicalized === true ? 'Self Canonicalized' : 'Non-self Canonicalized'}
              size="small"
            />
          )}

          {filter.valid !== null && (
            <Pill
              ssrIcon={crossIcon}
              onClick={() => deselectFilter('valid')}
              label={filter.valid === true ? 'Valid Length of Title & Description' : 'Invalid Length of Title & Description'}
              size="small"
            />
          )}

          {filter.hasProducts !== null && (
            <Pill
              ssrIcon={crossIcon}
              onClick={() => deselectFilter('hasProducts')}
              label={filter.hasProducts === true ? 'Has Products' : 'Has No Products'}
              size="small"
            />
          )}

          {filter.needsReview && <Pill ssrIcon={crossIcon} onClick={() => deselectFilter('needsReview')} label={'Needs Review'} size="small" />}

          {filter.homeFurnishingBusinessId && (
            <Pill
              ssrIcon={crossIcon}
              onClick={() => deselectFilter('homeFurnishingBusinessId')}
              label={getHFBFieldLabel(filter.homeFurnishingBusinessId)}
              size="small"
            />
          )}
        </>
      )}
      {isSortVisible && sort.field && (
        <Pill ssrIcon={crossIcon} onClick={clearSort} label={getSortFieldLabel(`${sort.field},${sort.direction}`)} size="small" />
      )}
      <Styled.ClearAllFilters
        onClick={() => {
          clearFilter();
          clearSort();
        }}
        tagName="p"
      >
        Clear all
      </Styled.ClearAllFilters>
    </Styled.ActiveFiltersRow>
  );
};
