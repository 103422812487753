import React from 'react';
import Button from '@ingka/button';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import ellipsis from '@ingka/ssr-icon/paths/ellipses-vertical';
import { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import LocaleHeader from 'components/common/LocaleHeader';
import { FloatingMenu } from 'components/FloatingMenu';
import { FloatingMenuContent } from 'components/FloatingMenu/FloatingMenuContent';
import { LatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';
import { FileType, useMetaDataExport } from '../../hooks/useMetaDataExport';

export const MetaDataListTable: React.FC<{
  country: string;
  language: string;
  versions: LatestByCountryQuery['latestVersionsForCountry'];
}> = ({ country, language, versions }) => {
  const columnHeaders = ['Type', 'Modified', 'Edited by', '', ''];
  const { exportMetaData } = useMetaDataExport();

  return (
    <Styled.PageWrapper>
      <LocaleHeader country={country} language={language} />

      <Styled.Table fullWidth data-testid={`${country}-${language}`}>
        <TableHeader>
          <tr>
            {columnHeaders.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
        </TableHeader>

        <TableBody striped>
          {versions.map((version, key) => {
            const downloadFile = async (format: FileType) =>
              await exportMetaData(
                version._id,
                { page: 0 },
                {
                  country: country,
                  language: language,
                  type: version.type,
                },
                format
              );

            return (
              <tr key={key} data-testid={`row-${version.type}`}>
                <td>
                  <Text>{version.type.toUpperCase()}</Text>
                </td>
                <td>
                  <Text>{new Date(version.ts).toLocaleString()}</Text>
                </td>
                <td>
                  <Text>{version.author}</Text>
                </td>
                <td>
                  <Button
                    text="View"
                    iconPosition="trailing"
                    ssrIcon={chevronRight}
                    size={'small'}
                    type="secondary"
                    href={`/metadata/view/${version._id}`}
                    data-cy={'view-button'}
                  />
                </td>
                <td>
                  <FloatingMenu>
                    {(open, toggleOpen) => (
                      <>
                        <Styled.PillButton iconOnly ssrIcon={ellipsis} onClick={toggleOpen} data-testid={'floating-menu-btn'} size={'small'} />

                        {open && (
                          <FloatingMenuContent>
                            <Styled.Menu>
                              <Styled.MenuItem>
                                <a href="" onClick={() => downloadFile('json')}>
                                  Download JSON
                                </a>
                              </Styled.MenuItem>
                              <Styled.MenuItem>
                                <a href="" onClick={() => downloadFile('xlsx')}>
                                  Download XLSX
                                </a>
                              </Styled.MenuItem>
                              <Styled.MenuItem>
                                <a href={`/metadata/upload/${country}/${language}/${version.type}`} type={'text'}>
                                  Upload new version
                                </a>
                              </Styled.MenuItem>
                            </Styled.Menu>
                          </FloatingMenuContent>
                        )}
                      </>
                    )}
                  </FloatingMenu>
                </td>
              </tr>
            );
          })}
        </TableBody>
      </Styled.Table>
    </Styled.PageWrapper>
  );
};
