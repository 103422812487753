import React from 'react';
import Banner from '@ingka/banner';
import informationIcon from '@ingka/ssr-icon/paths/information-circle';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import { AnnouncementInput, useAnnouncementQuery } from '__generated__/types';

export const AnnouncementBanner: React.FC<AnnouncementInput> = () => {
  const { loading, error, data } = useAnnouncementQuery();
  if (loading || error || !data) return null;

  const { announcement } = data;
  const bannerProps = {
    body: announcement.message,
    ssrIcon: announcement.emergencyVariant ? warningTriangleIcon : informationIcon,
    emergency: announcement.emergencyVariant,
  };

  if (announcement && !announcement.enabled) {
    return null;
  }

  return <Banner {...bannerProps} />;
};
