import React from 'react';
import styled from '@emotion/styled';
import { ValueKeys } from 'api/graphql/fragments';
import { FlexContainer } from 'components/FlexContainer';
import { MetaDataCompleteFragment } from '__generated__/types';

export const Label = styled.span`
  margin-right: 1em;
  font-weight: bold;
  flex: 0 0 120px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-width: 1px;
  &:first-of-type {
    font-weight: bold;
    border-bottom-style: solid;
    border-color: gray;
  }
  &:nth-of-type(n + 2) {
    border-top-style: solid;
    border-color: lightgray;
  }
`;

const Value = styled.span`
  overflow-wrap: break-word;
  word-break: break-all;
  flex: 1;
  margin-left: 10px;
`;

const Updated = styled(Value)`
  color: #070;
  font-weight: bold;
`;

const ComparisonTableCell: React.FC<{
  new: string | boolean | null;
  old: string | boolean | null;
  label: string;
}> = (props) => {
  const updated = (props.old ?? '') !== (props.new ?? '');
  return (
    <Row data-title={props.label}>
      <Label>{props.label}</Label>
      <Value>{props.old || ''}</Value>
      {updated ? <Updated>{props.new || ''}</Updated> : <Value>{props.new}</Value>}
    </Row>
  );
};

export const filterKeys: ValueKeys[] = [
  'id',
  'filterId',
  'filterName',
  'filterType',
  'pageTitle',
  'description',
  'keywords',
  'canonical',
  'referenceUrl',
  'urlSlug',
  'canonicalId',
  'robots',
  'introText',
  'bodyCopy',
  'h1',
  'ogTitle',
  'ogDescription',
  'ogImage',
];

const pipAndPlpKeys: ValueKeys[] = [
  'id',
  'robots',
  'canonical',
  'canonicalId',
  'description',
  'keywords',
  'pageTitle',
  'urlSlug',
  'ogDescription',
  'ogTitle',
  'ogImage',
  'hasProducts',
];

type ComparisonRowProps = { new: MetaDataCompleteFragment; old: MetaDataCompleteFragment; type: string };
export const ComparisonRow: React.FC<ComparisonRowProps> = (props) => {
  const keys: ValueKeys[] = props.type === 'filter' ? filterKeys : pipAndPlpKeys;
  return (
    <FlexContainer direction={'column'}>
      <Row>
        <Label />
        <Value>Existing Data</Value>
        <Value>New Data</Value>
      </Row>
      {keys.map((k, index) => (
        <ComparisonTableCell key={index} new={props.new[k]} old={props.old[k]} label={k} />
      ))}
    </FlexContainer>
  );
};
