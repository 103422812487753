import React, { ChangeEvent, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalFooter, ModalHeader } from '@ingka/modal';
import { Prompt } from '@ingka/modal';
import { GaxiosResponse, request } from 'gaxios';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { token as tokenSelector } from 'redux/selectors/login';

type RuleModalProps = {
  isVisible: boolean;
};

export const UploadRulesModal: React.FC<RuleModalProps> = ({ isVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const {
    state: { uploadRulesModal },
    setUploadRulesModalValues,
  } = useContext(RulesContext);

  const history = useHistory();
  const token = useSelector(tokenSelector);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event?.target?.files[0]);
    }
  };

  const uploadRules = async () => {
    setIsLoading(true);
    setShowError(false);
    const formData = new FormData();
    if (!selectedFile) {
      return;
    }
    formData.append('file', selectedFile);
    try {
      const response: GaxiosResponse = await request({
        method: 'POST',
        url: `/api/redirect/rules/${uploadRulesModal.policy}/upload/`,
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });
      if (response.status === 200) {
        setUploadRulesModalValues({ isOpen: false });
        return history.push(`/redirects/${uploadRulesModal.country}/${uploadRulesModal.policy}/upload-diff/${response.data.uploadComparisonId}`);
      }

      setShowError(true);
      setIsLoading(false);
    } catch (e) {
      setShowError(true);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      escapable={false}
      visible={isVisible}
      handleCloseBtn={() => {
        setUploadRulesModalValues({ isOpen: false });
        setShowError(false);
      }}
    >
      <Prompt
        titleId={'Upload'}
        title={'Upload'}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              text={'Cancel'}
              type={'secondary'}
              onClick={() => {
                setUploadRulesModalValues({ isOpen: false });
                setShowError(false);
              }}
              size={'small'}
              fluid={false}
              disabled={isLoading}
            />
            <Button
              loading={isLoading}
              fluid={false}
              text={'Upload'}
              type={'primary'}
              onClick={uploadRules}
              size={'small'}
              disabled={!selectedFile || isLoading}
              data-testid={'modal-upload-btn-upload'}
            />
          </ModalFooter>
        }
      >
        {showError && (
          <InlineMessage
            variant={'cautionary'}
            title={'Oops! Something went wrong :('}
            body={'Something went wrong with the file you are trying to upload.'}
          />
        )}
        <hr />
        <p>Please choose a XLSX file.</p>
        <input type="file" name="file" accept=".xlsx" onChange={handleFileChange} data-testid={'modal-upload-file-input'} />
      </Prompt>
    </Modal>
  );
};
