import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import personIcon from '@ingka/ssr-icon/paths/person';
import { GaxiosResponse, request } from 'gaxios';
import jwt_decode from 'jwt-decode';
import { token as tokenSelector } from 'redux/selectors/login';
import * as Styled from './styles';

export interface DecodedToken {
  oid: string;
  name: string;
  upn: string;
}

export const Profile: React.FC = () => {
  const token = useSelector(tokenSelector);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const { oid } = jwt_decode<DecodedToken>(token);
        const url = `https://graph.windows.net/720b637a-655a-40cf-816a-f22f40755c2c/users/${oid}/thumbnailPhoto?api-version=1.6`;
        const { data, status }: GaxiosResponse = await request({ url, headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });
        if (status === 200) {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            setImage(reader.result?.toString() ?? null);
          };
        }
      } catch (err) {
        setImage(null);
      }
    };

    fetchProfileImage();
  }, [token]);

  return (
    <div data-testid='profile-header'>
      {image ? (
        <Styled.ProfileImage src={image} alt='profile pic' />
      ) : (
        <Styled.PlaceholderIcon paths={personIcon} data-testid={'profile placeholder pic'} />
      )}
    </div>
  );
};
