import { useGetLocalesQuery } from '__generated__/types';

type MarketType = { country: string };

export const useMarkets = (): { editableMarkets: MarketType[]; readonlyMarkets: MarketType[] } => {
  const { data } = useGetLocalesQuery();
  let editableMarkets: MarketType[] = [];
  let readonlyMarkets: MarketType[] = [];

  if (data) {
    editableMarkets = data.locales.myLocales;
    readonlyMarkets = data.locales.otherLocales;
  }

  return { editableMarkets, readonlyMarkets };
};
