import React, { useContext, useState } from 'react';
import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { Row } from 'components/common/Row';
import { FlexContainer } from 'components/FlexContainer';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { FilterInput, MetaDataEntry } from '__generated__/types';
import { DeleteEntryModal } from './DeleteEntryModal';
import { View } from './View';

export interface MetadataItemProps {
  data: MetaDataEntry;
  isEditable: boolean;
  versionId: string;
  type: string;
  author: string;
  diffVersionId: string;
  filter: FilterInput;
  onDelete: () => void;
}

export const MetadataItem: React.FC<MetadataItemProps> = ({ data, isEditable, versionId, type, author, diffVersionId, filter, onDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setIsEditorOpen } = useContext(MetadataContext);

  return (
    <Row data-testid={`metadata-row-${data.id}`}>
      <View data={data} type={type} author={author} diffVersionId={diffVersionId} />
      {isEditable && (
        <FlexContainer direction={'row'} align={'flex-start'}>
          <Button
            text={'Edit'}
            ssrIcon={pencil}
            type={'tertiary'}
            iconOnly={true}
            iconPosition={'leading'}
            onClick={() => setIsEditorOpen(true, data.id)}
            data-cy={'edit-button'}
          />
          <Button
            text={'Delete'}
            ssrIcon={trashCan}
            type={'tertiary'}
            iconOnly={true}
            iconPosition={'leading'}
            onClick={() => setModalOpen(true)}
            data-cy={'delete-button'}
            disabled={!data.isOptimized}
          />

          <DeleteEntryModal
            entryId={data.id}
            pageTitle={data.pageTitle || ''}
            versionId={versionId}
            isVisible={modalOpen}
            onCancel={() => setModalOpen(false)}
            filter={filter}
            onDelete={onDelete}
          />
        </FlexContainer>
      )}
    </Row>
  );
};
