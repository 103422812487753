import styled from '@emotion/styled';
import Select from '@ingka/select';

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LanguageDropdown = styled(Select)`
  option[value='Choose an option'] {
    display: none;
  }

  width: 200px;
`;
