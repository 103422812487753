import React, { useContext } from 'react';
import { Context as EditorContext, FormKeys } from 'hooks/contexts/EditorContext';
import { CheckboxInput } from './CheckboxInput';

interface EditorCheckboxInputProps {
  id: FormKeys;
  placeholder?: string;
  disabled?: boolean;
  label: string;
  needsReview?: boolean;
  maxLength?: number;
  showLength?: boolean;
}

export const EditorCheckboxInput: React.FC<EditorCheckboxInputProps> = (props) => {
  const { state, setField } = useContext(EditorContext);
  const checked = !!(state.formData && state.formData[props.id]);

  return <CheckboxInput {...props} onChange={() => setField(props.id, !checked)} checked={checked} />;
};
