import React, { FC } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import Text from '@ingka/text';
import { colourSemanticCaution, colourSemanticNegative, colourSemanticPositive } from '@ingka/variables/colours-css';
import * as Styled from './styles';

interface StatusIconProps {
  statusType: 'isOptimized' | 'isPublished' | 'needsReview';
  status: boolean;
}

export const StatusIcon: FC<StatusIconProps> = ({ statusType, status }) => {
  const isPositive = status ? checkmarkCircle : incorrect;
  const iconPath = statusType === 'needsReview' ? warningTriangle : isPositive;
  const getStatusIconElement = (statusText: string, color: string) => (
    <Styled.StatusIcon color={color}>
      <SSRIcon paths={iconPath} />
      <Text>{statusText}</Text>
    </Styled.StatusIcon>
  );

  const redOrGreen = status ? colourSemanticPositive : colourSemanticNegative;

  switch (statusType) {
    case 'isOptimized':
      return getStatusIconElement(status ? 'Optimized' : 'Not Optimized', redOrGreen);
    case 'isPublished':
      return getStatusIconElement(status ? 'Published' : 'Not Published', redOrGreen);
    case 'needsReview':
      return getStatusIconElement('Needs review', colourSemanticCaution);
    default:
      return null;
  }
};
