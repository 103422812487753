import { gql } from 'apollo-boost';
import { fragments } from 'api/graphql/fragments';

export const GET_FILE_LIST_QUERY = gql`
  query FileTabs($country: String!, $language: String!) {
    plpFiles: versionsByLocale(country: $country, language: $language, type: "PLP") {
      _id
      author
      country
      language
      ts
      type
      size
    }
    pipFiles: versionsByLocale(country: $country, language: $language, type: "PIP") {
      _id
      author
      country
      language
      ts
      type
      size
    }
    filterFiles: versionsByLocale(country: $country, language: $language, type: "filter") {
      _id
      author
      country
      language
      ts
      type
      size
    }
  }
`;

export const GET_DIFF = gql`
  ${fragments.diff}
  query Diff($id: ObjectId!) {
    version(_id: $id) {
      type
      country
      language
      diff {
        ...Diff
      }
    }
  }
`;

export const EDIT_ITEM = gql`
  mutation EditItem($versionId: ObjectId!, $entry: MetaDataEntryInput!, $filter: FilterInput) {
    editMetaDataEntry(versionId: $versionId, entry: $entry) {
      _id
      country
      language
      type
      latest
      ts
      author
      isDraft
      entries(filter: $filter) {
        totalPages
        totalItems
        entries {
          ...MetaDataExtended
        }
      }
    }
  }
  ${fragments.metaDataEntryOptimized}
`;

export const DELETE_ITEM = gql`
  mutation DeleteMetaDataEntry($versionId: ObjectId!, $entryId: String!, $filter: FilterInput) {
    deleteMetaDataEntry(versionId: $versionId, entryId: $entryId) {
      _id
      country
      language
      type
      latest
      ts
      author
      isDraft
      entries(filter: $filter) {
        totalPages
        entries {
          ...MetaDataExtended
        }
      }
    }
  }
  ${fragments.metaDataEntryOptimized}
`;

export const META_DATA_PUBLISH = gql`
  mutation PublishVersion($versionId: ObjectId!) {
    publishVersion(versionId: $versionId) {
      _id
    }
  }
`;

export const DROPDOWN_QUERY = gql`
  query GetDropdownOptions($versionId: ObjectId!, $text: String!) {
    dropdownOptions(versionId: $versionId, text: $text) {
      key
      text
      value
    }
    dropdownOptionExists(versionId: $versionId, dropdownKey: $text)
  }
`;

export const HFB_DROPDOWN_QUERY = gql`
  query GetHFBDropdownOptions($versionId: ObjectId!) {
    hfbDropdownOptions(versionId: $versionId) {
      key
      text
      value
    }
  }
`;

export const DUPLICATE_SLUGS_QUERY = gql`
  query GetDuplicateSlugs($id: ObjectId!, $urlSlug: String!) {
    currentVersion: version(_id: $id) {
      entriesWithSameSlug(urlSlug: $urlSlug)
    }
  }
`;

export const METADATA_ENTRIES_QUERY = gql`
  query CurrentVersion($id: ObjectId!, $filter: FilterInput, $sort: SortInput) {
    currentVersion: version(_id: $id) {
      _id
      country
      language
      type
      latest
      ts
      author
      isDraft
      urlSlugSyncEnabled
      size
      optimizedItems
      entries: entriesWithSuggestedKeywords(filter: $filter, sort: $sort) {
        totalPages
        entries {
          ...MetaDataExtended
        }
      }
    }
  }
  ${fragments.metaDataEntryOptimized}
`;

export const GET_ENTRIES = gql`
  query GetLatestVersionEntries($language: String!, $country: String!, $type: String!) {
    latestVersion(country: $country, language: $language, type: $type) {
      _id
      type
    }
  }
`;

export const EDIT_UPLOAD_ITEM = gql`
  mutation EditUploadItem($uploadId: ObjectId!, $metadataItem: MetaDataEntryInput!) {
    editUploadedItem(uploadId: $uploadId, metaDataItem: $metadataItem) {
      ...Upload
    }
  }
  ${fragments.upload}
`;

export const DELETE_UPLOAD_ITEM = gql`
  mutation DeleteUploadItem($uploadId: ObjectId!, $metaDataId: String!) {
    deleteUploadItem(uploadId: $uploadId, metaDataId: $metaDataId) {
      ...Upload
    }
  }
  ${fragments.upload}
`;

export const GET_UPLOAD_BY_ID = gql`
  query UploadData($id: ObjectId!) {
    uploadComparisonById(uploadId: $id) {
      ...UploadComparison
    }
  }
  ${fragments.uploadComparison}
`;

export const UPLOAD_PUBLISH = gql`
  mutation UploadPublish($uploadId: ObjectId!) {
    publishUploadedData(uploadId: $uploadId) {
      _id
    }
  }
`;
