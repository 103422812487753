import styled from '@emotion/styled';
import { Market } from './Market';

export const MarketWithBorders = styled(Market)`
  border-color: ${(props) => (props.fetching || props.generating ? '#0058a3' : props.fetched ? '#d3d3d3' : props.generated ? '#111' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  padding: 5px 10px;
  border-radius: 5px;
  color: ${(props) => (props.generated || props.fetching || props.generating ? '#111' : '#d3d3d3')};
`;

export const ActionBar = styled.div`
  margin-top: 30px;
`;
