import { defaultTo } from 'ramda';
import { createSelector } from 'reselect';
import { RootState } from 'redux/store';

export const isLoggedIn = createSelector(
  (state: RootState) => state.login.loggedIn,
  (button) => button
);
export const username = createSelector([(state: RootState) => state.login.username], (username) => defaultTo('', username));
export const token = createSelector([(state: RootState) => state.login.token], (token) => defaultTo('', token));
export const roles = createSelector([(state: RootState) => state.login.roles], (roles) => roles || []);
