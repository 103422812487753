import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import Container from 'components/common/Container';
import { Provider as EditorProvider } from 'hooks/contexts/EditorContext';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { toMetaData } from 'redux/util/toGenericMetadata';
import { MetaDataEntryInput, useEditItemMutation, useMetadataEntryByIdQuery } from '__generated__/types';
import { EditSheet } from './EditSheet';
import { MetaDataBookmarkError } from './MetaDataBookmarkError';
import { useLoader } from '../../hooks/useLoader';

export const MetaDataEditor: React.FC = () => {
  const [editItem] = useEditItemMutation();
  const history = useHistory();
  const { setIsEditorOpen } = useContext(MetadataContext);
  const [, setPending] = useLoader();
  const { pathname } = useLocation();
  const [, , , curCountry, curLanguage, curType, urlEntryId] = pathname.split('/');

  const { data, loading, refetch } = useMetadataEntryByIdQuery({
    variables: { entryId: urlEntryId, country: curCountry, language: curLanguage, metadataType: curType },
    onCompleted: (data) => {
      if (data?.getMetadataEntryById) {
        setIsEditorOpen(data?.getMetadataEntryById?.id);
      }
    },
  });

  const versionId = data?.getMetadataEntryById?.diffVersionId;
  const versionDetails = {
    type: curType,
    urlSlugSyncEnabled: false,
    versionId: versionId,
    market: { country: curCountry, language: curLanguage },
  };

  const editorInitialState = (() => {
    const currentlyEditedEntry = data?.getMetadataEntryById;
    if (!currentlyEditedEntry) return;
    const descriptionIsSynced = currentlyEditedEntry.description === currentlyEditedEntry.ogDescription;
    const isOptimized = !!currentlyEditedEntry.isOptimized;
    const needsReview = !!currentlyEditedEntry.needsReview;
    return {
      formData: { ...currentlyEditedEntry, descriptionIsSynced, isOptimized, needsReview },
      hasErrors: false,
      errors: {},
      versionDetails,
      isOpen: true,
    };
  })();

  const editItemCallback = useCallback(
    async (entry: MetaDataEntryInput) => {
      const item = toMetaData(entry);
      const res = await editItem({
        variables: {
          versionId,
          entry: { ...item, pageTitle: item.pageTitle || '' },
        },
      });
      if (res.data) {
        setPending(false);
        return history.push(`/metadata/view/${res.data.editMetaDataEntry._id}`);
      }
    },
    [versionId, history]
  );

  if (loading && !data) {
    return (
      <Loading text={'Please wait...'}>
        <LoadingBall />
      </Loading>
    );
  }

  return (
    <Container page="metadataEditPage">
      {!data?.getMetadataEntryById ? (
        <MetaDataBookmarkError refetch={refetch} />
      ) : (
        <>
          <Loading text={'Try reloading the page...'}>
            <LoadingBall />
          </Loading>

          {editorInitialState && (
            <EditorProvider injectedState={editorInitialState}>
              <EditSheet editItem={editItemCallback} />
            </EditorProvider>
          )}
        </>
      )}
    </Container>
  );
};
