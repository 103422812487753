import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { effects } from 'redux/reducers/login';
import { RootState } from 'redux/store';

export const withToken = async (
  dispatch: ThunkDispatch<RootState, null, AnyAction>,
  headers?: Record<string, string>
): Promise<Record<string, string>> => {
  const token = await dispatch(effects.refreshToken());
  return { ...headers, Authorization: `Bearer ${token}` };
};
