import { splitEvery } from 'ramda';

export interface PaginationT<T> {
  totalPages: number;
  data: T[][];
  pageSize: number;
  totalItems: number;
}
// eslint-disable-next-line @typescript-eslint/ban-types
export const paginateData = <T extends unknown>(data: T[], pageSize = 20): PaginationT<T> => {
  const arr = splitEvery(pageSize, data);
  return {
    data: arr,
    totalPages: arr.length,
    pageSize,
    totalItems: data.length,
  };
};
