import React, { ChangeEvent } from 'react';
import FormField from '@ingka/form-field';
import Select, { Option } from '@ingka/select';

export interface DropdownOption {
  key: string;
  value: string;
  text: string;
}

interface DropdownProps {
  id: string;
  isReadOnly?: boolean;
  helpText?: string;
  label: string;
  options: DropdownOption[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const DropdownItems = (options: DropdownOption[], value: string) =>
  options.map((option) => <Option key={option.key} value={option.value} name={option.text} selected={option.value === value} />, options);
export const DropdownInput: React.FC<DropdownProps> = (props) => {
  return (
    <FormField
      fieldHelper={{
        msg: props.helpText || '',
      }}
    >
      <Select label={props.label} id={props.id} name={props.id} onChange={props.onChange} disabled={props.isReadOnly}>
        {DropdownItems(props.options, props.value)}
      </Select>
    </FormField>
  );
};
