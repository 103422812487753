import React from 'react';
import ByggaSSRIconIngka from '@ingka/ssr-icon';
import checkmark from '@ingka/ssr-icon/paths/checkmark-small';
import crossSmall from '@ingka/ssr-icon/paths/cross-small';
import { KeyValue } from 'components/common/KeyValue';
import { FlexContainer } from 'components/FlexContainer';
import { SiteStructureItemProps } from './SiteStructure';

const booleanIcon = (value: boolean): React.ReactElement =>
  value ? <ByggaSSRIconIngka key={'added'} paths={checkmark} /> : <ByggaSSRIconIngka key={'added'} paths={crossSmall} />;
export const View: React.FC<SiteStructureItemProps> = (props) => {
  const labelSize = 250;
  return (
    <FlexContainer direction={'column'} data-testid={`siteStructure-${props.data._id}`}>
      <KeyValue label={'Owner'} value={props.data.owner} labelSize={labelSize} />
      <KeyValue label={'Slug'} value={props.data.slug} labelSize={labelSize} />
      <KeyValue label={'Description'} value={props.data.description} labelSize={labelSize} />
      <KeyValue label={'Id'} value={props.data.id} labelSize={labelSize} />
      <KeyValue label={'End date'} value={props.data.endDate} labelSize={labelSize} />
      <KeyValue label={'Start date'} value={props.data.startDate} labelSize={labelSize} />
      <KeyValue label={'Type'} value={props.data.type} labelSize={labelSize} />
      <KeyValue label={'Allow local apps'} value={booleanIcon(props.data.allowLocalApps)} labelSize={labelSize} />
      <KeyValue label={'Allow local pages'} value={booleanIcon(props.data.allowLocalPages)} labelSize={labelSize} />
      <KeyValue label={'Allow local sections'} value={booleanIcon(props.data.allowLocalSections)} labelSize={labelSize} />
      <KeyValue label={'Allow planners'} value={booleanIcon(props.data.allowPlanners)} labelSize={labelSize} />
    </FlexContainer>
  );
};
