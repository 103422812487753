import React, { SyntheticEvent, useState } from 'react';
import { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { useComponentOffsetTop } from 'hooks/useComponentOffsetTop';
import { Column as MetaDataTableColumn } from 'types';
import { FilterInput, MetaDataEntry } from '__generated__/types';
import { DeleteEntryModal } from './DeleteEntryModal';
import { MetaDataTableCollapsibleRow } from './MetaDataTableCollapsibleRow';
import * as Styled from './styles';

type ColumnHeader = MetaDataTableColumn | null;

interface MetaDataStrippedTableProps {
  entries: MetaDataEntry[];
  type: string;
  versionId: string;
  filter: FilterInput;
  columns: MetaDataTableColumn[];
  onDelete: () => void;
}

export const MetaDataStrippedTable: React.FC<MetaDataStrippedTableProps> = ({ entries, type, versionId, filter, columns, onDelete }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [entryId, setEntryId] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');
  const { componentRef: tableRef, componentOffsetTop: offsetTop } = useComponentOffsetTop();
  const isPlpPage = type === 'PLP';
  const visibleColumns = columns.filter((column) => column.visible && column.label !== 'Keywords');
  const columnHeaders: ColumnHeader[] = [null, ...visibleColumns, null];

  const onMetadataDelete = (e: SyntheticEvent, entryId: string, pageTitle: string) => {
    e.stopPropagation();
    setEntryId(entryId);
    setPageTitle(pageTitle);
    setIsDeleteModalOpen(true);
  };

  return (
    <div ref={tableRef}>
      <Styled.Table fullWidth offsettop={offsetTop} data-testid="meta-data-collapsed-table">
        <TableHeader sticky>
          <tr>
            {columnHeaders.map((header, index) => (
              <Styled.AlignedHeader key={index} rightAlign={['entryId', 'ts'].includes(header?.value ?? '')}>
                <Text tagName={'span'} bodySize="m">
                  {header?.label}
                </Text>
              </Styled.AlignedHeader>
            ))}
          </tr>
        </TableHeader>
        <TableBody striped>
          {entries.map((entry) => (
            <MetaDataTableCollapsibleRow
              key={`${entry.id}-row`}
              isPlpPage={isPlpPage}
              entry={entry}
              onMetadataDelete={onMetadataDelete}
              visibleColumns={visibleColumns}
              type={type}
            />
          ))}
        </TableBody>
      </Styled.Table>
      <DeleteEntryModal
        entryId={entryId}
        pageTitle={pageTitle}
        versionId={versionId}
        isVisible={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        filter={filter}
        onDelete={onDelete}
      />
    </div>
  );
};
