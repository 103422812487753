import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import Text from '@ingka/text';
import * as Styled from './styles';

interface MetaDataBookmarkErrorProps {
  refetch: () => void;
}

export const MetaDataBookmarkError: React.FC<MetaDataBookmarkErrorProps> = ({ refetch }) => {
  const history = useHistory();

  return (
    <Styled.CountrySwapperWrapper direction="column">
      <Text tagName="h1" headingSize="m">
        Something went wrong =/
      </Text>
      <Text tagName="p" bodySize="m">
        Please try to refetch or go the metadata page. Or try the bookmark again! If the error persists please contact the Optimera team.
      </Text>

      <Styled.BookmarkErrorButtons gap={1}>
        <Button text={'Refetch'} type={'secondary'} onClick={() => refetch()} size={'small'} />
        <Button text={'Go to metadata'} type={'secondary'} onClick={() => history.push(`/metadata`)} size={'small'} />
      </Styled.BookmarkErrorButtons>
    </Styled.CountrySwapperWrapper>
  );
};
