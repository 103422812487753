import React from 'react';
import styled from '@emotion/styled';
import { space125 } from '@ingka/variables';
import Breadcrumb from './Breadcrumb';

export const ContainerWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: ${space125};

  .tabs__tab {
    padding: 0rem 0.25rem 0.5rem 0.25rem;
  }
`;

interface ContainerProps {
  children: React.ReactNode;
  page: string;
  country?: string;
  language?: string;
  type?: string;
  filename?: string;
}

const ContainerComp: React.FC<ContainerProps> = (props) => (
  <ContainerWrapper>
    <Breadcrumb page={props.page} country={props.country} language={props.language} type={props.type} filename={props.filename} />
    {props.children}
  </ContainerWrapper>
);

export default ContainerComp;
