import React from 'react';
import styled from '@emotion/styled';
import Loading, { LoadingBall } from '@ingka/loading';
import { environment } from 'environment';
import { pathOr, uniqBy } from 'ramda';
import Container from 'components/common/Container';
import { NoData } from 'components/common/NoData';
import { Column } from 'components/common/Table';
import { paginateData } from 'opt-util/paginate';
import { Message, MetadataType } from 'types';
import { UploadError, useUploadDataQuery } from '__generated__/types';
import { IComparisonRow } from './ComparisonTable';
import { ErrorMessageListItem } from './UploadErrors';
import { ValidationResult } from './ValidationResult';
import { ErrorMessage } from 'components/common/styles';

const WarningMessage = styled.li`
  color: darkorange;
  font-size: 0.8rem;
`;

export function getMessage(i: UploadError): string {
  let message = '';
  const [prop, type] = i.type.split('_');
  if (type === 'length') {
    message = `Length of "${prop}" is below ${pathOr('50', [prop, 'min'], environment.validation)}`;
  } else if (type === 'dupl') {
    message = `Duplicate value for "${prop}". Following IDs have the same value: ${(i.ids || []).join(', ')}`;
  } else if (type === 'pattern') {
    message = `"${prop}" does not match expected pattern.`;
  } else if (type === 'empty') {
    message = `"${prop}" is missing`;
  } else if (i.type === 'dupl_entry') {
    message = `Duplicate entry in the uploaded sheet`;
  } else if (i.type === 'non_existing_entry') {
    message = `Unable to update entry; Entry not found`;
  }
  return message;
}

export const getErrorMessage = (i: UploadError): Message => {
  const prefixRow = (str: string) => `Row ${i.row}: ${str}`;
  const message = getMessage(i);
  return {
    id: i.id,
    messages: [prefixRow(message)],
  };
};

const cols: Column[] = [
  { key: 'id', label: 'ID' },
  { key: 'filterType', label: 'Filter Type' },
  { key: 'filterName', label: 'Filter Name' },
  { key: 'pageTitle', label: 'Page Title' },
  {
    key: 'urlSlug',
    label: 'URL Slug',
  },
  {
    key: 'canonical',
    label: 'Canonical Url',
  },
  {
    key: 'canonicalId',
    label: 'Canonical Id',
  },
  {
    key: 'referenceUrl',
    label: 'Reference Url',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'keywords',
    label: 'Keywords',
  },
  { key: 'introText', label: 'Intro Text' },
  { key: 'bodyCopy', label: 'Body Copy' },
  { key: 'h1', label: 'H1' },
  { key: 'ogTitle', label: 'OG Title' },
  { key: 'ogDescription', label: 'OG Description' },
  { key: 'ogImage', label: 'OG Image' },
  {
    key: 'robots',
    label: 'Robots',
    render: (val: string) => val || 'index, follow',
  },
  {
    key: 'warnings',
    label: 'Result',
    render: (val: string[], label, row: IComparisonRow) => {
      const warnings = (val || []).map((message, i) =>
        message.includes('dupl_entry') ? (
          <ErrorMessageListItem key={i}>
            {getMessage({
              type: message,
              ids: [],
              row: row.new.row || 0,
              id: row.new.id,
            })}
          </ErrorMessageListItem>
        ) : (
          <WarningMessage key={i}>
            {getMessage({
              type: message,
              ids: [],
              row: row.new.row || 0,
              id: row.new.id,
            })}
          </WarningMessage>
        )
      );
      return warnings.length > 0 ? <ul>{warnings}</ul> : null;
    },
  },
];

const UploadResultComp: React.FC<{ id: string }> = ({ id }) => {
  const { loading, error, data } = useUploadDataQuery({
    variables: { id },
  });
  if (loading) {
    return (
      <Loading text={'Please wait...'}>
        <LoadingBall />
      </Loading>
    );
  } else if (data && data.uploadComparisonById) {
    const { _id, country, language, type, comparison, errors } = data.uploadComparisonById;
    const comparisonRow: IComparisonRow[] = comparison.map((i): IComparisonRow => {
      return { new: i, original: i.original };
    });
    const paginatedRows = paginateData(comparisonRow);
    return (
      <Container page={'uploadCountry'} country={country} language={language} type={type}>
        <ValidationResult
          uploadId={_id}
          options={{ language, country, type: type as MetadataType }}
          result={paginatedRows}
          columns={cols}
          errorMessages={uniqBy((i) => `${i.id}-${i.type}`, errors || []).map(getErrorMessage)}
        />
      </Container>
    );
  } else if (data && data.uploadComparisonById === null) {
    return <NoData title={'Upload comparison does not exist'} />;
  } else if (error) {
    return <ErrorMessage>${error.message}</ErrorMessage>;
  }
  return null;
};

export default UploadResultComp;
