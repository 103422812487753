import React from 'react';
import { ReactCountryFlagProps } from 'react-country-flag';
import * as Styled from './styles';

export const CountryFlag: React.FC<ReactCountryFlagProps> = ({ countryCode, ...restFlagProps }) =>
  countryCode.toLowerCase() === 'r1' ? (
    <Styled.FallbackCountryFlag alt={countryCode} src='/ikea-flag.png' />
  ) : (
    <Styled.CountryFlag countryCode={countryCode} svg {...restFlagProps} />
  );
