import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@ingka/button';
import InlineMessage, { InlineMessageProps } from '@ingka/inline-message';
import { LoadingBall } from '@ingka/loading';
import Modal, { ModalFooter, ModalHeader } from '@ingka/modal';
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle';
import Text from '@ingka/text';
import Tooltip from '@ingka/tooltip';
import { GaxiosResponse, request } from 'gaxios';
import { DateInputField } from 'components/common/DateInputField';
import { TextInputField } from 'components/common/TextInputField';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { useDebounce } from 'hooks/useDebounce';
import { token as tokenSelector } from 'redux/selectors/login';
import { isAdmin } from 'redux/selectors/selectors';
import { RuleType } from './index';
import { getInlineMessage } from './inlineMessages';
import { StatusIcon } from './statusIcon';
import { getValidationType, validateValueWithType, ValidationTypes } from './validations';
import * as StyledLocal from './styled';

const Styled = { ...StyledLocal };

type InlineMessageType = { isShown: boolean; message?: InlineMessageProps };
type State = {
  name: string;
  redirectType: number;
  targetURL: string;
  matchURL: string;
  dateStart: string;
  dateEnd: string;
  targetStatus: number | string;
  isLoading: boolean;
  inlineMessage: InlineMessageType;
  pathExist: string;
};

type RuleModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  header: string;
  toolTipText: string;
  existingRule?: RuleType;
};

type Action =
  | { type: 'SET_REDIRECT_TYPE'; payload: number }
  | { type: 'SET_TARGET_URL'; payload: string }
  | { type: 'SET_MATCH_URL'; payload: string }
  | { type: 'SET_DATE_START'; payload: string }
  | { type: 'SET_DATE_END'; payload: string }
  | { type: 'SET_TARGET_STATUS'; payload: number | string }
  | { type: 'SET_RULE_NAME'; payload: string }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_INLINE_MESSAGE'; payload: InlineMessageType }
  | { type: 'SET_PATH_EXIST'; payload: string }
  | { type: 'SET_INIT' };

export const AddEditRuleModal: React.FC<RuleModalProps> = ({ isVisible, onCancel, onSubmit, header, toolTipText, existingRule }) => {
  const initState: State = {
    name: existingRule?.name || '',
    redirectType: existingRule?.redirectType || 301,
    targetURL: existingRule?.targetURL || '',
    matchURL: existingRule?.matchURL || '',
    dateStart: existingRule?.dateStart?.slice(0, 10) || '',
    dateEnd: existingRule?.dateEnd?.slice(0, 10) || '',
    targetStatus: existingRule?.targetStatus || 'None',
    pathExist: '',
    inlineMessage: {
      isShown: false,
    },
    isLoading: false,
  };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'SET_RULE_NAME':
        return { ...state, name: action.payload };
      case 'SET_REDIRECT_TYPE':
        return { ...state, redirectType: action.payload };
      case 'SET_TARGET_URL':
        return { ...state, targetURL: action.payload };
      case 'SET_MATCH_URL':
        return { ...state, matchURL: action.payload };
      case 'SET_DATE_START':
        return { ...state, dateStart: action.payload };
      case 'SET_DATE_END':
        return { ...state, dateEnd: action.payload };
      case 'SET_TARGET_STATUS':
        return { ...state, targetStatus: action.payload };
      case 'SET_IS_LOADING':
        return { ...state, isLoading: action.payload };
      case 'SET_INLINE_MESSAGE':
        return { ...state, inlineMessage: { ...action.payload } };
      case 'SET_PATH_EXIST':
        return { ...state, pathExist: action.payload };
      case 'SET_INIT':
        return { ...state, ...initState };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initState);
  const statusMessage = useContext(StatusMessageContext);
  const { setRefetchAfter, setEditRuleModalIsOpenValue, setSelectedRuleValue } = useContext(RulesContext);
  const token = useSelector(tokenSelector);
  const isAdminUser = useSelector(isAdmin);
  const { pathname } = useLocation();
  const [isFetchingUrlStatus, setIsFetchingUrlStatus] = useState(false);
  const [, , curCountry, policy] = pathname.split('/');
  const isShortURLPage = policy && policy.includes('.');
  const curLang = !isShortURLPage ? policy.split('-').pop() : undefined;

  const modalTitle = (
    <Styled.RuleModalHeader>
      {header}
      <Tooltip tooltipText={toolTipText} ssrIcon={questionMarkCircle} position={'trailing'} />
    </Styled.RuleModalHeader>
  );

  const RadioButtonsArray = [
    {
      id: 'typePermanent',
      name: 'redirectType',
      label: 'Permanent (301)',
      onChange: () => dispatch({ type: 'SET_REDIRECT_TYPE', payload: 301 }),
      checked: state.redirectType === 301,
    },
    {
      id: 'typeTemporary',
      name: 'redirectType',
      label: 'Temporary (302)',
      onChange: () => dispatch({ type: 'SET_REDIRECT_TYPE', payload: 302 }),
      checked: state.redirectType === 302,
    },
  ];

  const setResponseBody = ({
    name,
    matchURL,
    targetURL,
    redirectType,
    dateStart,
    dateEnd,
    targetStatus,
    dateModified,
    docRefId,
    userModifiedBy,
    invocations,
  }: RuleType): RuleType => ({
    name,
    matchURL,
    targetURL,
    redirectType,
    dateStart,
    dateEnd: dateEnd || null,
    targetStatus,
    dateModified,
    docRefId,
    userModifiedBy: { email: userModifiedBy?.email || '', fullName: userModifiedBy?.email || '' },
    invocations,
  });

  const publishRule = async (existingRule?: RuleType) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    const reqBody = {
      name: state.name,
      matchURL: state.matchURL,
      targetURL: state.targetURL,
      redirectType: state.redirectType,
      dateStart: state.dateStart,
      dateEnd: state.dateEnd || null,
      targetStatus: state.targetStatus,
    };
    if (existingRule) {
      const response: GaxiosResponse = await request({
        method: 'PUT',
        url: `/api/redirect/rules/${policy}/${existingRule.docRefId}`,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        data: reqBody,
      });

      if (response.data.result === 'ok') {
        const resBody = setResponseBody(response.data);

        setSelectedRuleValue(resBody);
        dispatch({ type: 'SET_INIT' });
        onSubmit();
        statusMessage.setStatusMessage({
          isVisible: true,
          variant: 'positive',
          title: 'Well done!',
          bodyText: 'You have successfully edited the rule!',
          actions: [
            {
              text: 'View now',
              onClick: () => {
                setEditRuleModalIsOpenValue(true);
                statusMessage.setStatusMessage({ isVisible: false });
              },
            },
          ],
        });
        setRefetchAfter('Edited');
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        dispatch({
          type: 'SET_INLINE_MESSAGE',
          payload: {
            isShown: true,
            message: getInlineMessage('couldNotEditRule'),
          },
        });
      }
    } else {
      const url = `/api/redirect/rules/${policy}`;
      const response: GaxiosResponse = await request({
        method: 'POST',
        url,
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        data: reqBody,
      });

      if (response.data.result === 'ok') {
        const resBody = setResponseBody(response.data);

        setSelectedRuleValue(resBody);
        dispatch({ type: 'SET_INIT' });
        onSubmit();
        statusMessage.setStatusMessage({
          isVisible: true,
          variant: 'positive',
          title: 'Well done!',
          bodyText: 'You have successfully added a new rule!',
          actions: [
            {
              text: 'View now',
              onClick: () => {
                setEditRuleModalIsOpenValue(true);
                statusMessage.setStatusMessage({ isVisible: false });
              },
            },
          ],
        });
        setRefetchAfter('Added');
      } else {
        dispatch({
          type: 'SET_INLINE_MESSAGE',
          payload: {
            isShown: true,
            message: getInlineMessage('couldNotCreateRule'),
          },
        });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }
    }
  };

  const getUrlStatus = async (url: string) => {
    const reqUrl = '/api/redirect/status';
    const response: GaxiosResponse = await request({
      method: 'POST',
      url: reqUrl,
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      data: { url },
    });
    return dispatch({ type: 'SET_TARGET_STATUS', payload: response.data.status });
  };

  const getPathError = async (path: string, policy: string, excludedDoc?: string) => {
    const url = `/api/redirect/rules/${policy}/validate`;
    const response: GaxiosResponse = await request({
      method: 'POST',
      url,
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      data: { matchURL: path, excludedDoc },
    });
    return dispatch({ type: 'SET_PATH_EXIST', payload: response?.data?.ruleExistenceResult ?? '' });
  };

  const debouncedTargetUrl = useDebounce(state.targetURL, 300);
  const debouncedMatchUrl = useDebounce(state.matchURL, 300);

  useEffect(() => {
    if (state.targetURL && curLang !== 'global') {
      setIsFetchingUrlStatus(true);
    }
    setIsFetchingUrlStatus(false);

    if (debouncedMatchUrl && (validateValueWithType(debouncedMatchUrl, ValidationTypes.path) === '' || `${curCountry}/${curLang}`)) {
      getPathError(debouncedMatchUrl, policy, existingRule?.docRefId).catch((error) => error);
    }
    if (debouncedTargetUrl && curLang !== 'global' && validateValueWithType(debouncedTargetUrl, ValidationTypes.url) === '') {
      getUrlStatus(debouncedTargetUrl).catch((error) => error);
    }
  }, [debouncedMatchUrl, state.targetURL, debouncedTargetUrl]);

  const errorMessages = {
    targetUrlError: validateValueWithType(state.targetURL, ValidationTypes.url, curCountry, curLang),
    targetStatusError: validateValueWithType(String(state.targetStatus), ValidationTypes.httpStatus, undefined, undefined, state.dateStart),
    matchUrlError:
      validateValueWithType(state.matchURL, getValidationType(policy), curCountry, curLang) ||
      validateValueWithType(state.pathExist, ValidationTypes.pathExist),
    dateStartError: validateValueWithType(state.dateStart, ValidationTypes.date),
    dateEndError: validateValueWithType(state.dateEnd, ValidationTypes.date),
  };

  const isPublishDisabled = !state?.targetURL || !state?.matchURL || (Object.values(errorMessages).some((e) => e) && !isAdminUser);
  return (
    <Modal
      visible={isVisible}
      escapable={false}
      handleCloseBtn={() => {
        dispatch({ type: 'SET_INIT' });
        onCancel();
      }}>
      <Styled.PromptWithWidth
        titleId={'Add/Edit Rule'}
        title={modalTitle}
        header={<ModalHeader />}
        footer={
          <ModalFooter renderBorder>
            <Button
              text={'Cancel'}
              type={'secondary'}
              onClick={() => {
                dispatch({ type: 'SET_INIT' });
                onCancel();
              }}
              disabled={state.isLoading}
            />
            <Button
              text={'Publish'}
              data-testid='publish-button'
              type={'primary'}
              onClick={() => publishRule(existingRule)}
              disabled={isPublishDisabled}
              loading={state.isLoading}
            />
          </ModalFooter>
        }>
        {state.inlineMessage.isShown && <InlineMessage {...state.inlineMessage?.message} />}

        <Styled.RadioButtonGroupPosition list={RadioButtonsArray} name='Redirect type' disabled={state.isLoading} />

        <Styled.FlexWithWidth mt={16} gap={1}>
          <DateInputField
            disabled={state.isLoading}
            shouldValidate={state.dateStart.length >= 1}
            id={'dateStart'}
            label={'Start date'}
            onChange={(e) => dispatch({ type: 'SET_DATE_START', payload: e.target.value.trim() })}
            errorMessage={errorMessages.dateStartError}
            value={state.dateStart}
          />
          <DateInputField
            shouldValidate={state.dateEnd.length >= 1}
            id={'dateEnd'}
            label={'End date'}
            onChange={(e) => dispatch({ type: 'SET_DATE_END', payload: e.target.value.trim() })}
            errorMessage={errorMessages.dateEndError}
            value={state.dateEnd}
            disabled={state.redirectType !== 302 || state.isLoading}
          />
        </Styled.FlexWithWidth>
        <TextInputField
          disabled={state.isLoading}
          shouldValidate={state.matchURL.length >= 1}
          id={'matchUrl'}
          label={'Redirect from path'}
          onChange={(e) => dispatch({ type: 'SET_MATCH_URL', payload: e.target.value.trim() })}
          errorMessage={errorMessages.matchUrlError}
          value={state.matchURL}
        />
        <TextInputField
          disabled={state.isLoading}
          shouldValidate={state.targetURL.length >= 1}
          id={'targetUrl'}
          label={'Redirect to URL'}
          onChange={(e) => dispatch({ type: 'SET_TARGET_URL', payload: e.target.value.trim() })}
          errorMessage={errorMessages.targetUrlError || errorMessages.targetStatusError}
          value={state.targetURL}
        />
        <Styled.TargetStatusContainer>
          <Text bodySize='m'>Target Status</Text>
          <Styled.TargetStatusMessage>
            {isFetchingUrlStatus ? (
              <Styled.TargetStatusMessageLoader>
                <LoadingBall size={'medium'} />
              </Styled.TargetStatusMessageLoader>
            ) : (
              <StatusIcon status={state.targetStatus} startDate={state.dateStart} />
            )}
          </Styled.TargetStatusMessage>
        </Styled.TargetStatusContainer>
        <TextInputField
          disabled={state.isLoading}
          shouldValidate={false}
          id={'name'}
          label={'Name'}
          onChange={(e) => dispatch({ type: 'SET_RULE_NAME', payload: e.target.value.trimStart() })}
          value={state.name}
        />
      </Styled.PromptWithWidth>
    </Modal>
  );
};
