import React, { useState } from 'react';
import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import Loading, { LoadingBall } from '@ingka/loading';
import SpeechBubble from '@ingka/ssr-icon/paths/speech-bubble';
import { useSaveSurveyMutation } from '__generated__/types';
import { SurveyModal } from './SurveyModal';
import * as Styled from 'components/seo/styles';

export const FeedbackSurvey: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const initialState = {
    data: false,
    error: false,
    sent: false,
  };
  const [{ data, error, sent }, setResult] = useState<{ data: boolean; error: boolean; sent: boolean }>(initialState);
  const [saveSurvey, { loading }] = useSaveSurveyMutation();
  return (
    <Styled.FeedbackButtonContainer>
      <Button
        type={'primary'}
        expanding
        text={'Give feedback'}
        size={'small'}
        ssrIcon={SpeechBubble}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <SurveyModal
        isVisible={isOpen}
        question={'What can be done better?'}
        onClickClose={() => setIsOpen(false)}
        buttons={
          <>
            <Button
              text="Close"
              type={sent ? 'primary' : 'secondary'}
              size={'small'}
              onClick={async () => {
                setIsOpen(false);
                setResult(initialState);
                setText('');
              }}
            />
            {!sent && (
              <Button
                text="Send"
                type="primary"
                size={'small'}
                disabled={!text.trim() || loading}
                onClick={async () => {
                  const res = await saveSurvey({ variables: { survey: { question: 'feedback', answer: text } } });
                  setResult({ data: !!res.data?.saveSurveyAnswer, error: !res.data?.saveSurveyAnswer, sent: true });
                }}
              />
            )}
          </>
        }
      >
        <p>Your feedback help us better understand if we are creating value and where we need to improve.</p>
        <p>
          If you wish to report a bug, please refer to this{' '}
          <Hyperlink newWindow text={'Jira portal'} url={'https://jira.digital.ingka.com/servicedesk/customer/portal/16/create/961'} />.
        </p>
        {loading && (
          <Loading text={'Please wait...'}>
            <LoadingBall />
          </Loading>
        )}
        {!loading && !sent && (
          <Styled.SurveyTextArea
            label={''}
            id="feedback-text-area"
            disabled={loading || sent}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        )}
        {!loading && sent && (
          <>
            <Styled.ConfirmationMessage>
              {data && !error && '✅ Thank you for you feedback!'}
              {!data && error && '⚠️ An error occurred! Please try again later.'}
            </Styled.ConfirmationMessage>
            {data && <p></p>}
          </>
        )}
      </SurveyModal>
    </Styled.FeedbackButtonContainer>
  );
};
