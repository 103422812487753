/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as dag from 'd3-dag';
import { CatalogReply } from './Catalog';
import { FullscreenSVG, InfoBox, InfoContainer } from './styles';

interface Coords {
  x: number;
  y: number;
}

interface Info {
  name: string;
  deepId: string;
  parents: number;
  children: number;
  url: string;
}

interface SugiyamaProps {
  data: CatalogReply;
}

export const Sugiyama: React.FC<SugiyamaProps> = ({ data }) => {
  const ref = useRef<SVGSVGElement>(null);
  const [info, setInfo] = useState<Info>();

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
    }

    const stratifiedData = dag.dagStratify()(data.structure);

    const width = 200 + data.structure.length * 20;
    const height = 200 + data.structure.length * 8;

    dag.sugiyama().size([width, height]).layering(dag.layeringSimplex()).decross(dag.decrossTwoLayer()).coord(dag.coordCenter())(stratifiedData);

    const svg = d3.select(ref.current);

    const zoom = d3.zoom();
    const g = svg
      .attr('viewBox', `0 0 ${width + 200} ${height + 200}`)
      .call(
        zoom.on('zoom', function (this, event) {
          g.attr('transform', event.transform);
        }) as any
      )
      .append('g');

    svg.call(zoom.transform as any, d3.zoomIdentity.scale(1));

    const defs = svg.append('defs');

    const steps = stratifiedData.size();
    const colorMap: string[] = [];

    Array.from(stratifiedData.idescendants()).forEach((node, i) => {
      colorMap[node.data.id as any] = d3.interpolateRainbow(i / steps);
    });

    // How to draw edges
    const line = d3
      .line<Coords>()
      .curve(d3.curveCatmullRom)
      .x((d) => d.x)
      .y((d) => d.y);

    // Plot edges
    g.append('g')
      .selectAll('path')
      .data(stratifiedData.links())
      .enter()
      .append('path')
      .attr('d', ({ points }) => line(points))
      .attr('fill', 'none')
      .attr('stroke-width', 3)
      .attr('stroke', ({ source, target }: any) => {
        const gradId = `${source.data.id}-${target.data.id}`;
        const grad = defs
          .append('linearGradient')
          .attr('id', gradId)
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', source.x)
          .attr('x2', target.x)
          .attr('y1', source.y)
          .attr('y2', target.y);
        grad.append('stop').attr('offset', '0%').attr('stop-color', colorMap[source.data.id]);
        grad.append('stop').attr('offset', '100%').attr('stop-color', colorMap[target.data.id]);
        return `url(#${gradId})`;
      });

    // Select nodes
    const nodes = g
      .append('g')
      .selectAll('g')
      .data(stratifiedData.descendants())
      .enter()
      .append('g')
      .attr('transform', ({ x, y }: any) => `translate(${x}, ${y})`);

    // Plot node circles
    nodes
      .append('circle')
      .attr('r', 25)
      .attr('fill', (n) => colorMap[n.data.id as any])
      .on('mouseover', function (this, event, d) {
        const res = data.resources.find((res) => res.id === d.data.id);
        const node = data.structure.find((node) => node.id === d.data.id);

        setInfo({
          name: res?.name || '',
          deepId: res?.deepId || '',
          parents: node?.parentIds.length || 0,
          children: res?.subCategories.length || 0,
          url: res?.url || '',
        });
      });

    // Add text to nodes
    nodes
      .append('text')
      .text((d) => d.data.id)
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('fill', 'white')
      .attr('pointer-events', 'none');
  }, [data]);

  return (
    <>
      <FullscreenSVG ref={ref} />
      <InfoContainer>
        {info && (
          <InfoBox>
            <b>Name:</b> {info.name}
            <br />
            <b>DeepID:</b> {info.deepId}
            <br />
            <b>Parents:</b> {info.parents}
            <br />
            <b>Children:</b> {info.children}
            <br />
            <b>URL:</b>
            <a href={info.url}>{info.url}</a>
          </InfoBox>
        )}
      </InfoContainer>
    </>
  );
};
