import React, { FC } from 'react';
import * as Styled from './styles';

interface MenuItem {
  id: string;
  label: string;
}

type EditSheetSideMenuProps = {
  menuItems: MenuItem[];
  activeItem: string;
  onItemSelect: (id: string) => void;
};
export const EditSheetSideMenu: FC<EditSheetSideMenuProps> = ({ menuItems, activeItem, onItemSelect }) => {
  const isItemActive = (id: string) => (id === activeItem ? 'true' : undefined);
  return (
    <Styled.SideMenu direction='column' gap={0.5}>
      {menuItems.map((menuItem) => (
        <Styled.SideMenuItem
          data-testid={`test-id-${menuItem.id}`}
          key={menuItem.id}
          active={isItemActive(menuItem.id)}
          onClick={() => onItemSelect(menuItem.id)}>
          <Styled.SideMenuItemText headingSize='xs' active={isItemActive(menuItem.id)}>
            {menuItem.label}
          </Styled.SideMenuItemText>
        </Styled.SideMenuItem>
      ))}
    </Styled.SideMenu>
  );
};
