import React from 'react';
import { Tab, TabPanel } from '@ingka/tabs';
import Container from 'components/common/Container';
// import Margin from 'components/common/Margin';
import Tabs from 'components/common/Tabs';
import SitemapGenerator from 'components/sitemaps/SitemapsGenerator';
import { SitemapStatsComponent } from 'components/sitemaps/SitemapStatsComponent';
import { UrlSearchComp } from 'components/sitemaps/UrlSearchComp';
import { PageHeader } from 'pages/styles';

export const SitemapsPage: React.FC = () => (
  <Container page='sitemaps'>
    <PageHeader>Sitemaps</PageHeader>
    <Tabs
      tabs={[
        <Tab key={'tab-1'} tabPanelId={'generator'} text={'Generator'} />,
        <Tab key={'tab-2'} tabPanelId={'stats'} text={'Statistics'} />,
        <Tab key={'tab-3'} tabPanelId={'search'} text={'Search url'} />,
      ]}
      tabPanels={[
        <TabPanel key={'panel-1'} tabPanelId={'generator'}>
          <SitemapGenerator />
        </TabPanel>,
        <TabPanel key={'panel-2'} tabPanelId={'stats'}>
          <SitemapStatsComponent />
        </TabPanel>,
        <TabPanel key={'panel-3'} tabPanelId={'search'}>
          <UrlSearchComp />
        </TabPanel>,
      ]}
      defaultActiveTab={'generator'}
    />
  </Container>
);
