import React, { useState } from 'react';
import Button from '@ingka/button';
import Checkbox from '@ingka/checkbox';
import gear from '@ingka/ssr-icon/paths/gear';
import Text from '@ingka/text';
import * as StyledLocal from 'components/CheckboxGroup/styles';
import { FloatingMenu } from 'components/FloatingMenu';
import { FloatingMenuContent } from 'components/FloatingMenu/FloatingMenuContent';
import * as StyledGlobal from 'components/styles';
const Styled = { ...StyledGlobal, ...StyledLocal };

export type CheckboxFields = { checked: boolean; label: string; value: string; id: string };

type CheckboxGroupProps = {
  onApply: (visibleColumns: string[]) => void;
  defaultCheckboxes: CheckboxFields[];
};
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ onApply, defaultCheckboxes }) => {
  const defaultCheckedCheckBoxesIds = defaultCheckboxes.filter((c) => c.checked).map((c) => c.id);
  const allCheckBoxesIds = defaultCheckboxes.map((c) => c.id);
  const [checkboxes, setCheckboxes] = useState<string[]>(defaultCheckedCheckBoxesIds);

  const countTotalCheckboxes = allCheckBoxesIds.length;
  const countCheckedCheckboxes = checkboxes.length;
  const areNoCheckboxesChecked = countCheckedCheckboxes === 0;
  const areAllCheckboxesChecked = countCheckedCheckboxes === countTotalCheckboxes;
  const areSomeCheckboxesChecked = !areNoCheckboxesChecked && !areAllCheckboxesChecked;

  const resetToDefaultCheckboxes = () => setCheckboxes(defaultCheckedCheckBoxesIds);

  const toggleSelectAll = () => {
    if (areAllCheckboxesChecked) {
      setCheckboxes([]);
    } else {
      setCheckboxes(allCheckBoxesIds);
    }
  };

  const toggleCheckbox = (id: string) => {
    if (checkboxes.includes(id)) {
      setCheckboxes((prevCheckboxIds) => prevCheckboxIds.filter((checkbox) => checkbox !== id));
    } else {
      setCheckboxes((prevCheckboxIds) => [...prevCheckboxIds, id]);
    }
  };
  const isChecked = (id: string) => checkboxes.includes(id);

  return (
    <FloatingMenu preventCloseOnClick>
      {(isOpen, toggleOpen) => (
        <>
          <Button
            data-testid={'floating-menu'}
            size={'small'}
            text={'Columns'}
            iconPosition={'leading'}
            type={'tertiary'}
            ssrIcon={gear}
            onClick={toggleOpen}
          />

          {isOpen && (
            <FloatingMenuContent leftPosition="-210px">
              <Styled.CheckboxGroup>
                <Text tagName="p" bodySize="s" data-testid={'selected-checkboxes-count'}>
                  {countCheckedCheckboxes} out of {countTotalCheckboxes} selected
                </Text>
                <Checkbox
                  id={'selectAll'}
                  value={'selectAll'}
                  label={'Select all'}
                  checked={areAllCheckboxesChecked}
                  indeterminate={areSomeCheckboxesChecked}
                  onChange={toggleSelectAll}
                />
                <Styled.CheckboxGrid>
                  {defaultCheckboxes.map(({ id, label }) => (
                    <Checkbox
                      name={label}
                      key={id}
                      label={label}
                      id={`checkbox-${id}`}
                      value={id}
                      checked={isChecked(id)}
                      onChange={() => toggleCheckbox(id)}
                    />
                  ))}
                </Styled.CheckboxGrid>
                <Styled.ButtonFooter>
                  <Button text="Reset" type="tertiary" size={'small'} className="inner_padding_over_ride" onClick={resetToDefaultCheckboxes} />
                  <Button
                    text="Cancel"
                    type="tertiary"
                    size={'small'}
                    className="inner_padding_over_ride"
                    onClick={() => {
                      resetToDefaultCheckboxes();
                      toggleOpen();
                    }}
                  />
                  <Button
                    text="Apply"
                    type="primary"
                    disabled={areNoCheckboxesChecked}
                    size={'small'}
                    onClick={() => {
                      onApply(checkboxes);
                      toggleOpen();
                    }}
                  />
                </Styled.ButtonFooter>
              </Styled.CheckboxGroup>
            </FloatingMenuContent>
          )}
        </>
      )}
    </FloatingMenu>
  );
};
