import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { defaultFilter, MetaDataEntryTable } from 'components/seo/metadataView/MetadataEntryTable';
import { FilterInput } from '__generated__/types';

export const getFilterFromSearchQueryParams = (searchQuery: string): Partial<FilterInput> | undefined => {
  if (!(searchQuery.startsWith('?') && searchQuery.includes('='))) return;
  const query = searchQuery.split('?').pop();
  if (!query) return;
  const [key, value] = query.split('=');
  if (key in defaultFilter) {
    return { [key]: JSON.parse(value) };
  }
};
export const DataViewPage: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const { id } = props.match.params;
  const { search } = useLocation();
  const preFilter = getFilterFromSearchQueryParams(search);
  return <MetaDataEntryTable id={id} preFilter={preFilter} />;
};
