import React, { useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import * as Styled from './styled';

type MetaDataSearchProps = {
  onSearchChange: (value: string) => void;
};
export const MetaDataSearch: React.FC<MetaDataSearchProps> = ({ onSearchChange }) => {
  const [inputFieldText, setInputFieldText] = useState('');
  const debouncedValue = useDebounce(inputFieldText, 600);

  useEffect(() => {
    onSearchChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Styled.SearchHalfWidth
      id={'filter'}
      type={'text'}
      placeholder={'Search by keyword'}
      onChange={(e) => setInputFieldText(e.target.value)}
      value={inputFieldText}
      onClear={() => setInputFieldText('')}
    />
  );
};
