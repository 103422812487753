import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import { Prompt } from '@ingka/modal';
import { ModalHeader } from '@ingka/modal';
import { client } from 'api/graphql';
import { TRANSLATE_TO_TARGET_LANGUAGE } from 'components/admin/queries';
import { TextAreaInputField } from './TextAreaInputField';
import { TranslationModalContainer, TranslationModalFooter, TranslationModalInputContainer } from './styles';

interface TranslationModalButtonProps {
  targetLanguage?: string;
  visible: boolean;
  onModalClose: () => void;
  originalText: string;
  onSaveTranslation: (value: string) => void;
}
export const TranslationModalButton: React.FC<TranslationModalButtonProps> = ({
  visible,
  onSaveTranslation,
  originalText,
  onModalClose,
  targetLanguage,
}) => {
  const [editedTranslatedText, setEditedTranslatedText] = useState('');
  const [enableTranslationInput, setEnableTranslationInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSaveBtn = () => {
    onSaveTranslation(editedTranslatedText);
    setEnableTranslationInput(!enableTranslationInput);
  };

  const handleModalClose = () => {
    onModalClose();
    setEnableTranslationInput(false);
  };

  const fetchTranslation = async (targetLanguage: string) => {
    const translation = await client.query<{
      translate: string;
    }>({
      query: TRANSLATE_TO_TARGET_LANGUAGE,
      variables: { targetLanguage, text: originalText },
    });
    return translation;
  };

  useEffect(() => {
    targetLanguage &&
      fetchTranslation(targetLanguage)
        .then((translation) => {
          setEditedTranslatedText(translation.data.translate);
          setIsLoading(false);
        })
        .catch();
  }, []);
  return (
    <TranslationModalContainer visible={visible} handleCloseBtn={handleModalClose}>
      <Prompt
        titleId={`id-${targetLanguage}-translation-modal`}
        title={`Translate to ${targetLanguage}`}
        header={<ModalHeader />}
        footer={
          <TranslationModalFooter>
            <Button text="Save" type="primary" size={'small'} disabled={!enableTranslationInput} onClick={handleSaveBtn} />
            <Button text="Close" type="secondary" size={'small'} onClick={handleModalClose} />
          </TranslationModalFooter>
        }
      >
        {isLoading && (
          <>
            <p>Translating...</p>
            <Loading text={'Please wait...'}>
              <LoadingBall />
            </Loading>
          </>
        )}
        {!isLoading && (
          <TranslationModalInputContainer gap={2}>
            <TextAreaInputField
              id={'translation-original'}
              disabled
              label={'Original'}
              shouldValidate={false}
              value={originalText}
              onChange={() => ({})}
            />
            <TextAreaInputField
              id={'translation-translated'}
              label="Translation"
              shouldValidate={false}
              value={editedTranslatedText}
              onFocus={() => setEnableTranslationInput(true)}
              onChange={(e) => setEditedTranslatedText(e.target.value)}
            />
          </TranslationModalInputContainer>
        )}
      </Prompt>
    </TranslationModalContainer>
  );
};
