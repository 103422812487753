import { useContext, useEffect } from 'react';
import { validationFunctions } from 'opt-util/validation';
import { Context as EditorContext, FormKeys } from './contexts/EditorContext';

export const useValidation = (fieldId: FormKeys, value: string | undefined): { errorMessage: string | undefined } => {
  const { state, setError } = useContext(EditorContext);
  useEffect(() => {
    const validate = async () => {
      if (!state.versionDetails || !state.formData || fieldId === 'isOptimized' || fieldId === 'descriptionIsSynced' || fieldId === 'needsReview')
        return;
      const { type, versionId } = state.versionDetails;
      const error = await validationFunctions[fieldId](value, versionId, state.formData.id, type);
      if (state.errors[fieldId] !== error) {
        setError(fieldId, error);
      }
    };
    validate();
  }, [value]);

  return { errorMessage: state.errors[fieldId] };
};
