import React, { PropsWithChildren } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { environment } from 'environment';
import { AnnouncementBanner } from 'components/admin/announcement/AnnouncementBanner';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeedbackSurvey } from 'components/seo/survey';
import { Header } from 'features/Header';
import { SideNav } from 'features/Header/SideNav';
import { MetaDataEditor } from 'features/MetaData/MetaDataEditor';
import { Provider as MetaDataProvider } from 'hooks/contexts/MetaDataContext';
import { Provider as RulesContext } from 'hooks/contexts/RulesContext';
import { Provider as SideNavProvider } from 'hooks/contexts/SideNavContext';
import { Provider as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { useCountry } from 'hooks/useCountry';
import { emptyOrNil } from 'opt-util/helpers';
import { isLoggedIn } from 'redux/selectors/login';
import { RootState } from 'redux/store';
import { Elm } from 'types/React';
import { UploadRulesDiff } from './RulesPage/UploadRulesDiff';
import { DataDiffPage } from './seo/DataDiffPage';
import { DataViewPage } from './seo/DataViewPage';
import UploadCountryPage from './seo/UploadCountryPage';
import UploadPage from './seo/UploadPage';
import { UploadResultPage } from './seo/UploadResultPage';
import { SitemapsPage } from './sitemaps/SitemapsPage';
import { SitemapStatsDetailView } from './sitemaps/SitemapStatsDetailView';
import { AdminPage } from './AdminPage';
import { BookmarkPage } from './BookmarkPage';
import { CountrySelectPage } from './CountrySelectPage';
import { Dashboard } from './Dashboard';
import { GraphsPage } from './GraphsPage';
import LoginContainer from './LoginRedirect';
import { MetaData } from './Metadata';
import { RedirectsPage } from './RedirectsPage';
import { RulesPage } from './RulesPage';
import * as Styled from './styles';

export const PageWrapper: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
  return (
    <div className={'page'}>
      <StatusMessageContext>
        <RulesContext>
          <MetaDataProvider>
            <AnnouncementBanner />
            <ErrorBoundary>
              <Header />
            </ErrorBoundary>
            <ErrorBoundary>
              <Styled.PageContainer>
                <SideNav />
                <Styled.PageContent>{props.children}</Styled.PageContent>
              </Styled.PageContainer>
            </ErrorBoundary>
          </MetaDataProvider>
        </RulesContext>
      </StatusMessageContext>
    </div>
  );
};
// eslint-disable-next-line @typescript-eslint/ban-types
const wrapped =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T): Elm<T> =>
    (
      <PageWrapper>
        <Component {...props} />
      </PageWrapper>
    );

export const AuthenticatedRoutes: React.FC = () => (
  <>
    <Switch>
      <Route path={'/'} component={wrapped(Dashboard)} exact />
      <Route path={'/dashboard/:type'} component={wrapped(Dashboard)} exact />
      <Route path={'/admin/:tool'} component={wrapped(AdminPage)} />
      <Route path={'/sitemaps'} exact component={wrapped(SitemapsPage)} />
      <Route path={'/sitemaps/stats/:language-:country'} component={wrapped(SitemapStatsDetailView)} />
      <Route path={'/graphs'} component={wrapped(GraphsPage)} />
      <Route path={'/redirects/:country'} component={wrapped(RedirectsPage)} exact />
      <Route path={'/redirects/:country/:policy'} component={wrapped(RulesPage)} exact />
      <Route path={'/redirects/:country/:policy/upload-diff/:id'} component={wrapped(UploadRulesDiff)} exact />
      <Route path={'/metadata'} component={wrapped(MetaData)} exact />
      <Route path={'/metadata/upload/:country/:language/:type'} component={wrapped(UploadCountryPage)} />
      <Route path={'/metadata/upload'} component={wrapped(UploadPage)} />
      <Route path={'/metadata/uploadResult/:id'} component={wrapped(UploadResultPage)} />
      <Route path={'/metadata/view/:id'} component={wrapped(DataViewPage)} />
      <Route path={'/metadata/diff/:id'} component={wrapped(DataDiffPage)} />
      <Route path={'/bookmarklet'} component={wrapped(BookmarkPage)} />
      <Route path={'/metadata/edit/:country/:language/:type/:entryId?'} component={wrapped(MetaDataEditor)} />
    </Switch>
  </>
);

export const AllRoutes: React.FC<RouteSwitchState & ReactCookieProps> = (props) => {
  const params = new URLSearchParams(window.location.search);
  const tokenCookie = props.cookies && props.cookies.get('azure_token');
  const token = tokenCookie || params.get('token');
  const [country] = useCountry();
  const tokenEmpty = emptyOrNil(token);
  const originalPath = sessionStorage.getItem('originalPath');
  if (props.loggedIn) {
    if (!country) {
      return (
        <Router>
          <CountrySelectPage />
        </Router>
      );
    }
    return (
      <Router>
        <SideNavProvider>
          <AuthenticatedRoutes />
        </SideNavProvider>
        <FeedbackSurvey />
      </Router>
    );
  }
  if (!tokenEmpty) {
    return (
      <Router>
        <LoginContainer />
      </Router>
    );
  } else {
    if (!originalPath) {
      sessionStorage.setItem('originalPath', window.location.search ? window.location.pathname + window.location.search : window.location.pathname);
    }
    window.location.replace(environment.serviceUrl + environment.backendLoginPath);
    return null;
  }
};

interface RouteSwitchState {
  loggedIn: boolean;
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: isLoggedIn(state),
});

export const Routes = connect(mapStateToProps)(withCookies<RouteSwitchState & ReactCookieProps>(AllRoutes));
