import styled from '@emotion/styled';
import { space25, space100 } from '@ingka/variables';

export const StatusIcon = styled.div<{ color: string }>`
  display: flex;
  padding: ${space100} 0px;
  align-items: center;
  gap: ${space25};
  color: ${(props) => props.color};
  min-width: 9em;
`;
