import React, { FC } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import crossCircle from '@ingka/ssr-icon/paths/cross-circle';
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { isFutureDate } from 'opt-util/helpers';
import * as StyledLocal from './styled';

const Styled = { ...StyledLocal };
export const StatusIcon: FC<{ status: string | number; startDate: string }> = ({ status, startDate }) => {
  const isScheduled = isFutureDate(startDate);
  switch (status) {
    case 200:
      return (
        <Styled.TargetStatusSuccess>
          <SSRIcon paths={checkmarkCircle} height={'20px'} />
          {status}
        </Styled.TargetStatusSuccess>
      );
    case 'None':
      return (
        <Styled.TargetStatusNone>
          <SSRIcon paths={questionMarkCircle} height={'20px'} />
          {status}
        </Styled.TargetStatusNone>
      );
    case null:
      return <Styled.TargetStatusNone>Unknown</Styled.TargetStatusNone>;
    default:
      if (isScheduled && status === 404) {
        return (
          <Styled.TargetStatusWarning>
            <SSRIcon paths={warningTriangle} height={'20px'} />
            {status}
          </Styled.TargetStatusWarning>
        );
      }
      return (
        <Styled.TargetStatusError>
          <SSRIcon paths={crossCircle} height={'20px'} />
          {status}
        </Styled.TargetStatusError>
      );
  }
};
