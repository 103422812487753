import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from '@ingka/text';
import { useCountry } from 'hooks/useCountry';
import { getCountryName } from 'opt-util/helpers';
import * as Styled from './styles';

interface MetaDataCountrySwapperProps {
  newCountry: string;
  newLanguage: string;
  entryId: string;
}

export const MetaDataCountrySwapper: React.FC<MetaDataCountrySwapperProps> = ({ newCountry, newLanguage, entryId }) => {
  const [, setCountry] = useCountry();
  const history = useHistory();
  const countrySwapClick = () => {
    const selectedCountry = {
      countryCode: newCountry,
      countryName: getCountryName(newCountry).toLowerCase(),
    };

    setCountry(selectedCountry);
    history.push(`/metadata/edit/${newCountry}/${newLanguage}/PLP/${entryId}`);
  };

  return (
    <Styled.CountrySwapperWrapper direction="column">
      <Text tagName="h1" headingSize="m">
        Swap Country
      </Text>
      <Text tagName="p" bodySize="m">
        It appears your magic-bookmark-country does not match the selected country in Optimera. To swap country and continue to Edit, please click the
        button below.
      </Text>

      <Styled.CountrySwapperButton text={'Swap country and continue'} type={'primary'} onClick={() => countrySwapClick()} size={'small'} />
    </Styled.CountrySwapperWrapper>
  );
};
