import React from 'react';
import { Chart } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS, LinearScale, LineController, LineElement, PointElement, Title } from 'chart.js';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

export const LineChart: React.FC<{ title: string; data: number[]; labels: string[] }> = (props) => {
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        align: 'end' as const,
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: props.title,
      },
    },
    scales: {
      x: {
        ticks: {
          source: 'auto',
          maxTicksLimit: 6,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 4,
        },
      },
    },
  };
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        fills: false,
        label: props.title,
        backgroundColor: 'rgba(0, 88, 171, 1)',
        borderColor: 'rgba(0, 88, 171, 1)',
        pointBorderColor: 'rgba(0, 88, 171, 0)',
        pointBackgroundColor: 'rgba(0, 88, 171, 0)',
        tension: 0.4,
      },
    ],
  };
  return <Chart data={data} options={lineChartOptions} type={'line'} />;
};
