import React from 'react';
import Text from '@ingka/text';
import * as Styled from './styles';

interface MetaDataStrippedTableItemProps {
  entryId: string;
  searchClicks: number | undefined;
  searchCtr: string | undefined;
  searchImpressions: number | undefined;
  avgPosition: number | undefined;
  suggestedPrimaryKeyword: string | undefined | null;
  suggestedSecondaryKeyword: string | undefined | null;
  suggestedAlternativeKeyword: string | undefined | null;
}

export const MetaDataStrippedTableItem: React.FC<MetaDataStrippedTableItemProps> = ({
  entryId,
  searchClicks,
  searchCtr,
  searchImpressions,
  avgPosition,
  suggestedPrimaryKeyword,
  suggestedSecondaryKeyword,
  suggestedAlternativeKeyword,
}) => {
  const seoMetric = [
    { title: 'Clicks', value: searchClicks },
    { title: 'CTR', value: searchCtr },
    { title: 'Impressions', value: searchImpressions },
    { title: 'Average', value: avgPosition },
  ];

  const suggestedKeywords = [
    { title: 'Primary', value: suggestedPrimaryKeyword },
    { title: 'Secondary', value: suggestedSecondaryKeyword },
    { title: 'Alternative', value: suggestedAlternativeKeyword },
  ];

  return (
    <Styled.MetaDataCollapsedWrapper data-testid={`${entryId}-expanded-row`}>
      <Styled.MetaDataCollapsedWrapperCell>
        <Styled.MetaDataCollapsedHeader>
          <Text>SEO metrics</Text>
          <Text>Suggested keywords</Text>
        </Styled.MetaDataCollapsedHeader>

        <Styled.MetaDataCollapsedRow>
          <Styled.MetaDataCollapsedGroupedCell>
            {seoMetric.map(({ title, value }, index) => (
              <div key={`${index}-${title}`}>
                <Text>{title}</Text>
                <Text>{value}</Text>
              </div>
            ))}
          </Styled.MetaDataCollapsedGroupedCell>
          <Styled.MetaDataCollapsedGroupedCellKeywords>
            {suggestedKeywords.map(({ title, value }, index) => (
              <div key={`${index}-${title}`}>
                <Text>{title}</Text>
                <Text>{value}</Text>
              </div>
            ))}
          </Styled.MetaDataCollapsedGroupedCellKeywords>
        </Styled.MetaDataCollapsedRow>
      </Styled.MetaDataCollapsedWrapperCell>
    </Styled.MetaDataCollapsedWrapper>
  );
};
