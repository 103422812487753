import React from 'react';
import { is } from 'ramda';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type RenderFunction = (value: any, label?: string, rowData?: any) => void;

export interface Column {
  key: string;
  label: string;
  render?: RenderFunction;
}

interface TableCellProps {
  label: string;
  render?: RenderFunction;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  rowData: any;
}
interface TableRowProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
  columns: Column[];
  testid?: string | TestidFn;
}

interface TableHeaderProps {
  columns: Column[];
}
type TableBodyProps = TableHeaderProps & {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any[];
  testid?: string | TestidFn;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type TestidFn = (row: any) => string;
export type TableProps = TableHeaderProps &
  TableBodyProps & {
    name?: string;
    striped?: boolean;
    verticalCenter?: boolean;
    testid?: string | TestidFn;
  };

export const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => (
  <thead>
    <tr>
      {columns.map((v, index) => (
        <th key={index}>{v.label}</th>
      ))}
    </tr>
  </thead>
);
const TableCell: React.FC<TableCellProps> = ({ label, render, value, rowData }) => (
  <td data-title={label}>{render && is(Function, render) ? render(value, label, rowData) : value}</td>
);

const TableRow: React.FC<TableRowProps> = ({ columns, testid, data }) => {
  let id = '';
  if (testid) {
    id = typeof testid === 'string' ? `${testid}-row` : testid(data);
  }
  return (
    <tr data-testid={id}>
      {columns.map((column, index) => (
        <TableCell key={index} label={column.label} render={column.render} value={data[column.key] ?? data} rowData={data} />
      ))}
    </tr>
  );
};

const TableBody: React.FC<TableBodyProps> = ({ testid, columns, data }) => (
  <tbody>
    {data.map((row, index) => (
      <TableRow key={index} data={row} columns={columns} testid={testid} />
    ))}
  </tbody>
);

const stripedClass = (striped = false) => (striped ? 'table--striped' : '');
const verticalCenterClass = (verticalCenter = false) => (verticalCenter ? 'vertical-center' : '');

const Table: React.FC<TableProps> = ({ columns, data = [], name, striped, verticalCenter, testid }) => (
  <table
    className={`table ${stripedClass(striped)} ${verticalCenterClass(verticalCenter)}`}
    aria-label={name}
    data-testid={typeof testid === 'string' ? testid : undefined}>
    <TableHeader columns={columns} />
    <TableBody columns={columns} data={data} testid={testid} />
  </table>
);

export default Table;
