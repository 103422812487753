import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import { fontWeightBold, fontWeightRegular } from '@ingka/variables';
import { colourCommercialMessageBtiYellow, colourNeutral2, colourNeutral4, colourNeutral5, colourNeutral7 } from '@ingka/variables/colours-css';

export const Header = styled.div`
  width: 100%;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: #003e72;
  padding: 15px 20px 15px 20px;
`;

export const MenuContainerLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  width: 196px;
  position: relative;

  a {
    color: #ffdb00;
    text-decoration: none;
  }
`;

export const MenuContainerRight = styled.div`
  display: flex;
  gap: 20px;
`;

export const BurgerMenu = styled(Button)`
  color: white;
  left: 0;
  position: absolute;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0px;
`;

export const PlaceholderIcon = styled(SSRIcon)`
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  color: white;
  border-radius: 50%;
  padding: 7px;
`;

export const SideNavItemName = styled.span<{ isActive: boolean }>`
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? colourNeutral7 : colourNeutral5)};
  font-weight: ${({ isActive }) => (isActive ? fontWeightBold : fontWeightRegular)};
`;

export const SideNavItemIcon = styled.span<{ isActive: boolean }>`
  .btn__inner {
    background-color: ${({ isActive }) => isActive && `${colourCommercialMessageBtiYellow} !important`};
  }
`;

export const SideNav = styled.div`
  height: calc(100vh - 100px);
  max-width: 218px;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SideNavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 20px;
  width: max-content;
  height: 40px;
  text-decoration: none;
  :hover {
    text-decoration: none;

    span {
      color: ${colourNeutral7};
    }

    .btn__inner {
      background-color: ${colourNeutral2};
    }
  }
  :active {
    .btn__inner {
      background-color: ${colourNeutral4};
    }
  }
`;

export const UrlList = styled.div`
  display: grid;
  gap: 25px;
`;

export const MenuContainerLeftWithSearch = styled.div`
  display: flex;
  align-items: center;
  color: #ffdb00;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  width: 50%;
  position: relative;

  .search {
    margin-left: 20px;
    width: 100%;

    label {
      color: #767676;
    }
  }
`;

export const BackIcon = styled(Button)`
  color: white;
  left: 0;
  position: absolute;
`;

export const CountryText = styled.div`
  margin-left: 60px;

  p {
    margin-bottom: 0;
    text-transform: uppercase;
    width: 85px;

    &:first-of-type {
      font-size: 12px;
      font-weight: 400;
      text-transform: none;
    }
  }

  .header__country-name--short {
    width: 120px;
  }
`;

export const EditHeaderText = styled(Text)`
  color: #ffdb00;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  &:first-of-type {
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }
`;
