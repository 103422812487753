import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { RouterProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import { defaultTo, isEmpty } from 'ramda';
import { effects } from 'redux/reducers/login';

interface loginParams {
  token: string;
  userRoles: string;
}

interface LoginProps {
  login: (arg0: loginParams) => void;
}

const LoginComp: React.FC<RouterProps & LoginProps> = ({ login, history }) => {
  const [cookies] = useCookies(['azure_roles', 'azure_token']);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = cookies['azure_token'] || params.get('token');
    const userRoles: string = defaultTo('', cookies['azure_roles']);
    if (!isEmpty(token)) {
      login({ token, userRoles });

      const originalPath = sessionStorage.getItem('originalPath') || '/';
      sessionStorage.removeItem('originalPath');
      history.push(originalPath);
    }
  }, []);
  return (
    <Loading text={'Please wait...'}>
      <LoadingBall />
    </Loading>
  );
};

const mapDispatchToProps = {
  login: effects.loginAsync,
};

export default withRouter(connect(null, mapDispatchToProps)(LoginComp));
