import * as React from 'react';
import languages from 'iso-639-1';
import { getCountryName } from 'opt-util/helpers';
import * as Styled from './styles';

const LocaleHeader: React.FC<{ country: string; language?: string }> = (props) => {
  const { language, country } = props;
  const countryName = getCountryName(country);
  const languageName = language ? ` - ${languages.getName(language) || language.toUpperCase()}` : '';

  return (
    <Styled.Header>
      {countryName}
      {country ? languageName : 'New - Country - Language'}
    </Styled.Header>
  );
};

export default LocaleHeader;
