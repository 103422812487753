import styled from '@emotion/styled';
import { ModalFooter, Prompt } from '@ingka/modal';
import Search from '@ingka/search';
import Select from '@ingka/select';
import Text from '@ingka/text';
import { Dropdown } from 'components/Dropdown';
import { FlexContainer } from 'components/FlexContainer';

export const SearchHalfWidth = styled(Search)`
  width: 50%;
`;

export const SortDropdownWrapper = styled(Select)`
  option[value='Choose an option'] {
    display: none;
  }
  width: 100%;
  & select {
    font-size: 0.75rem;
    height: 36px;
    padding: 4px 8px;
  }
`;

export const FilterDropdown = styled(Dropdown)`
  width: 100%;
  & select {
    font-size: 0.75rem;
    height: 36px;
    padding: 4px 8px;
  }
`;

export const ButtonFooter = styled(ModalFooter)`
  display: flex;
  width: 90vw;
  max-width: 1200px;
  padding-top: 16px;

  .btn {
    width: auto;
    flex-grow: unset;

    .btn__inner {
      min-height: 40px;
    }

    &:nth-of-type(2) {
      margin-left: auto;
    }
  }
`;

export const PromptWithWidth = styled(Prompt)`
  .prompt__content-wrapper {
    width: 90vw;
    max-width: 1200px;
  }
`;

export const ValidityFilterWrapper = styled.div`
  display: flex;
  height: 100%;
  gap: 20px;
  margin-bottom: 20px;

  > fieldset,
  > div {
    width: 25%;
  }
`;

export const ClearAllFilters = styled(Text)`
  margin-left: 8px;
  cursor: pointer;
  color: rgb(37, 76, 150);

  &:hover {
    text-decoration: underline;
  }
`;

export const ValidityFilterDropdown = styled(FlexContainer)`
  margin-top: 20px;
  width: 25%;
`;

export const ValidityFilterCheckboxWrapper = styled.div`
  margin-top: 20px;
`;

export const ActiveFiltersRow = styled(FlexContainer)`
  margin-bottom: 20px;
`;
