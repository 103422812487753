import React from 'react';
import { useHistory } from 'react-router-dom';
import { TabPanel } from '@ingka/tabs';
import { DashboardVersion } from 'pages/Dashboard';
import { FlexContainer } from 'components/FlexContainer';
import { DashboardCard, DashboardCardProps } from './DashboardCard';
import { DashboardRedirectCard } from './DashboardRedirectCard';

type DashboardTabPanelProps = {
  possiblePageTypes: string[];
  activeTab: string;
  selectedVersions: DashboardVersion[];
  selectedLanguage: string;
};
const getTabsForSelectedVersionType = (versions: DashboardVersion[], pushHistory: (path: string) => void) => {
  return versions.map(({ id, size, optimizedItems, needsReview, type }) => {
    const percentOptimized = size ? Math.round((optimizedItems / size) * 100) : 0;
    const cards: DashboardCardProps[] = [
      {
        type: 'negative',
        title: 'Non optimized',
        text: `${size - optimizedItems}`,
        onClick: () => pushHistory(`/metadata/view/${id}?optimized=false`),
      },
      {
        type: 'cautious',
        title: 'Needs review',
        text: `${needsReview}`,
        onClick: () => pushHistory(`/metadata/view/${id}?needsReview=true`),
      },
      { type: 'positive', title: 'Total optimized', text: `${optimizedItems}` },
      { type: 'informative', title: 'Optimized', text: `${percentOptimized}%` },
    ];
    return { type, cards };
  });
};

export const DashboardTabPanel: React.FC<DashboardTabPanelProps> = ({ possiblePageTypes, activeTab, selectedLanguage, selectedVersions }) => {
  const history = useHistory();
  const metadataTabs = getTabsForSelectedVersionType(selectedVersions, history.push);
  const redirectTabs = ['redirect', 'shorturl'];

  return (
    <>
      {possiblePageTypes.map((type) =>
        !redirectTabs.includes(type)
          ? type === activeTab && (
              <TabPanel key={type} tabPanelId={type}>
                {metadataTabs
                  .filter((tab) => tab.type === activeTab)
                  .map((metadataTab) => (
                    <FlexContainer direction={'row'} gap={0.4} key={metadataTab.type}>
                      {metadataTab.cards.map((props) => (
                        <DashboardCard key={props.type} {...props} />
                      ))}
                    </FlexContainer>
                  ))}
                {!metadataTabs.some((tab) => tab.type === activeTab) && (
                  <FlexContainer justify='center'>
                    <h2>There is no data for {activeTab}</h2>
                  </FlexContainer>
                )}
              </TabPanel>
            )
          : type === activeTab && (
              <TabPanel key={activeTab} tabPanelId={activeTab}>
                {selectedLanguage && <DashboardRedirectCard language={selectedLanguage} activeTab={activeTab} />}
              </TabPanel>
            )
      )}
    </>
  );
};
