import styled from '@emotion/styled';
import Button from '@ingka/button';
import Pill from '@ingka/pill';
import IngkaTable from '@ingka/table';
import { space50 } from '@ingka/variables';
import { space100, space125, space200, space300 } from '@ingka/variables';
import { colourNeutral2, colourTextAndIcon1 } from '@ingka/variables/colours-css';
import { FlexContainer } from 'components/FlexContainer';

export { FormGroup, FormGroupItem } from './editSheetForm.styles';
export { FormField } from './metaDataForm.styles';
export { EditorContainer } from './metaDataCopy.styles';
export { ButtonWrapper, EditorConfirmTitle, FullScreenSheet, MessageContainer, EditorConfirmText, ModalSideBar } from './editSheet.styles';
export { SideMenu, SideMenuItem, SideMenuItemText } from './editSheetSideMenu.styles';

export const ErrorMessage = styled.div`
  padding: ${space100};
  color: red;
`;

export const Header = styled.h3`
  display: flex;
  align-items: center;
`;

export const PageWrapper = styled.div`
  margin-top: ${space125};
`;

const metaDataListWidths = [20, 35, 25, 15, 5];

export const Table = styled(IngkaTable)`
  border: none;
  overflow: inherit !important;

  th {
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: none !important;

    ${metaDataListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `
    )}
  }
  .text {
    color: ${colourTextAndIcon1} !important;
  }
  td {
    height: ${space300};
    padding: ${space50} !important;
    vertical-align: middle;
    word-break: break-word;
    border-bottom: none !important;

    ${metaDataListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `
    )}

    &:nth-of-type(4) {
      text-align: end;
    }

    .btn__inner {
      height: ${space200};
      max-height: ${space200};
      min-height: ${space200};
    }
  }
  tr {
    :nth-of-type(odd) {
      background-color: ${colourNeutral2};
    }
  }
`;

export const Menu = styled.ul`
  width: 200px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: ${space50} ${space100};
  &:hover {
    background-color: #eee;
  }
  & > a {
    font-weight: bold;
    text-decoration: none;
  }
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;

export const CountrySwapperWrapper = styled(FlexContainer)`
  width: 60%;
  margin: auto;
  max-width: 500px;
`;

export const BookmarkErrorButtons = styled(FlexContainer)`
  margin-top: ${space125};
  button {
    width: 50%;
  }
`;

export const CountrySwapperButton = styled(Button)`
  margin-top: ${space125};
`;
