export function goToOptimeraPageScript(): void {
  const getRedirectPatternMap = (): Record<string, string> => {
    try {
      /* @ts-expect-error replace token on script string with JSON to provide redirect patterns */
      return __OPTIMERA_REDIRECT_PATTERNS__;
    } catch (error) {
      return {};
    }
  };

  try {
    if (window.location.href.endsWith('/bookmarklet')) {
      /* eslint-disable-next-line no-alert */
      alert(`Drag this button to your Bookmarks bar and press it when browsing IKEA site to go back to Publicera.`);
      return;
    }
    const redirectTo = getRedirectPatternMap();

    if (Object.keys(redirectTo).length === 0) {
      throw new Error('Missing Optimera redirect patterns.');
    }

    const getTargetDomain = (): string => {
      const entry = Object.entries(redirectTo).find(([url]) => window.location.hostname.match(new RegExp(url)));

      if (!entry) {
        throw new Error('The Optimera domain could not be found.');
      }
      return entry[1];
    };

    const getTargetEnv = (): string => {
      const match = window.location.pathname.match(/^\/([a-zA-Z0-9]{2}\/[a-zA-Z0-9]{2})\//);
      if (!match) {
        throw new Error('The Optimera environment could not be matched from url.');
      }
      return match[1];
    };

    const extractEntryId = (): string => {
      const parts = window.location.pathname.split('-');
      if (parts.length > 1) {
        const lastPart = parts[parts.length - 1];
        return lastPart.replace(/\/$/, '');
      }
      return '';
    };

    const domain = getTargetDomain();
    const env = getTargetEnv();
    const entryId = extractEntryId();

    const targetUrl = `${domain}/metadata?env=${env}&entryId=${entryId}`;
    /* eslint-disable-next-line no-console */
    console.log(`Navigated to: ${targetUrl}`);
    window.open(targetUrl);
  } catch (error: any) {
    /* eslint-disable-next-line no-alert */
    alert(`An error in script occured: ${error.message}`);
  }
}
