import styled from '@emotion/styled';

export const FormGroup = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;
`;

export const FormGroupItem = styled.div`
  flex-basis: calc(50% - 10px);

  .form-field,
  .form-field > .input-field,
  .form-field > .text-area {
    margin-bottom: 0.25rem;
  }
`;
