import React, { FC } from 'react';
import HelperText from '@ingka/helper-text';
import Text from '@ingka/text';
import { RuleTypeDiff } from 'pages/RulesPage/UploadRulesDiff';
import { StatusIcon } from './statusIcon';

type RuleDiffProps = {
  rule: RuleTypeDiff;
};
const RuleDiffFieldHelper: React.FC<{ errorMessage?: string }> = ({ errorMessage }) => (
  <>
    {errorMessage && (
      <HelperText shouldValidate={!!errorMessage} valid={!errorMessage}>
        {errorMessage}
      </HelperText>
    )}
  </>
);
const RuleDiff: FC<RuleDiffProps> = ({ rule: { name, matchURL, redirectType, targetURL, dateEnd, dateStart, errors, targetStatus } }) => {
  return (
    <tr>
      <td>
        <Text tagName='p'>{matchURL}</Text>
        <RuleDiffFieldHelper errorMessage={errors?.matchURL} />
      </td>
      <td>
        <Text tagName='p'>
          <a href={targetURL} target='_blank' rel='noreferrer'>
            {targetURL}
          </a>
        </Text>
        <RuleDiffFieldHelper errorMessage={errors?.targetURL} />
      </td>
      <td>
        <Text tagName='p'>{name}</Text>
      </td>
      <td>
        <Text tagName='p'>{dateStart ? new Date(dateStart).toLocaleDateString() : 'None'}</Text>
        <RuleDiffFieldHelper errorMessage={errors?.dateStart} />
      </td>
      <td>
        <Text tagName='p'>{dateEnd ? new Date(dateEnd).toLocaleDateString() : 'None'}</Text>
        <RuleDiffFieldHelper errorMessage={errors?.dateEnd} />
      </td>
      <td>
        <Text tagName='p'>{redirectType}</Text>
        <RuleDiffFieldHelper errorMessage={errors?.redirectType} />
      </td>
      <td>
        <Text>
          <StatusIcon status={targetStatus} startDate={dateStart} />
        </Text>
      </td>
    </tr>
  );
};

export { RuleDiff };
