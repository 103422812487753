import React, { FC, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal from '@ingka/modal';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { Divider } from 'components/Divider';
import { KeywordsTable } from 'components/KeywordsTable';
import { editSheetSideMenuItems } from 'components/seo/metadataView/EditorFieldConfig';
import { ReviewWarningMessage } from 'components/seo/metadataView/InfoBox';
import { MetaDataModalHeader } from 'components/seo/metadataView/MetaDataModalHeader';
import { getConfig } from 'components/seo/metadataView/View';
import * as StyledGlobal from 'components/styles';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { useLoader } from 'hooks/useLoader';
import { OmitTN } from 'opt-util/types';
import { toMetaData } from 'redux/util/toGenericMetadata';
import { MetaDataCompleteFragment, MetaDataEntryInput } from '__generated__/types';
import { EditSheetContentSideBar } from './EditSheetContentSideBar';
import { EditSheetForm } from './EditSheetForm';
import { EditSheetSideMenu } from './EditSheetSideMenu';
import { MetaDataCopy } from './MetaDataCopy';
import { MetaDataForm } from './MetaDataForm';
import * as StyledLocal from 'components/common/styles';
import * as MetaDataStyles from './styles';

const Styled = { ...StyledGlobal, ...StyledLocal, ...MetaDataStyles };

interface ItemEditProps {
  editItem: (md: MetaDataEntryInput) => void;
}

export const EditSheet: FC<ItemEditProps> = ({ editItem }) => {
  const {
    state: { hasErrors, formData, versionDetails },
  } = useContext(EditorContext);
  const {
    setIsEditorOpen,
    state: { isEditorOpen: isOpen },
  } = useContext(MetadataContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const fieldsToBeReviewed = ['pageTitle', 'ogTitle', 'description', 'ogDescription', 'urlSlug'];
  const fields = getConfig(versionDetails?.type).map((c) => ({
    props: {
      key: c.key,
      id: c.key,
      disabled: c.disabled || (formData?.descriptionIsSynced && ['ogTitle', 'ogDescription'].includes(c.key)),
      label: c.label,
      maxLength: c.maxLength,
      showLength: c.showLength,
      needsReview: !!(formData?.needsReview && fieldsToBeReviewed.includes(c.key)),
      tooltipMessage: c.tooltipMessage,
      translationButton: c.translationButton,
      fieldHelper: c.fieldHelper,
      fullWidth: c.fullWidth,
      ariaLabelledby: c.ariaLabelledby,
    },
    showInEditor: c.showInEditor,
    component: c.component,
  }));

  const filteredFields = fields.filter((field) => field.showInEditor);
  const [isEditorOpen, setShowEditor] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState('metaData');
  const [, setLoading] = useLoader();

  const submit = (formData: OmitTN<MetaDataCompleteFragment>) => {
    setLoading(true);
    editItem(toMetaData(formData));
    setShowEditor(true);
    setIsEditorOpen(false);
  };

  const suggestionsAvailable =
    !!formData?.suggestedPrimaryKeyword || !!formData?.suggestedSecondaryKeyword || !!formData?.suggestedAlternativeKeyword;

  const closeModal = () => {
    const isMetadataEditPage = pathname.includes('/metadata/edit/');

    if (isMetadataEditPage) {
      history.push(`/metadata`);
    } else {
      setShowEditor(true);
      setIsEditorOpen(false);
    }
  };

  const menuItems = editSheetSideMenuItems.filter(
    (item) =>
      (item.renderInVersionTypes && item.renderInVersionTypes.includes(versionDetails?.type || '') && item.showInSideBar) ||
      (!item.renderInVersionTypes && item.showInSideBar)
  );

  const editorOpenButtonWrapper = (
    <Styled.ButtonWrapper>
      <Button text={'Cancel'} onClick={closeModal} type={'secondary'} size={'small'} inverseTheme />
      <Styled.YellowButton text={'Continue'} onClick={() => setShowEditor(false)} type={'primary'} disabled={hasErrors} size={'small'} />
    </Styled.ButtonWrapper>
  );

  const editorClosedButtonWrapper = (
    <Styled.ButtonWrapper>
      <Button text={'Back'} onClick={() => setShowEditor(true)} type={'secondary'} size={'small'} inverseTheme />
      <Styled.YellowButton text={'Save'} onClick={() => formData && submit(formData)} type={'primary'} size={'small'} />
    </Styled.ButtonWrapper>
  );

  const modalHeader = (
    <MetaDataModalHeader type={versionDetails?.type || ''}>{isEditorOpen ? editorOpenButtonWrapper : editorClosedButtonWrapper}</MetaDataModalHeader>
  );

  return (
    <Modal visible={isOpen} handleCloseBtn={closeModal} escapable={false} keepContentMounted={false}>
      <Styled.FullScreenSheet header={modalHeader} footer={null}>
        <Styled.ModalSideBar>
          <EditSheetSideMenu menuItems={menuItems} activeItem={selectedMenuItem} onItemSelect={(id) => setSelectedMenuItem(id)} />
        </Styled.ModalSideBar>
        <Divider vertical />
        <Styled.FlexedModalBody key={'body'} width="48.75rem">
          {isEditorOpen && (
            <>
              {formData?.needsReview && (
                <Styled.MessageContainer>
                  <InlineMessage
                    variant="cautionary"
                    ssrIcon={warningTriangle}
                    title={'Needs review'}
                    body={<ReviewWarningMessage clickable={false} entry={formData} />}
                  />
                </Styled.MessageContainer>
              )}
              {suggestionsAvailable && <KeywordsTable entry={formData} />}
              {versionDetails?.type === 'filter' && selectedMenuItem === 'metaData' && <MetaDataForm fields={filteredFields} />}
              {versionDetails?.type === 'filter' && selectedMenuItem === 'copy' && <MetaDataCopy fields={fields} />}
              {versionDetails?.type !== 'filter' && <EditSheetForm fields={filteredFields} />}
            </>
          )}
          {!isEditorOpen && (
            <>
              <Styled.EditorConfirmTitle>Changes are about to be saved</Styled.EditorConfirmTitle>
              <Styled.EditorConfirmText>
                By clicking the <b>Save</b> button, you confirm that all the inputs have been reviewed and edited correctly.
              </Styled.EditorConfirmText>
              <Styled.EditorConfirmText>These changes will be applied and will update the information of the entry.</Styled.EditorConfirmText>
            </>
          )}
        </Styled.FlexedModalBody>
        <Divider vertical />
        <Styled.ModalSideBar>
          <EditSheetContentSideBar />
        </Styled.ModalSideBar>
      </Styled.FullScreenSheet>
    </Modal>
  );
};
