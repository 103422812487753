import React from 'react';
import styled from '@emotion/styled';
import Breadcrumb from '@ingka/breadcrumb';
import Hyperlink from '@ingka/hyperlink';
import languages from 'iso-639-1';
import { getCountryName } from 'opt-util/helpers';

const StyledContainer = styled.div`
  margin-bottom: 20px;
`;

interface BreadcrumbLink {
  url: string;
  text: string;
}

const pageLinks: { [key: string]: BreadcrumbLink } = {
  metaData: { url: '/metadata', text: 'Metadata {countryName}' },
  list: { url: '/metadata/list/{country}/{language}', text: '{languageName}' },
  view: { url: '/metadata/view/{country}/{language}/{type}/{filename}', text: '{type}' },
  upload: { url: '/metadata/upload', text: 'Upload' },
  uploadCountry: { url: '/metadata/upload/{country}/{language}/{type}', text: 'Upload {type}' },
  diff: { url: '/metadata/diff/{id}', text: 'Diff' },
};

const breadcrumbTrail: { [key: string]: BreadcrumbLink[] } = {
  list: [pageLinks.metaData, pageLinks.list],
  view: [pageLinks.metaData, pageLinks.list, pageLinks.view],
  upload: [pageLinks.upload],
  uploadCountry: [pageLinks.metaData, pageLinks.list, pageLinks.uploadCountry],
  diff: [pageLinks.metaData, pageLinks.list, pageLinks.diff],
};

interface MatchProps {
  country?: string;
  language?: string;
  type?: string;
  filename?: string;
}

type BreadcrumbProps = {
  page: string;
  country?: string;
  language?: string;
  type?: string;
  filename?: string;
};

const replaceStrPlaceHolders = (str: string, { country, language, type, filename }: MatchProps) => {
  if (country && language) {
    str = str.replace('{countryLabel}', `${getCountryName(country)} - ${languages.getName(language) || language.toUpperCase()}`);
  }
  if (country) {
    str = str.replace('{country}', country);
    str = str.replace('{countryName}', getCountryName(country));
  }
  if (language) {
    str = str.replace('{language}', language);
    str = str.replace('{languageName}', languages.getName(language) || language.toUpperCase());
  }
  if (type) {
    str = str.replace('{type}', type);
  }
  if (filename) {
    str = str.replace('{filename}', filename);
  }
  return str;
};

const BreadcrumbT: React.FC<BreadcrumbProps> = (props) =>
  breadcrumbTrail[props.page] ? (
    <StyledContainer>
      <Breadcrumb>
        {breadcrumbTrail[props.page].map((loc: BreadcrumbLink, index) => (
          <Hyperlink key={`bc-${index}`} url={replaceStrPlaceHolders(loc.url, { ...props })} text={replaceStrPlaceHolders(loc.text, { ...props })} />
        ))}
      </Breadcrumb>
    </StyledContainer>
  ) : null;

export default BreadcrumbT;
