import styled from '@emotion/styled';
import InlineMessage from '@ingka/inline-message';
import { space125 } from '@ingka/variables';
import { FlexContainer } from 'components/FlexContainer';

export const PageContainer = styled.div`
  display: flex;
`;

export const PageContent = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  padding: ${space125};
`;

export const PageHeader = styled.h2`
  margin-bottom: ${space125};
`;

export const RulesStatusInlineMessage = styled(InlineMessage)`
  margin-bottom: 24px;
`;

export const RulesFilteringWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  div:last-child {
    margin-left: auto;
    align-items: center;
  }
`;

export const NoNewOrModifiedRules = styled.div`
  display: flex;
  min-height: 400px;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgrey;
`;

export const FullPageLoader = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 10px 0;
  border-bottom: solid lightgrey 1px;
  margin-bottom: 20px;

  .search {
    width: 50%;
  }
`;

export const BookmarkletWrapper = styled(FlexContainer)`
  width: 60%;
  margin: auto;
  margin-top: 50px;
  max-width: 400px;
`;

export const BookmarkletButtonWrapper = styled(FlexContainer)`
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const BookmarkletButton = styled.a`
  width: 100%;
`;

export const BookmarkletArrow = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-80%, -55%);

  path {
    fill: #8e8c8c;
  }
`;
