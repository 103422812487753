import React, { useEffect } from 'react';
import Pill from '@ingka/pill';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { FilteredPageURL } from 'components/admin/FilteredPages';
import { Row } from 'components/common/Row';
import { validateInputValue } from 'opt-util/validation';
import { useFilterQuery } from '__generated__/types';
import { FilteredPagesTableRow } from './FilteredPagesTableRow';
import * as Styled from './styled';

interface FilteredPagesInputCardProps {
  entry: FilteredPageURL;
  onChangedEntry: (entry: FilteredPageURL) => void;
  onClickDelete: () => void;
  isDeleteButtonDisabled: boolean;
  disabled: boolean;
  index: number;
}

export const FilteredPagesInputCard: React.FC<FilteredPagesInputCardProps> = ({
  entry,
  onChangedEntry,
  onClickDelete,
  isDeleteButtonDisabled,
  disabled,
  index,
}) => {
  const { data, error } = useFilterQuery({
    skip: !entry.inputUrl,
    fetchPolicy: 'no-cache',
    variables: {
      url: entry.inputUrl,
      slug: entry.urlSlug,
    },
    errorPolicy: 'none',
  });
  useEffect(() => {
    onChangedEntry({
      ...entry,
      preview: data?.getFilteredUrl.preview ?? '',
      globalId: data?.getFilteredUrl.globalId ?? '',
    });
  }, [data]);

  useEffect(() => {
    if (
      entry.urlSlug ||
      entry.title ||
      entry.h1 ||
      !data?.getFilteredUrl.suggestions.slug ||
      !data?.getFilteredUrl.suggestions.h1 ||
      !data?.getFilteredUrl.suggestions.title
    )
      return;
    onChangedEntry({
      ...entry,
      h1: data.getFilteredUrl.suggestions.h1,
      title: data.getFilteredUrl.suggestions.title,
      urlSlug: data.getFilteredUrl.suggestions.slug,
    });
  }, [data]);

  const inputFieldValidation = (field: string) => {
    let errorMessage;
    entry.isValid = true;
    if (field === 'urlSlug') {
      errorMessage = validateInputValue('urlSlug', entry.urlSlug);
      errorMessage && (entry.isValid = !entry.isValid);
      return errorMessage || undefined;
    }
  };
  return (
    <Row>
      <Styled.TableContent>
        <table>
          <tbody>
            <FilteredPagesTableRow
              fieldTitle="Input URL:"
              fieldId={`fp-input-url-${index}`}
              fieldType="text"
              value={entry.inputUrl}
              onChange={(e) => onChangedEntry({ ...entry, inputUrl: e.currentTarget.value })}
              disabled={disabled}
            />
            <FilteredPagesTableRow
              fieldTitle="URL slug:"
              fieldId={`fp-url-slug-${index}`}
              fieldType="text"
              value={entry.urlSlug}
              shouldValidate
              errorMessage={inputFieldValidation('urlSlug')}
              onChange={(e) => onChangedEntry({ ...entry, urlSlug: e.currentTarget.value })}
              disabled={disabled}
            />
            <FilteredPagesTableRow
              fieldTitle="H1:"
              fieldId={`fp-h1-${index}`}
              fieldType="text"
              value={entry.h1}
              onChange={(e) => onChangedEntry({ ...entry, h1: e.currentTarget.value })}
              disabled={disabled}
            />
            <FilteredPagesTableRow
              fieldTitle="Title:"
              fieldId={`fp-title-${index}`}
              fieldType="text"
              value={entry.title}
              onChange={(e) => onChangedEntry({ ...entry, title: e.currentTarget.value })}
              disabled={disabled}
            />
            <FilteredPagesTableRow
              fieldTitle="Preview:"
              fieldId={`fp-${error?.message ? 'error' : 'preview'}-${index}`}
              fieldType="text"
              value={error?.message ? `Error: ${error?.message}` : entry.preview}
              readOnly
              disabled={disabled}
              hasError={!!error?.message}
            />
            <FilteredPagesTableRow
              fieldTitle="Global ID:"
              fieldId={`fp-global-id-${index}`}
              fieldType="text"
              value={entry.globalId}
              readOnly
              disabled={disabled}
            />
          </tbody>
        </table>
        <div>
          <Pill iconOnly size={'small'} ssrIcon={trashCan} disabled={isDeleteButtonDisabled} onClick={onClickDelete} />
        </div>
      </Styled.TableContent>
    </Row>
  );
};
