import { InlineMessageProps } from '@ingka/inline-message';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';

type InlineMessageKeys = 'couldNotCreateRule' | 'couldNotEditRule';
export const inlineMessages: Record<InlineMessageKeys, InlineMessageProps> = {
  couldNotCreateRule: {
    ssrIcon: warningTriangleIcon,
    variant: 'cautionary',
    title: 'Something went wrong!',
    body: 'An error occurred. Could not create rule.',
  },
  couldNotEditRule: {
    ssrIcon: warningTriangleIcon,
    variant: 'cautionary',
    title: 'Something went wrong!',
    body: 'An error occurred. Could not edit rule.',
  },
};

export const getInlineMessage = (key: keyof typeof inlineMessages): InlineMessageProps => {
  return inlineMessages[key];
};
