import styled from '@emotion/styled';
import Text from '@ingka/text';
import { radiusS, space50 } from '@ingka/variables';
import { colourNeutral2 } from '@ingka/variables/colours-css';
import { FlexContainer } from 'components/FlexContainer';

type MenuItemProps = {
  active?: 'true' | undefined;
};
export const SideMenu = styled(FlexContainer)`
  width: 100%;
`;

export const SideMenuItem = styled('div')<MenuItemProps>`
  background-color: ${({ active }: MenuItemProps) => active && colourNeutral2};
  padding: ${space50};
  border-radius: ${radiusS};
  cursor: pointer;
  &:hover {
    background-color: ${colourNeutral2};
  }
`;

export const SideMenuItemText = styled(Text)<MenuItemProps>`
  font-weight: ${({ active }: MenuItemProps) => (active ? '700' : '400')};
`;
