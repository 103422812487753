import React, { useContext } from 'react';
import ascIcon from '@ingka/ssr-icon/paths/sort-ascending';
import descIcon from '@ingka/ssr-icon/paths/sort-descending';
import Text from '@ingka/text';
import { Context as RulesContext, SortDirection } from 'hooks/contexts/RulesContext';
import { Column as RuleTableColumn } from 'types';
import { ColumnHeader } from './index';
import { RulesTableHeaderTargetStatusTooltip, RulesTableHeaderUsageTooltip } from './RulesTableHeaderTooltip';
import { TableColumnIcon } from './RuleTableColumnIcon';
import * as Styled from './styled';

type RuleTableColumnHeaderProps = {
  columnHeader: ColumnHeader;
};
export const RuleTableColumnHeader: React.FC<RuleTableColumnHeaderProps> = ({ columnHeader }) => {
  const {
    setSortOnField,
    state: { sort },
  } = useContext(RulesContext);
  const isColumnHeaderContent = (columnHeader: ColumnHeader): columnHeader is RuleTableColumn => {
    return columnHeader !== null && ('label' in columnHeader || 'field' in columnHeader);
  };

  if (isColumnHeaderContent(columnHeader)) {
    const isSortedColumn = sort.field === columnHeader?.field;
    const sortIcon = isSortedColumn ? (sort.direction === SortDirection.ASC ? ascIcon : descIcon) : ascIcon;
    return (
      <Styled.TableHeading onClick={() => columnHeader?.field && setSortOnField(columnHeader?.field)}>
        <Styled.TableColumnHeading>
          <Text bodySize='s'>{columnHeader.label}</Text>
          {columnHeader.label === 'Target status' && <RulesTableHeaderTargetStatusTooltip />}
          {columnHeader.label === 'Usage' && <RulesTableHeaderUsageTooltip />}
          {columnHeader?.field && <TableColumnIcon isSortedColumn={isSortedColumn} paths={sortIcon} columnField={columnHeader.field} />}
        </Styled.TableColumnHeading>
      </Styled.TableHeading>
    );
  }

  return (
    <Styled.TableHeading>
      <Styled.TableColumnHeading>{columnHeader}</Styled.TableColumnHeading>
    </Styled.TableHeading>
  );
};
