import styled from '@emotion/styled';
import InputField from '@ingka/input-field';

export const SmallerInputField = styled(InputField)`
  height: 2.5rem;
  input {
    height: 2rem;
    font-size: 0.875rem;
  }
`;

export const TableContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 5rem 40rem;
  grid-auto-flow: column;
`;

export const TableData = styled.td`
  text-align: right;
  line-height: 2rem;
  padding-bottom: 0 !important;
  padding-top: 0.5rem !important;
  .form-field {
    margin-bottom: 0;
    margin-left: 1rem;
  }
  .input-field {
    margin-bottom: 0;
  }
  p {
    text-align: left;
    margin-left: 1rem;
  }
`;

export const InputContainer = styled.div`
  width: 80%;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  gap: 1rem;
`;

export const Message = styled.p<{ hasError?: boolean }>`
  color: ${(props) => (props.hasError ? 'red' : 'inherit')};
`;
