import * as React from 'react';
import styled from '@emotion/styled';
import { PaginatedTable } from 'components/common/PaginatedTable';
import { Column } from 'components/common/Table';
import { paginateData } from 'opt-util/paginate';
import { Message } from 'types';
export const ErrorMessageListItem = styled.li`
  color: red;
  font-size: 0.8rem;
`;
const columns: Column[] = [
  { key: 'id', label: 'ID' },
  {
    key: 'messages',
    label: 'Messages',
    render: (val: string[]) => (
      <ul>
        {val.map((error, index) => (
          <ErrorMessageListItem key={index}>{error}</ErrorMessageListItem>
        ))}
      </ul>
    ),
  },
];

interface UploadErrorsProps {
  messages: Message[];
}

const UploadErrors: React.FC<UploadErrorsProps> = ({ messages }) => {
  const errorMessages = paginateData(messages);
  return errorMessages.totalItems === 0 ? null : (
    <>
      <hr />
      <h4>Errors (The below records will not be uploaded)</h4>
      <PaginatedTable columns={columns} data={errorMessages} testid={'upload-errors'} />
    </>
  );
};

export default UploadErrors;
