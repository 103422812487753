import React, { useContext } from 'react';
import Text from '@ingka/text';
import { EditorCheckboxInput } from 'components/common/EditorCheckboxInput';
import { FlexContainer } from 'components/FlexContainer';
import { StatusIcon } from 'components/StatusIcon';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';

export const EditSheetContentSideBar = (): JSX.Element => {
  const {
    state: { formData, versionDetails },
  } = useContext(EditorContext);
  const enableOgTitleDescriptionSyncCheckBox = versionDetails?.type === 'filter' || versionDetails?.type === 'PLP';
  const enableFilterPagePublishCheckBox = versionDetails?.type === 'filter';

  return (
    <>
      <StatusIcon statusType={versionDetails?.type === 'filter' ? 'isPublished' : 'isOptimized'} status={!!formData?.isOptimized} />
      <FlexContainer direction='column' gap={0.1}>
        <Text headingSize='xs'>Global ID</Text>
        <Text>{formData?.id}</Text>
      </FlexContainer>

      <FlexContainer direction='column' gap={0.1}>
        <Text headingSize='xs'>Filter Type</Text>
        <Text>{formData?.filterType}</Text>
      </FlexContainer>

      <FlexContainer direction='column' gap={0.1}>
        <Text headingSize='xs'>Filter Name</Text>
        <Text>{formData?.filterName}</Text>
      </FlexContainer>

      {formData?.ts && formData.diffVersionId && (
        <FlexContainer direction='column' gap={0.1}>
          <Text headingSize='xs'>Updated At</Text>
          <FlexContainer gap={0.3}>
            <Text>{new Date(formData.ts).toLocaleDateString()},</Text>
            <Text>
              {new Date(formData.ts).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}
            </Text>
          </FlexContainer>
        </FlexContainer>
      )}

      <FlexContainer direction='column' gap={0.1}>
        <Text headingSize='xs'>Updated By</Text>
        <Text>{formData?.author}</Text>
      </FlexContainer>
      <FlexContainer direction='column' mt={1} gap={0.1}>
        {enableOgTitleDescriptionSyncCheckBox && <EditorCheckboxInput id='isOptimized' label='Published' />}
        {enableFilterPagePublishCheckBox && <EditorCheckboxInput id='descriptionIsSynced' label='Sync OG Title and Description' />}
      </FlexContainer>
    </>
  );
};
