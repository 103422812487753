import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '@ingka/button';
import { request } from 'gaxios';
import { useSitemapsMarkets } from 'components/sitemaps/hooks';
import { useInterval } from 'hooks/useInterval';
import { withToken } from 'opt-util/withToken';
import { roles, username } from 'redux/selectors/login';
import { isAdmin } from 'redux/selectors/selectors';
import { RootState } from 'redux/store';
import { JobStatusMessage, Market, MarketProgress, SitemapGeneratorProps } from 'types/sitemaps';
import * as Styled from './styled';
import { FlexContainer } from '../../FlexContainer';

export const marketKey = (m: Market): string => `${m.language}-${m.country}`;

export const SitemapGenerator: React.FC<SitemapGeneratorProps> = ({ isAdmin }) => {
  const [isRunning, setIsRunning] = useState(true);
  const markets = useSitemapsMarkets();
  const dispatch = useDispatch();
  const userRoles = useSelector(roles);
  const email = useSelector(username);
  const [status, setStatus] = useState<Record<string, MarketProgress>>({});
  const handleGenerateSitemapsClick = async () => {
    setIsRunning(true);
    await request({
      method: 'POST',
      url: '/api/sitemaps/mappings/generate',
      headers: { ...(await withToken(dispatch)), roles: JSON.stringify(userRoles), email },
    });
  };

  useInterval(async () => {
    const response = await request({ url: '/api/sitemaps/mappings/status', headers: await withToken(dispatch) });
    const { isRunning, marketInProgress, marketsWithFetchedState, marketsWithGeneratedState } = response.data as JobStatusMessage;

    setIsRunning(isRunning);
    if (!isRunning) {
      setStatus({});
      return;
    }

    const jobStatus: Record<string, MarketProgress> = {};

    marketsWithFetchedState.forEach((item) => {
      jobStatus[marketKey(item)] = { market: item, fetched: true };
    });
    marketsWithGeneratedState.forEach((item) => {
      jobStatus[marketKey(item)] = { market: item, generated: true };
    });
    if (marketInProgress) {
      jobStatus[marketKey(marketInProgress)] = {
        market: marketInProgress,
        generating: true,
        fetching: true,
      };
    }
    setStatus({ ...status, ...jobStatus });
  }, 10 * 1000);

  return (
    <>
      <FlexContainer flexWrap="wrap">
        {markets.map((item: MarketProgress) => {
          const marketStatus = status[marketKey(item.market)] || {};
          return (
            <Styled.MarketWithBorders
              country={item.market.country}
              language={item.market.language}
              key={item.market.country + item.market.language}
              fetched={marketStatus.fetched}
              fetching={marketStatus.fetching}
              generating={marketStatus.generating}
              generated={marketStatus.generated}
            />
          );
        })}
      </FlexContainer>
      {isAdmin && (
        <Styled.ActionBar>
          <Button type={'primary'} text={'Generate sitemaps'} onClick={() => handleGenerateSitemapsClick()} disabled={isRunning} size={'small'} />
        </Styled.ActionBar>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAdmin: isAdmin(state),
});

export default connect(mapStateToProps)(SitemapGenerator);
