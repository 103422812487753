import styled from '@emotion/styled';
import Loading from '@ingka/loading';
import {
  colourNeutral3,
  colourNeutral7,
  colourSemanticCaution,
  colourSemanticInformative,
  colourSemanticNegative,
  colourSemanticPositive,
} from '@ingka/variables/colours-css';

export const DashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px 10px 10px;
  flex-basis: 25%;
  height: 215px;
  border: 1px solid ${colourNeutral3};
  box-sizing: border-box;
  color: ${colourNeutral7};
  text-align: center;
  border-radius: 4px;

  p {
    font-size: 17px;
  }
`;

export const DashboardBoxHeader = styled.h1<{ type: 'negative' | 'cautious' | 'positive' | 'informative' }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ type }) => {
    return {
      negative: colourSemanticNegative,
      cautious: colourSemanticCaution,
      positive: colourSemanticPositive,
      informative: colourSemanticInformative,
    }[type];
  }};
  height: 48px;
  font-size: 36px;
`;
export const DashboardText = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const LoadingWrapper = styled(Loading)`
  height: 48px;
`;
