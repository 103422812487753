import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import Search from '@ingka/search';
import { useDebounce } from 'hooks/useDebounce';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onLetterSearch: (_value: string) => void;
  debouncedValue?: number;
}

export const SearchField: FC<SearchProps> = ({ id, placeholder, onLetterSearch, debouncedValue = 300, type }) => {
  const [inputFieldText, setInputFieldText] = useState<string | number>('');
  const debounce = useDebounce(inputFieldText, debouncedValue);
  useEffect(() => {
    onLetterSearch(debounce as string);
  }, [debounce]);
  return (
    <Search
      id={id || 'search_input_field'}
      data-testid={id}
      type={type}
      placeholder={placeholder}
      onChange={(e) => {
        setInputFieldText(e.target.value);
      }}
      value={inputFieldText}
      onClear={() => setInputFieldText('')}
    />
  );
};
