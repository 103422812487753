import { Cookies } from 'react-cookie';
import { Action, createAction, createReducer, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { request } from 'gaxios';
import jwt from 'jsonwebtoken';
import { defaultTo, propOr } from 'ramda';
import { ThunkAction } from 'redux-thunk';
import { token } from 'redux/selectors/login';
import { RootState } from 'redux/store';

const username = (token: string): string => propOr<string>('user', 'unique_name')(jwt.decode(token));
const roles = (userRoles: string): string[] => defaultTo([], userRoles.split(','));

export interface TokRoles {
  token: string;
  userRoles: string;
}

export const actions = {
  login: createAction<TokRoles>('login/login'),
  logout: createAction('login/logout'),
  updateToken: createAction<string>('login/updateToken'),
};

export const effects = {
  logoutAsync: (): ThunkAction<void, RootState, null, Action> => (dispatch) => {
    const cookies = new Cookies();
    cookies.remove('azure_token');
    dispatch(actions.logout());
  },

  loginAsync:
    ({ token, userRoles }: TokRoles): ThunkAction<void, LoginState, null, Action> =>
    (dispatch) => {
      const cookies = new Cookies();
      cookies.remove('azure_token');
      dispatch(actions.login({ token, userRoles }));
    },

  refreshToken:
    (): ThunkAction<Promise<string>, RootState, null, Action> =>
    (dispatch, getState): Promise<string> => {
      const tokenString = token(getState());
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const decToken = jwt.decode(tokenString) as Dictionary<any>;
      if (decToken && decToken.exp < Date.now() / 1000) {
        return request<string>({ url: '/api/auth/refreshtoken', headers: { 'content-type': 'text/plain', Authentication: `Bearer ${tokenString}` } })
          .then((token) => {
            dispatch(actions.updateToken(token.data));
            return token.data;
          })
          .catch((e) => {
            console.error(`refreshToken: Error: ${e}`);
            throw e;
          });
      }
      return Promise.resolve(tokenString);
    },
};

export interface LoginState {
  loggedIn: boolean;
  token?: string;
  roles: string[];
  username?: string;
}

const loginReducer = createReducer<LoginState>(
  { loggedIn: false, roles: [] },
  {
    [actions.login.type]: (state, action: PayloadAction<TokRoles>) => ({
      loggedIn: true,
      token: action.payload.token,
      username: username(action.payload.token),
      roles: roles(action.payload.userRoles),
    }),
    [actions.logout.type]: () => ({
      loggedIn: false,
      roles: [],
    }),
    [actions.updateToken.type]: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
  }
);

export default loginReducer;
