import Flag from 'react-country-flag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import InputField from '@ingka/input-field';
import List from '@ingka/list';
import Modal, { ModalFooter } from '@ingka/modal';
import TextArea from '@ingka/text-area';
import { space75 } from '@ingka/variables';
import {
  colourNeutral5,
  colourInteractiveDisabled1,
  colourNeutral2,
  colourSemanticNegative,
  colourSemanticPositive,
} from '@ingka/variables/colours-css';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { FlexContainer } from 'components/FlexContainer';

const colorBrandBlue = '#0058a3';

export const NeedsReviewWarning = styled.p`
  color: #f26a2f;
  position: relative;
`;

export const Label = styled.span<{ flexSize?: number }>`
  font-weight: bold;
  flex: 0 0 ${(props) => props.flexSize || 150}px;
`;

export const BlueLabel = styled(Label)`
  color: ${colorBrandBlue};
  cursor: pointer;
`;

export const Value = styled.span`
  overflow-wrap: break-word;
  word-break: break-all;
`;

export const ErrorMessage = styled.div`
  padding: 1em;
  color: red;
`;

export const OverflowLabelTextArea = styled(TextArea)<{ readOnly?: boolean }>`
  label {
    overflow: visible;
    display: flex;
  }
  .text-area__wrapper {
    textarea {
      background-color: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'inherit')};
    }
    .text-area__border {
      border: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'auto')};
    }
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const DatePickerWrapper = styled.div<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? colourInteractiveDisabled1 : 'inherit')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
`;
export const DatePickerInput = styled.input<{ validation: 'error' | 'success' | 'default' }>`
  border-width: 1px;
  border-color: ${({ validation }) =>
    (validation === 'success' && colourSemanticPositive) || (validation === 'error' && colourSemanticNegative) || colourNeutral5};
  border-radius: 4px;
  height: 3rem;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  padding: 0 ${space75};
  &:disabled {
    border-color: ${colourInteractiveDisabled1};
    opacity: 0.4;
  }
`;
export const TooltipMessage = styled.span`
  position: absolute;
  top: 150%;
  left: -250px;
  max-width: 530px;
  background-color: #111;
  color: white;
  padding: 8px 12px;
  font-size: 0.75rem;
  border-radius: 4px;

  ul {
    list-style: none;

    li {
      margin-bottom: 8px;
    }
  }
`;

export const ToolTipButton = styled(Button)`
  align-items: center;
  background: rgb(245, 245, 245);
  border: 0;
  border-radius: 64px;
  color: rgb(17, 17, 17);
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  line-height: 1.4285714286;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  transition-duration: 0.25s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
  vertical-align: top;
  width: 2rem;

  &:hover {
    background: rgb(223, 223, 223);

    span.btn__inner {
      background: none;
    }
  }
`;

export const TranslationModalInputContainer = styled(FlexContainer)`
  height: 85%;

  textarea {
    min-width: 30vw;
    height: 100%;
    resize: none;
  }

  .label-wrapper--text-input {
    height: 100%;
  }

  .text-area__wrapper {
    height: 100%;
  }
`;

export const TranslationModalContainer = styled(Modal)`
  .prompt__content-wrapper {
    max-width: 95vw;
    height: 80vh;
  }
`;

export const PreviewContainer = styled.div`
  white-space: normal;
  max-height: 48vh;
`;

export const TranslationModalFooter = styled(ModalFooter)`
  justify-content: center !important;
`;

export const TranslationButtonHyperlink = styled(Hyperlink)`
  margin-left: 0.4rem;
`;

export const Divider = styled.hr`
  border-block-start: 1px solid #dfdfdf;
`;

export const Header = styled.h3`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`;

export const SpacedList = styled(List)`
  margin-bottom: 0.75rem;
`;

export const NoDataFlex = styled(FlexContainer)`
  width: 100%;

  span {
    color: #e5ab2b;
    font-size: 2rem;
  }
`;

const flagStyling = css`
  width: 30px !important;
  height: 20px !important;
  margin-right: 10px;
`;

export const CountryFlag = styled(Flag)`
  ${flagStyling}
`;

export const FallbackCountryFlag = styled.img`
  ${flagStyling}
`;

export const InputTextField = styled(InputField)<{ readOnly?: boolean }>`
  .input-field__wrapper {
    background-color: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'inherit')};
    .input-field__border {
      border: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'auto')};
    }
  }
`;

export const CheckBox = styled(CheckboxInput)`
  margin: 0;
`;
