import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Checkbox from '@ingka/checkbox';
import Loading, { LoadingBall } from '@ingka/loading';
import { TableBody, TableHeader } from '@ingka/table';
import { request } from 'gaxios';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { useComponentOffsetTop } from 'hooks/useComponentOffsetTop';
import { useCountry } from 'hooks/useCountry';
import { useMarkets } from 'hooks/useMarkets';
import { token as tokenSelector } from 'redux/selectors/login';
import { Column as RuleTableColumn } from 'types';
import { AddEditRuleModal } from './AddEditRuleModal';
import { Rule } from './Rule';
import { RuleTableColumnHeader } from './RuleTableColumnHeader';
import * as Styled from './styled';

type RuleTypeErrors = { [key in keyof Partial<Omit<RuleType, 'errors'>>]: string };

export type RuleType = {
  name: string;
  matchURL: string;
  targetURL: string;
  dateStart: string;
  targetStatus: number;
  userModifiedBy?: { fullName: string; email: string };
  redirectType: number;
  dateEnd: string | null;
  dateModified: string;
  docRefId: string;
  invocations: number;
  errors?: RuleTypeErrors;
  isModified?: boolean;
};

export type GlobalRule = RuleType & { priority: number };

export type ColumnHeader = RuleTableColumn | React.ReactElement | null;

type RulesProps = {
  rules: RuleType[] | GlobalRule[];
  isLoading: boolean;
  onRuleToggle: (docRefId: string) => void;
  clearSelectedRules: () => void;
  toggleSelectAll: () => void;
  selectedRules: string[];
  columns: RuleTableColumn[];
  policy: string;
};

const isGlobalRules = (rules: RuleType[] | GlobalRule[], policy: string): rules is GlobalRule[] => policy === 'r1-global';

export const Rules: React.FC<RulesProps> = ({
  rules,
  selectedRules,
  isLoading,
  columns,
  policy,
  onRuleToggle,
  toggleSelectAll,
  clearSelectedRules,
}) => {
  const isSelectAllChecked = rules.length > 0 && rules.length === selectedRules.length;
  const isR1Global = policy === 'r1-global';
  const [globalRules, setGlobalRules] = useState<GlobalRule[]>([]);
  const [previousGlobalRules, setPreviousGlobalRules] = useState<GlobalRule[]>([]);
  const [movedRule, setMovedRule] = useState<GlobalRule>();
  const { setStatusMessage } = useContext(StatusMessageContext);
  const { componentRef: tableRef, componentOffsetTop: offsetTop } = useComponentOffsetTop();

  const updateRule = async (movedRule: GlobalRule) => {
    const url = `/api/redirect/rules/${policy}/${movedRule.docRefId}/priority`;
    const res = await request({
      method: 'PUT',
      url,
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      data: { priority: movedRule.priority },
    });

    if (res.status >= 400) {
      throw new Error('Something went wrong when updating the priority of the redirect rule! Please try again later.');
    }
  };

  useEffect(() => {
    if (isGlobalRules(rules, policy)) {
      setGlobalRules(rules);
    }
  }, [rules]);

  useEffect(() => {
    if (!movedRule) return;

    updateRule(movedRule).catch((error) => {
      setGlobalRules(previousGlobalRules);
      setStatusMessage({
        isVisible: true,
        variant: 'cautionary',
        title: 'Redirect update failed',
        bodyText: error.message,
        actions: [],
      });
    });
  }, [movedRule]);

  const ruleList = isR1Global ? globalRules : rules;

  const ids = ruleList.map((rule) => rule.docRefId);
  const token = useSelector(tokenSelector);

  const customTablePropsWhenGlobal = isR1Global ? { targetElementIndex: 3 } : {};

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let columnHeaders: ColumnHeader[] = [
    <Checkbox
      key={0}
      id='selectAll'
      disabled={rules.length === 0}
      checked={isSelectAllChecked}
      onChange={toggleSelectAll}
      value='selectAll'
      data-testid={'select-all-checkbox'}
    />,
    ...columns,
    null,
    null,
  ];

  if (isR1Global) {
    columnHeaders = [null, ...columnHeaders];
  }

  const { editableMarkets } = useMarkets();
  const [country] = useCountry();
  const {
    state: { selectedRule, editRuleModalIsOpen },
    setSelectedRuleValue,
    setEditRuleModalIsOpenValue,
  } = useContext(RulesContext);

  const isEditingAllowed = editableMarkets.some((locale) => locale.country === country?.countryCode);

  const handleEditClick = (currentRule: RuleType) => {
    setSelectedRuleValue(currentRule);
    setEditRuleModalIsOpenValue(true);
  };

  const moveCurrentRuleToNextIndex = (
    prevRules: GlobalRule[],
    currentIndex: number,
    nextIndex: number
  ): { movedRule: GlobalRule; reorganisedRules: GlobalRule[] } => {
    const rules = [...prevRules];

    const currentRule = rules[currentIndex];
    const replaceRule = rules[nextIndex];

    const movingToTopOfList = nextIndex === 0;
    const movingToBottomOfList = nextIndex === rules.length - 1;

    if (movingToTopOfList) {
      currentRule.priority = replaceRule.priority + 1;
    } else if (movingToBottomOfList) {
      currentRule.priority = replaceRule.priority - 1;
    } else {
      const staticAdjacentRule = rules[nextIndex + (currentIndex > nextIndex ? -1 : 1)];
      currentRule.priority = (replaceRule.priority + staticAdjacentRule.priority) / 2;
    }

    rules.sort((a, b) => b.priority - a.priority);

    return { movedRule: currentRule, reorganisedRules: rules };
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      setGlobalRules((prevRules) => {
        const ruleIds = prevRules.map((rule) => rule.docRefId);
        const currentIndex = ruleIds.indexOf(String(active.id));
        const nextIndex = ruleIds.indexOf(String(over.id));

        const { movedRule, reorganisedRules } = moveCurrentRuleToNextIndex(prevRules, currentIndex, nextIndex);
        setMovedRule(movedRule);
        setPreviousGlobalRules(prevRules);
        return reorganisedRules;
      });
    }
  };

  return (
    <>
      {editRuleModalIsOpen && (
        <AddEditRuleModal
          isVisible={editRuleModalIsOpen}
          onSubmit={() => {
            setEditRuleModalIsOpenValue(false);
            clearSelectedRules();
          }}
          onCancel={() => {
            setEditRuleModalIsOpenValue(false);
          }}
          existingRule={selectedRule}
          header={'Edit Rule'}
          toolTipText={'Use this form to edit a rule'}
        />
      )}
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
        <div ref={tableRef}>
          <Styled.Table offsettop={offsetTop} fullWidth {...customTablePropsWhenGlobal}>
            <TableHeader sticky>
              <tr>
                {columnHeaders.map((header, index) => (
                  <RuleTableColumnHeader key={index} columnHeader={header} />
                ))}
              </tr>
            </TableHeader>
            {!isLoading && (
              <TableBody striped>
                <SortableContext items={ids} strategy={verticalListSortingStrategy}>
                  {ruleList.map((rule) => (
                    <Rule
                      id={rule.docRefId}
                      isSelected={selectedRules.includes(rule.docRefId)}
                      key={rule.matchURL + rule.dateModified}
                      rule={rule}
                      onCheckboxClick={() => onRuleToggle(rule.docRefId)}
                      onEditClick={() => handleEditClick(rule)}
                      onDeleteClick={clearSelectedRules}
                      isEditingAllowed={isEditingAllowed}
                      columns={columns}
                      isGlobal={isR1Global}
                    />
                  ))}
                </SortableContext>
              </TableBody>
            )}
          </Styled.Table>
        </div>
      </DndContext>
      {isLoading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
    </>
  );
};
