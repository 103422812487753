import React, { useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import { trashCan } from '@ingka/ssr-icon/icons';

export const DeleteButton: React.FC<{ deleteItem: () => void }> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button text={'Delete'} ssrIcon={trashCan} type={'tertiary'} iconOnly={true} iconPosition={'leading'} onClick={() => setOpen(true)} />
      <Modal
        escapable={false}
        visible={open}
        handleCloseBtn={() => {
          setOpen(!open);
        }}
      >
        <Prompt
          titleId={'Delete'}
          title={'Delete'}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                text={'Yes'}
                type={'primary'}
                onClick={() => {
                  props.deleteItem();
                  setOpen(!open);
                }}
              />
              <Button
                text={'No'}
                type={'secondary'}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            </ModalFooter>
          }
        >
          <p>Do you really want to delete the entry?</p>
        </Prompt>
      </Modal>
    </>
  );
};
