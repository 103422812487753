import React, { ChangeEvent, FC } from 'react';
import { Option } from '@ingka/select';
import SSRIcon from '@ingka/ssr-icon';
import ArrowLeftToLine from '@ingka/ssr-icon/paths/arrow-left-to-line';
import ArrowRightToLine from '@ingka/ssr-icon/paths/arrow-right-to-line';
import ChevronLeft from '@ingka/ssr-icon/paths/chevron-left';
import ChevronRight from '@ingka/ssr-icon/paths/chevron-right';
import { FlexContainer } from 'components/FlexContainer';
import { PaginationOptions, usePaginator } from 'hooks/usePaginator';
import * as Styled from './styles';

type PaginationProps = {
  onItemsPerPageChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & PaginationOptions;

const Pagination: FC<PaginationProps> = ({ onItemsPerPageChange, ...options }) => {
  const { page, lastPage, paginateFirstPage, paginatePreviousPage, paginateNextPage, paginateLastPage } = usePaginator(options);

  const hasItemsPerPageOptions = 'itemsPerPage' in options;

  const itemsPerPageOptions = [
    { value: '50', name: '50' },
    { value: '100', name: '100' },
  ];
  return (
    <FlexContainer justify="flex-end" mr={2}>
      {hasItemsPerPageOptions && (
        <>
          <FlexContainer justify="flex-end" mr={1}>
            <Styled.Selector id="rows-per-page" data-testid={'rows-per-page'} onChange={onItemsPerPageChange} defaultValue="50">
              {itemsPerPageOptions.map((option, index) => (
                <Option key={index} value={option.value} name={option.name} />
              ))}
            </Styled.Selector>
          </FlexContainer>
          <Styled.Text bodySize={'s'}>per page</Styled.Text>
        </>
      )}
      <Styled.Icons>
        <SSRIcon paths={ArrowLeftToLine} data-testid={'paginate-first'} onClick={paginateFirstPage} />
        <SSRIcon paths={ChevronLeft} data-testid={'paginate-backward'} onClick={paginatePreviousPage} />
        <Styled.Text bodySize={'s'}>
          {page} of {lastPage}
        </Styled.Text>
        <SSRIcon paths={ChevronRight} data-testid={'paginate-forward'} onClick={paginateNextPage} />
        <SSRIcon paths={ArrowRightToLine} data-testid={'paginate-last'} onClick={paginateLastPage} />
      </Styled.Icons>
    </FlexContainer>
  );
};

export { Pagination };
