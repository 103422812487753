import React, { BaseSyntheticEvent, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import Modal, { ModalHeader, Prompt } from '@ingka/modal';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import { ContainerWrapper } from 'components/common/Container';
import { ActiveFiltersRow } from 'components/common/FilterAndSortComponent/ActiveFiltersRow';
import { FilterAndSortModal } from 'components/common/FilterAndSortComponent/FilterAndSortModal';
import { MetaDataSearch } from 'components/common/FilterAndSortComponent/MetaDataSearch';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { useLoader } from 'hooks/useLoader';
import { roles } from 'redux/selectors/login';
import { isAdmin } from 'redux/selectors/selectors';
import { Column as MetaDataTableColumns } from 'types';
import { FilterInput, SortInput, useCurrentVersionQuery } from '__generated__/types';
import { MetadataColumnsAndToggle } from './MetadataColumnsAndToggle';
import { MetaDataEntryButtonsAndPagination } from './MetaDataEntryButtonsAndPagination';
import { MetaDataEntryTableData } from './MetadataEntryTableData';
import { ErrorMessage } from 'components/common/styles';
import * as Styled from './styles';

export const defaultFilter: FilterInput = {
  optimized: null,
  textFilter: null,
  selfCanonicalized: null,
  valid: null,
  hasProducts: null,
  needsReview: false,
  homeFurnishingBusinessId: null,
  page: 0,
};

export const MetaDataEntryTable: React.FC<{ id: string; preFilter?: Partial<FilterInput> }> = ({ id, preFilter }) => {
  const initialFilter = { ...defaultFilter, ...preFilter };
  const defaultSort: SortInput = { direction: 'desc', field: 'search_clicks' };
  const [filter, setFilter] = useState<FilterInput>(initialFilter);
  const [sort, setSort] = useState<SortInput>(defaultSort);
  const { setMetaDataContext } = useContext(MetaDataContext);
  const userRoles = useSelector(roles);
  const isAdminUser = useSelector(isAdmin);
  const [pending, setPending] = useLoader();
  const [activeIndexListOrSquareView, setActiveIndexListOrSquareView] = useState(1);
  const [chosenColumns, setChosenColumns] = useState<MetaDataTableColumns[]>([]);

  const { loading, data, error, refetch } = useCurrentVersionQuery({
    variables: {
      id,
      filter,
      sort,
    },
    onCompleted: (data) => {
      if (!data?.currentVersion) return;
      const isEditorUser = userRoles.includes(data.currentVersion.country);
      const userCanEdit = (isEditorUser && data.currentVersion.type !== 'PIP') || isAdminUser;
      const version = {
        country: data?.currentVersion?.country,
        language: data?.currentVersion?.language,
        type: data?.currentVersion?.type,
        userCanEdit,
        versionId: data.currentVersion._id,
        isDraft: data.currentVersion.isDraft,
      };
      setMetaDataContext(version);
    },
    fetchPolicy: 'no-cache',
  });

  const [locale, setLocale] = useState({
    country: data?.currentVersion?.country,
    language: data?.currentVersion?.language,
    type: data?.currentVersion?.type,
  });

  if (data && data.currentVersion && (data?.currentVersion?.language !== locale.language || data?.currentVersion?.country !== locale.country)) {
    setLocale({
      country: data.currentVersion.country,
      language: data.currentVersion.language,
      type: data.currentVersion.type,
    });
  }

  const setFilterData = (fields: Partial<FilterInput>) =>
    setFilter((prev) => ({
      ...prev,
      ...fields,
    }));

  const onSearchChange = (value: string) => {
    setFilterData({ textFilter: value });
  };

  const onApply = (filter: FilterInput, sort: SortInput) => {
    setFilterData(filter);
    setSort(sort);
  };

  const setPage = (page: number) => setFilterData({ page });
  const clearFilter = () => setFilterData(defaultFilter);

  const deselectFilter = (key: keyof FilterInput) => setFilterData({ [key]: defaultFilter[key] });
  const clearSort = () => setSort(defaultSort);

  const handleToggleListOrSquareView = (eventObj: BaseSyntheticEvent, index: number) => setActiveIndexListOrSquareView(index);

  const onLocalStorageColumnsChange = (localStorageColumns: MetaDataTableColumns[]) => {
    setChosenColumns(localStorageColumns);
  };

  const totalPages = data?.currentVersion?.entries.totalPages ?? 0;
  const totalItems = data?.currentVersion?.size ?? 0;
  const isDraft = data?.currentVersion?.isDraft;
  const isFilterPage = data?.currentVersion?.type === 'filter';
  const draftBodyMessage = `${
    isFilterPage ? 'In edit mode; check "publish" to mark as ready for production.' : ''
  } Press the Publish button in order for the changes to go to production`;

  const isEditorUser = locale.country && userRoles.includes(locale.country);
  const isLatestVersion = !!data?.currentVersion?.latest;
  const userCanEdit = ((isEditorUser && locale.type !== 'PIP') || isAdminUser) && isLatestVersion;

  if (error) {
    return (
      <ErrorMessage>
        {error.message} <Button text={'Retry'} onClick={() => window.location.reload()} size={'small'} />
      </ErrorMessage>
    );
  } else {
    return (
      <ContainerWrapper>
        {locale && (
          <>
            {isDraft && (
              <Styled.DraftInlineMessage ssrIcon={warningTriangleIcon} variant={'cautionary'} title={'Draft version'} body={draftBodyMessage} />
            )}
            <Styled.SearchRowContainer gap={1} align="center" squareDisplayActive={activeIndexListOrSquareView === 1}>
              <MetaDataSearch onSearchChange={onSearchChange} />
              <FilterAndSortModal
                defaultFilter={defaultFilter}
                defaultSort={defaultSort}
                filter={filter}
                sort={sort}
                onApply={onApply}
                type={locale.type || ''}
              />
              {data?.currentVersion && (
                <MetadataColumnsAndToggle
                  type={data?.currentVersion?.type}
                  onToggleListOrSquareView={handleToggleListOrSquareView}
                  activeIndexListOrSquareView={activeIndexListOrSquareView}
                  onLocalStorageChangeColumns={onLocalStorageColumnsChange}
                />
              )}
            </Styled.SearchRowContainer>
            <MetaDataEntryButtonsAndPagination
              totalItems={totalItems}
              country={locale.country || ''}
              language={locale.language || ''}
              type={locale.type || ''}
              totalPages={totalPages}
              versionId={data?.currentVersion?._id || ''}
              setPage={setPage}
              filter={filter}
            />
          </>
        )}

        <ActiveFiltersRow
          filter={filter}
          sort={sort}
          defaultFilters={defaultFilter}
          defaultSort={defaultSort}
          clearFilter={clearFilter}
          clearSort={clearSort}
          deselectFilter={deselectFilter}
        />
        {loading && (
          <Loading text={'Please wait...'}>
            <LoadingBall />
          </Loading>
        )}
        {pending && (
          <Modal visible={pending} escapable={false} handleCloseBtn={() => setPending(false)}>
            <Prompt titleId={'Processing request'} title={'Processing request'} header={<ModalHeader />} footer={null}>
              <Loading text={''}>
                <LoadingBall />
              </Loading>
            </Prompt>
          </Modal>
        )}
        {data?.currentVersion && !loading && (
          <MetaDataEntryTableData
            isEditable={userCanEdit}
            data={data.currentVersion}
            refetchCallback={refetch}
            filter={filter}
            displayListOrSquareView={activeIndexListOrSquareView}
            columns={chosenColumns}
          />
        )}
      </ContainerWrapper>
    );
  }
};
