import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from 'components/common/Container';
import { MetaDataCountrySwapper } from 'features/MetaData/MetaDataCountrySwapper';
import { MetaDataList } from 'features/MetaData/MetaDataList';
import { useCountry } from 'hooks/useCountry';

const MetaData: React.FC = () => {
  const [country] = useCountry();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const env = searchParams.get('env');
  const urlCountryCode = env?.substring(0, 2) || '';
  const urlLanguageCode = env?.substring(3, 5) || '';
  const entryId = searchParams.get('entryId') || '';

  if (urlCountryCode === country?.countryCode) {
    history.push(`/metadata/edit/${urlCountryCode}/${urlLanguageCode}/PLP/${entryId}`);
  }

  return (
    <Container page={'metaData'} country={country?.countryCode}>
      {urlCountryCode !== country?.countryCode && searchParams.has('env') ? (
        <MetaDataCountrySwapper newCountry={urlCountryCode} newLanguage={urlLanguageCode} entryId={entryId} />
      ) : (
        country?.countryCode && <MetaDataList country={country?.countryCode} />
      )}
    </Container>
  );
};

export { MetaData };
