import styled from '@emotion/styled';

export const CheckboxGroup = styled.div`
  padding: 12px;
  gap: 12px;
  display: grid;
  .checkbox__label {
    font-size: 12px;
  }
`;
export const CheckboxGrid = styled.div`
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    '. .'
    '. .'
    '. .'
    '. .';
`;
export const ButtonFooter = styled.div`
  display: flex;
  width: 255px;
  padding-top: 16px;

  .inner_padding_over_ride {
    padding-right: 16px;
  }
  .inner_padding_over_ride > .btn__inner {
    padding: 0 1rem;
  }

  button:last-child {
    margin-left: auto;
  }
`;
