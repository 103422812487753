import React from 'react';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';

type SurveyModalProps = {
  isVisible: boolean;
  question: string;
  buttons: React.ReactNode;
  children?: React.ReactNode;
  onClickClose: () => void;
};

export const SurveyModal: React.FC<SurveyModalProps> = ({ isVisible, question, buttons, children, onClickClose }) => {
  return (
    <Modal visible={isVisible} handleCloseBtn={() => onClickClose()}>
      <Prompt titleId={question} title={question} header={<ModalHeader />} footer={<ModalFooter>{buttons}</ModalFooter>}>
        {children}
      </Prompt>
    </Modal>
  );
};
