import React, { Component, ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from './api/graphql';
import { Routes } from './pages/Routes';
import store, { persistor } from './redux/store';
import '@ingka/base/dist/style.min.css';
import '@ingka/breadcrumb/dist/style.min.css';
import './App.css';

class App extends Component {
  render(): ReactNode {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <CookiesProvider>
            <PersistGate persistor={persistor}>
              <Routes />
            </PersistGate>
          </CookiesProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
