import React, { ReactElement } from 'react';
import { usePaginator } from 'hooks/usePaginator';
import { PaginationT } from 'opt-util/paginate';
import { Pagination } from './Pagination';
import Table, { Column, TableProps } from './Table';

interface PaginatedTableProps<T> extends Omit<TableProps, 'data'> {
  data: PaginationT<T>;
  columns: Column[];
}
export const PaginatedTable = <T,>(props: PaginatedTableProps<T>): ReactElement => {
  const paginator = usePaginator({ itemsPerPage: props.data.pageSize, totalItems: props.data.totalItems });
  const currentData = props.data.data[paginator.page - 1];
  return (
    <>
      <Pagination paginator={paginator} />
      <Table {...props} data={currentData} />
      <Pagination paginator={paginator} />
    </>
  );
};
