import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { CountryGrouped } from 'types';

export const useCountry = (): [CountryGrouped | null, (country: CountryGrouped, path?: string) => void] => {
  const [selectedCountryCookie, setSelectedCountryCookie] = useCookies(['selectedCountry']);
  const history = useHistory();
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  return [
    selectedCountryCookie?.selectedCountry || null,
    (country, path) => {
      setSelectedCountryCookie('selectedCountry', country, { expires: expirationDate, path: '/' });
      path && history.push(path);
    },
  ];
};
