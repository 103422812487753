import React, { FC, ReactElement } from 'react';
import { FlexContainer } from 'components/FlexContainer';
import { Label, Value } from './styles';

export const KeyValue: FC<{
  label: string;
  value?: string | ReactElement | boolean | null;
  labelSize?: number;
}> = (props) => {
  return props.value ? (
    <FlexContainer justify='flex-start'>
      <Label flexSize={props.labelSize}>{props.label}</Label>
      <Value>{props.value}</Value>
    </FlexContainer>
  ) : null;
};
