import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import Text from '@ingka/text';
import { Pagination } from 'components/Pagination';
import { FilterInput } from '__generated__/types';
import * as Styled from './styles';
import { useMetaDataExport } from 'hooks/useMetaDataExport';

interface MetaDataEntryButtonsAndPaginationProps {
  totalItems: number;
  totalPages: number;
  country: string;
  language: string;
  type: string;
  versionId: string;
  setPage: (i: number) => void;
  filter: FilterInput;
}

export const MetaDataEntryButtonsAndPagination: React.FC<MetaDataEntryButtonsAndPaginationProps> = ({
  totalItems,
  totalPages,
  country,
  language,
  type,
  versionId,
  setPage,
  filter,
}) => {
  const history = useHistory();
  const { exportMetaData } = useMetaDataExport();

  const downloadFile = async () =>
    await exportMetaData(
      versionId,
      filter,
      {
        country: country,
        language: language,
        type: type,
      },
      'xlsx'
    );

  const uploadClick = () => {
    history.push(`/metadata/upload/${country}/${language}/${type}`);
  };

  return (
    <>
      <Styled.MetaDataFilteringWrapper gap={0.5} align="center">
        <Button
          ssrIcon={arrowDown}
          text={'Download all'}
          type={'secondary'}
          onClick={() => downloadFile()}
          data-testid={'metadata-download-btn'}
          size={'small'}
        />
        <Button
          ssrIcon={arrowUp}
          text={'Upload'}
          type={'secondary'}
          onClick={() => uploadClick()}
          data-testid={'metadata-upload-btn'}
          size={'small'}
        />

        <Text tagName="span" bodySize="m">
          {totalItems} items found
        </Text>

        <Pagination totalPages={totalPages} onPageChange={setPage} />
      </Styled.MetaDataFilteringWrapper>
    </>
  );
};
