import React from 'react';
import { TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import { OmitTN } from 'opt-util/types';
import { MetaDataCompleteFragment } from '__generated__/types';
import { TableBody } from './TableBody';
import * as Styled from './styles';

interface KeywordsTableProps {
  entry: OmitTN<MetaDataCompleteFragment>;
}

export const KeywordsTable: React.FC<KeywordsTableProps> = ({ entry }) => {
  return (
    <Styled.PaddedAccordion>
      <Styled.AccordionKeywordsItem size="small" id="1" title="Suggested keywords">
        <Styled.FixedTable>
          <TableHeader>
            <tr>
              <th>
                <Text tagName="p" bodySize="s">
                  Keywords
                </Text>
              </th>
              <th></th>
            </tr>
          </TableHeader>
          <TableBody entry={entry} />
        </Styled.FixedTable>
      </Styled.AccordionKeywordsItem>
    </Styled.PaddedAccordion>
  );
};
