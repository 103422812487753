import { Dispatch } from 'react';
import { NormalizedEntry } from 'opt-util/validation';
import { Action, createDataContext } from './createDataContext';

export interface VersionDetails {
  urlSlugSyncEnabled: boolean;
  type: string;
  versionId: string;
  market: { country: string; language: string };
}
export type FormData = NormalizedEntry & {
  descriptionIsSynced: boolean;
  isOptimized: boolean;
  needsReview: boolean;
  author?: string | null | undefined;
  ts?: number | undefined | null;
  diffVersionId?: string | undefined;
};
export type FormKeys = keyof FormData;

export interface State {
  errors: { [fieldId: string]: string };
  hasErrors: boolean;
  formData: FormData | undefined;
  versionDetails: VersionDetails | undefined;
}

const formReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set':
      const { key, value } = action.payload;
      if (!state.formData) return state;
      if (state.formData.descriptionIsSynced) {
        if (key === 'pageTitle') return { ...state, formData: { ...state.formData, [key]: value, ogTitle: value } };
        if (key === 'description') return { ...state, formData: { ...state.formData, [key]: value, ogDescription: value } };
      }
      if (key === 'descriptionIsSynced') {
        if (value) {
          return {
            ...state,
            formData: { ...state.formData, descriptionIsSynced: true, ogTitle: state.formData.pageTitle, ogDescription: state.formData.description },
          };
        } else {
          return {
            ...state,
            formData: { ...state.formData, descriptionIsSynced: false },
          };
        }
      }
      return { ...state, formData: { ...state.formData, [key]: value } };
    case 'init':
      return { ...state, ...action.payload.data };
    case 'error':
      const errors = { ...state.errors, [action.payload.fieldId]: action.payload.errorMessage };
      return { ...state, errors, hasErrors: Object.entries(errors).some(([, errorMessage]) => errorMessage) };
    default:
      return state;
  }
};

const setField =
  (dispatch: Dispatch<Action>) =>
  <K extends FormKeys>(key: K, value: FormData[K]) => {
    dispatch({ type: 'set', payload: { key, value } });
  };

const setError = (dispatch: Dispatch<Action>) => (fieldId: string, errorMessage: string) => {
  dispatch({ type: 'error', payload: { fieldId, errorMessage } });
};

export const { Provider, Context } = createDataContext(
  formReducer,
  { setField, setError },
  { hasErrors: false, errors: {}, formData: undefined, versionDetails: undefined }
);
