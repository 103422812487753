import React, { ChangeEvent, ReactNode, SyntheticEvent, useRef, useState } from 'react';
import informationIcon from '@ingka/ssr-icon/paths/information';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { ToolTipButton, TooltipContainer, TooltipMessage } from './styles';

interface TooltipProps {
  children: ReactNode;
}
export const Tooltip: React.FC<TooltipProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipMessageRef = useRef(null);
  const tooltipButtonRef = useRef(null);
  useOnClickOutside([tooltipMessageRef, tooltipButtonRef], () => setIsOpen(false));

  return (
    <TooltipContainer ref={tooltipButtonRef}>
      <ToolTipButton
        ssrIcon={informationIcon}
        type={'tertiary'}
        iconOnly={true}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      />
      {isOpen && <TooltipMessage ref={tooltipMessageRef}>{children}</TooltipMessage>}
    </TooltipContainer>
  );
};
