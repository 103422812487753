import React, { FC } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { FlexContainer } from 'components/FlexContainer';
import LeftText from 'components/RichEditor/icons/text-left.svg';
import { EditorContainer, LeftTextIcon } from 'components/RichEditor/styles';
import { ToolbarPlugin } from './ToolbarPlugin';

type RichTextProps = {
  showBlockOptions: boolean;
  canTranslate: boolean;
  editorLabel?: string;
  hideToolbar?: boolean;
  compact?: boolean;
  openTranslationModal?: () => void;
};

export const RichText: FC<RichTextProps> = ({ editorLabel, hideToolbar, compact, openTranslationModal, showBlockOptions, canTranslate }) => {
  const [editor] = useLexicalComposerContext();

  return (
    <EditorContainer id={editor._config.namespace} compact={compact}>
      <FlexContainer gap={0.4} mt={0.2}>
        <LeftTextIcon src={LeftText} />
        <label>{editorLabel}</label>
      </FlexContainer>
      {!hideToolbar && <ToolbarPlugin openTranslationModal={openTranslationModal} showBlockOptions={showBlockOptions} canTranslate={canTranslate} />}
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        placeholder={<div className="editor-placeholder">Enter some rich text...</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
    </EditorContainer>
  );
};

export { RichTextPlugin };
