import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@ingka/button';
import Table, { TableBody } from '@ingka/table';
import { GaxiosResponse, request } from 'gaxios';
import { TextAreaInputField } from 'components/common/TextAreaInputField';
import { effects } from 'redux/reducers/login';
import { AppDispatch } from 'redux/store';

interface SearchResult {
  link: string;
  market: string;
  term: string;
  ts: string;
}

const search = async (term: string, token: string) => {
  const reqBody = term.match(/^https?.*/) ? { url: term } : { id: term };
  return await request<SearchResult[]>({ method: 'POST', url: '/api/sitemaps/search', data: reqBody, headers: { Authorization: `Bearer ${token}` } })
    .then((res: GaxiosResponse<SearchResult[]>) => {
      return res.data;
    })
    .catch(() => []);
};

export const UrlSearchComp: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const refreshToken = () => dispatch(effects.refreshToken());
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState('');

  const handleSubmit = async (searchTerms: string) => {
    setResults([]);
    setLoading(true);
    const searchTermsArr = searchTerms.split(',').map((q) => q.trim());
    const token: string = await refreshToken();
    let results: SearchResult[] = [];

    await Promise.all(
      searchTermsArr.map(async (term) => {
        const res = await search(term, token);
        results = [...results, ...res];
      })
    );

    setResults(results);
    setLoading(false);
  };
  return (
    <>
      <TextAreaInputField
        shouldValidate={false}
        id={'searchterms'}
        label={'URLs to search'}
        placeholder={'Comma separated list of urls or Ids'}
        onChange={(e) => setFormData(e.target.value)}
        value={formData}
      />
      <Button text={'Search'} type={'primary'} disabled={!formData} onClick={() => handleSubmit(formData)} size={'small'} />
      {!isLoading && results.length > 0 && (
        <Table data-testid={'results-table'} inset={true} className={'url-search-results'}>
          <TableBody striped={true}>
            {results.map((record: SearchResult, index: number) => (
              <tr key={`${record.term}-${index}`}>
                <td>Search term: {record.term}</td>
                <td>Market: {record.market}</td>
                <td>Created: {record.ts}</td>
                <td>Can be found in: {record.link}</td>
              </tr>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
