import React, { SVGProps } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import * as Styled from './styled';

export const TableColumnIcon: React.FC<{
  isSortedColumn: boolean;
  paths: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  columnField: string;
}> = ({ isSortedColumn, paths, columnField }) => (
  <Styled.SortIcon isSortedColumn={isSortedColumn}>
    <SSRIcon id={'sort-icon'} paths={paths} data-testid={`sort-icon-${columnField}`} />
  </Styled.SortIcon>
);
