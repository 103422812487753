import { connect } from 'react-redux';
import { UploadNewLocale } from 'components/admin/UploadNewLocale';
import Container from 'components/common/Container';
import { isAdmin } from 'redux/selectors/selectors';
import { RootState } from 'redux/store';

interface UploadCompProps {
  isAdminUser: boolean;
}

const UploadComp = (props: UploadCompProps) => {
  return (
    <Container page="upload">
      {props.isAdminUser ? (
        <>
          <UploadNewLocale />
        </>
      ) : (
        <div>
          <p>
            <strong>You are not authorized to access this page</strong>
          </p>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState): UploadCompProps => ({
  isAdminUser: isAdmin(state),
});

export default connect(mapStateToProps)(UploadComp);
