import styled from '@emotion/styled';
import Text from '@ingka/text';

export const Container = styled.div`
  background: #0058a3;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;

export const Message = styled.div`
  display: grid;
  place-items: center;
  gap: 10px;
`;

export const Modal = styled.div`
  display: grid;
  place-items: center;
  gap: 30px;
`;

export const Logo = styled.img`
  width: 136px;
`;

export const Typography = styled(Text)`
  color: white;
`;
