import React from 'react';
import { useLocation } from 'react-router-dom';
import { DropdownOptionProps } from 'components/Dropdown';
import { useGetHfbDropdownOptionsQuery } from '__generated__/types';
import * as Styled from './styled';

type HFBDropdownProps = {
  value: string;
  onChange: (value: DropdownOptionProps) => void;
};
export const HFBDropdown: React.FC<HFBDropdownProps> = ({ value, onChange }) => {
  const { pathname } = useLocation();
  const versionId = pathname.split('/metadata/view/')[1];
  const { data } = useGetHfbDropdownOptionsQuery({
    variables: { versionId: versionId },
  });
  const options: DropdownOptionProps[] = data?.hfbDropdownOptions.map(({ text, key }) => ({ value: key, label: text })) ?? [];

  return <Styled.FilterDropdown id={'hfb-select'} value={value} onOptionChange={onChange} options={options} hintText={"All HFB's"} />;
};
