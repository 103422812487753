import React from 'react';
import styled from '@emotion/styled';
import Button from '@ingka/button';
import { range } from 'ramda';
import { Paginator } from 'hooks/usePaginator';

const PageNumbers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

const Page = styled.span<{ selected?: boolean }>`
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
  width: 4rem;
  text-align: center;
  margin: 0 0.3em;
  background-color: ${({ selected }) => (selected ? '#f0f0f0' : 'white')};
  &:hover {
    background-color: #f4f4f4;
  }
`;

const Summary = styled.div`
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0.3em;
  background-color: 'white';
`;
interface PaginationProps {
  paginator: Paginator;
}
export const Pagination: React.FC<PaginationProps> = ({ paginator }) => {
  const { page, lastPage, paginatePreviousPage, paginateNextPage, paginatePage } = paginator;
  const start = Math.max(1, Math.min(page - 5, lastPage - 10));
  const end = Math.min(lastPage + 1, start + 11);
  const isSelected = (pageNumber: number) => pageNumber === page;
  const shouldDisplayPaginationButtons = lastPage > 1;
  return (
    <>
      {shouldDisplayPaginationButtons && (
        <PageNumbers data-testid={'pagination'}>
          <Button onClick={paginatePreviousPage} text={'<<'} />
          {range(start, end).map((page) => (
            <Page
              key={`page-${page}`}
              selected={isSelected(page)}
              data-testid={isSelected(page) ? 'selected' : undefined}
              onClick={() => paginatePage(page)}>
              {page}
            </Page>
          ))}
          <Button onClick={paginateNextPage} text={'>>'} />
        </PageNumbers>
      )}
      <Summary aria-labelledby='summary-pagination' data-testid='summary-pagination'>
        {page} of {lastPage}
      </Summary>
    </>
  );
};
