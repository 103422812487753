import React, { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from 'components/FloatingMenu/styles';

function useOnClickOutside(ref: RefObject<HTMLDivElement>, cancellation: boolean, preventClick: boolean): [boolean, (p: boolean) => void] {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (cancellation) {
      setOpen(false);
    }

    if (preventClick) {
      return;
    }

    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      event.stopPropagation();
      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, cancellation]);
  return [open, setOpen];
}

type FloatingMenuProps = {
  children: (open: boolean, toggleOpen: () => void) => ReactNode;
  cancellation?: boolean;
  preventCloseOnClick?: boolean;
};

export const FloatingMenu: React.FC<FloatingMenuProps> = ({ children, cancellation = false, preventCloseOnClick = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useOnClickOutside(ref, cancellation, preventCloseOnClick);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return <Styled.Wrapper ref={ref}>{children(open, toggleOpen)}</Styled.Wrapper>;
};
