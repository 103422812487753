import React, { ChangeEvent } from 'react';
import FormField from '@ingka/form-field';
import * as Styled from './styled';

interface FilteredPagesTableRowProps {
  fieldTitle: string;
  fieldId: string;
  fieldType: 'text' | 'password' | 'number' | 'email' | 'hidden';
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  shouldValidate?: boolean;
  readOnly?: boolean;
  disabled: boolean;
  hasError?: boolean;
  errorMessage?: string;
}

export const FilteredPagesTableRow: React.FC<FilteredPagesTableRowProps> = ({
  fieldTitle,
  fieldId,
  fieldType,
  value,
  onChange,
  readOnly,
  disabled,
  hasError,
  shouldValidate,
  errorMessage,
}) => {
  return (
    <Styled.TableRow>
      <Styled.TableData>{fieldTitle}</Styled.TableData>
      <Styled.TableData>
        {!readOnly && onChange && (
          <FormField
            validation={{
              msg: errorMessage,
            }}
            valid={!errorMessage}
            shouldValidate={shouldValidate}
          >
            <Styled.SmallerInputField id={fieldId} type={fieldType} value={value} onChange={onChange} disabled={disabled} data-testid={fieldId} />
          </FormField>
        )}
        {readOnly && !onChange && (
          <Styled.Message hasError={hasError} data-testid={fieldId}>
            {value}
          </Styled.Message>
        )}
      </Styled.TableData>
    </Styled.TableRow>
  );
};
