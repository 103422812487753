import styled from '@emotion/styled';
import { Sheets } from '@ingka/modal';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const FullScreenSheet = styled(Sheets)`
  width: 100%;
  max-width: 100vw;
  border-radius: 0;
  .sheet__content {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export const MessageContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const EditorConfirmTitle = styled.h2`
  margin-bottom: 4rem;
`;

export const EditorConfirmText = styled.p`
  font-size: 1.2rem;
`;

export const ModalSideBar = styled.section`
  display: flex;
  width: 15rem;
  min-width: 15rem;
  max-width: 15rem;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;
