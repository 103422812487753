import styled from '@emotion/styled';
import * as R from 'ramda';

const defaultMargin = R.defaultTo(0);

const marginValue = (val?: number): string => `${defaultMargin(val)}em`;
interface MarginProps {
  size?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}
export default styled.div<MarginProps>`
  margin: ${(props) => marginValue(props.size)};
  margin-top: ${(props) => marginValue(props.top || props.size)};
  margin-bottom: ${(props) => marginValue(props.bottom || props.size)};
  margin-left: ${(props) => marginValue(props.left || props.size)};
  margin-right: ${(props) => marginValue(props.right || props.size)};
`;
