import React, { useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalHeader } from '@ingka/modal';
import filtersIcon from '@ingka/ssr-icon/paths/filters';
import { FilterInput, SortInput } from '__generated__/types';
import { ValidityFilter } from './ValidityFilter';
import * as Styled from './styled';

interface FilterAndSortModalProps {
  type: string;
  defaultFilter: FilterInput;
  filter: FilterInput;
  defaultSort: SortInput;
  sort: SortInput;
  onApply: (filter: FilterInput, sort: SortInput) => void;
}

export const FilterAndSortModal: React.FC<FilterAndSortModalProps> = ({ type, filter, sort, defaultFilter, defaultSort, onApply }) => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [modalFilter, setModalFilter] = useState<FilterInput>(defaultFilter);
  const [modalSort, setModalSort] = useState<SortInput>(defaultSort);

  const applyFilters = () => {
    onApply(modalFilter, modalSort);
    setIsFiltersModalOpen(false);
  };

  const onFilterChange = <FilterInput, K extends keyof FilterInput>(key: K, value: FilterInput[K]) => {
    setModalFilter((prev) => ({ ...prev, [key]: value }));
  };

  const onSortChange = (sort: SortInput) => setModalSort(sort);

  const onCancel = () => {
    setIsFiltersModalOpen(false);
  };

  const onClear = () => {
    setModalFilter(defaultFilter);
    setModalSort(defaultSort);
  };

  return (
    <>
      <Button
        data-testid={'filters-btn'}
        text={'Filters'}
        iconPosition={'leading'}
        type={'secondary'}
        ssrIcon={filtersIcon}
        onClick={() => {
          setModalFilter(filter);
          setModalSort(sort);
          setIsFiltersModalOpen(true);
        }}
      />

      <Modal visible={isFiltersModalOpen} handleCloseBtn={onCancel}>
        <Styled.PromptWithWidth
          titleId={''}
          title={''}
          header={<ModalHeader />}
          footer={
            <Styled.ButtonFooter>
              <Button text="Clear all" type="tertiary" onClick={onClear} size="small" />
              <Button text="Cancel" type="tertiary" onClick={onCancel} size="small" />
              <Button text="Apply" type="primary" onClick={applyFilters} size="small" />
            </Styled.ButtonFooter>
          }
        >
          <ValidityFilter type={type} filter={modalFilter} sort={modalSort} onFilterChange={onFilterChange} onSortChange={onSortChange} />
        </Styled.PromptWithWidth>
      </Modal>
    </>
  );
};
