import { environment, ValidationOptions } from 'environment';
import { defaultTo, prop } from 'ramda';
import { client } from 'api/graphql';
import { FilteredPageURL } from 'components/admin/FilteredPages';
import { DUPLICATE_SLUGS_QUERY } from 'components/seo/queries';
import { MetaDataCompleteFragment, MetaDataExtendedFragment } from '__generated__/types';
import { OmitTN } from './types';

export type NormalizedEntry = OmitTN<MetaDataCompleteFragment> & {
  author?: string | null | undefined;
  diffVersionId?: string | undefined;
  ts?: number | undefined | null;
};
export type AllKeys = keyof Omit<NormalizedEntry, 'isOptimized'>;
type ValidationFn = (input: string | undefined, versionId?: string, id?: string, type?: string) => string | Promise<string | undefined> | undefined;
const urlSlugMatcher = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const getValidationFunction = (propName: AllKeys, label?: string): ValidationFn => {
  const validation = environment.validation;
  const validationOpts = defaultTo<ValidationOptions, ValidationOptions>({}, prop(propName, validation));
  return async (value: string | undefined, versionId, id, type) => {
    const str = value || '';
    if (type === 'filter' && ['description', 'ogDescription'].includes(propName)) return;
    if (type === 'filter' && !['robots', 'ogImage', 'keywords', 'filterType', 'filterName'].includes(propName) && !str) {
      return 'Required';
    }
    if (propName === 'urlSlug') {
      if (!str) {
        return 'URL slug cannot be empty. Please provide a valid URL slug.';
      }
      if (!str.match(urlSlugMatcher)) {
        return 'URL Slug must only use alphanumeric characters and hyphen (e.g. "valid-url-slug")';
      }
      if (type === 'PIP') return;
      const { data } = await client.query({
        query: DUPLICATE_SLUGS_QUERY,
        variables: { id: versionId, urlSlug: str },
        fetchPolicy: 'no-cache',
      });
      const entries = data.currentVersion.entriesWithSameSlug as string[];
      const entriesOtherThanItSelf = entries.filter((entryId) => entryId !== id);
      if (entriesOtherThanItSelf.length > 0) {
        return `URL slug is already in use by: ${entriesOtherThanItSelf.toString()}`;
      }
    }
    if (validationOpts.min && str.length < validationOpts.min) {
      return `Minimum length for ${label || propName} is ${validationOpts.min} characters`;
    }
    if (validationOpts.max && str.length > validationOpts.max) {
      return `Maximum length for ${label || propName} is ${validationOpts.max} characters`;
    }

    if (str.match(/<\s*.*?>/)) {
      return `Invalid input for ${label || propName}. Should not contain <...>`;
    }
    if (propName === 'pageTitle') {
      const [, illegalChars] = str.match(/(www|http|(?<=[\s,.:;"']|^)(?=[\s,.:;"']|$))/i) ?? [];
      if (!illegalChars) return;
      return `Invalid input for ${label || propName}. Should not contain: ${illegalChars}`;
    }
  };
};
export const validationFunctions: Record<AllKeys, ValidationFn> = {
  id: getValidationFunction('id'),
  description: getValidationFunction('description', 'description'),
  keywords: getValidationFunction('keywords'),
  pageTitle: getValidationFunction('pageTitle', 'Page title'),
  kategoriseraTitle: getValidationFunction('kategoriseraTitle', 'Kategorisera title'),
  previousKategoriseraTitle: getValidationFunction('previousKategoriseraTitle', 'Kategorisera title'),
  robots: getValidationFunction('robots'),
  canonical: getValidationFunction('canonical'),
  canonicalId: getValidationFunction('canonicalId', 'Canonical ID'),
  ogTitle: getValidationFunction('ogTitle'),
  ogImage: getValidationFunction('ogImage'),
  ogDescription: getValidationFunction('ogDescription'),
  introText: getValidationFunction('introText'),
  bodyCopy: getValidationFunction('bodyCopy'),
  h1: getValidationFunction('h1'),
  hasProducts: getValidationFunction('hasProducts'),
  referenceUrl: getValidationFunction('referenceUrl'),
  urlSlug: getValidationFunction('urlSlug'),
  primaryKeyword: getValidationFunction('primaryKeyword'),
  secondaryKeyword: getValidationFunction('secondaryKeyword'),
  alternativeKeyword: getValidationFunction('alternativeKeyword'),
  suggestedPrimaryKeyword: getValidationFunction('suggestedPrimaryKeyword'),
  suggestedSecondaryKeyword: getValidationFunction('suggestedSecondaryKeyword'),
  suggestedAlternativeKeyword: getValidationFunction('suggestedAlternativeKeyword'),
  filterId: getValidationFunction('filterId'),
  filterName: getValidationFunction('filterName'),
  filterType: getValidationFunction('filterType'),
  filters: getValidationFunction('filters'),
  author: getValidationFunction('author'),
  diffVersionId: getValidationFunction('diffVersionId'),
  ts: getValidationFunction('ts'),
};

export const isValid = async (entry: MetaDataExtendedFragment): Promise<boolean> => {
  const validateProperty = async (val: string | undefined, key: AllKeys): Promise<boolean> => {
    const validationFn = validationFunctions[key];
    if (validationFn) {
      return !(await validationFn(val));
    }
    return true;
  };
  const keys: AllKeys[] = [
    'id',
    'canonical',
    'pageTitle',
    'canonicalId',
    'urlSlug',
    'keywords',
    'description',
    'robots',
    'primaryKeyword',
    'secondaryKeyword',
    'alternativeKeyword',
    'suggestedAlternativeKeyword',
    'suggestedPrimaryKeyword',
    'suggestedSecondaryKeyword',
  ];
  let isValid = true;
  for (const key of keys) {
    isValid = isValid && (await validateProperty(entry[key], key));
  }
  return isValid;
};

export const validateInputValue = (field: keyof FilteredPageURL | AllKeys, value: string) => {
  let isValid;
  if (field === 'urlSlug' && value.length > 0) {
    isValid = value.match(urlSlugMatcher);
    return !isValid && 'URL Slug must only use alphanumeric characters and hyphen (e.g. "valid-url-slug")';
  }
};
