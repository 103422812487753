import React, { BaseSyntheticEvent, FC, useEffect } from 'react';
import list from '@ingka/ssr-icon/paths/list';
import squareGrid from '@ingka/ssr-icon/paths/square-grid-2x2';
import { CheckboxFields, CheckboxGroup } from 'components/CheckboxGroup';
import { Toggle } from 'components/Toggle';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Column as MetaDataTableColumn } from 'types';

type MetadataColumnsAndToggleProps = {
  type: string;
  activeIndexListOrSquareView: number;
  onToggleListOrSquareView: (event: BaseSyntheticEvent, index: number) => void;
  onLocalStorageChangeColumns: (columns: MetaDataTableColumn[]) => void;
};

export const MetadataColumnsAndToggle: FC<MetadataColumnsAndToggleProps> = ({
  type,
  onToggleListOrSquareView,
  activeIndexListOrSquareView,
  onLocalStorageChangeColumns,
}) => {
  const defaultColumns: MetaDataTableColumn[] = [
    { label: 'Id', value: 'entryId', visible: true },
    { label: 'Page title', value: 'pageTitle', visible: true },
    { label: 'Url slug', value: 'urlSlug', visible: false },
    { label: 'Robots', value: 'robots', visible: true },
    { label: 'Updated at', value: 'ts', visible: false },
    { label: 'Updated by', value: 'author', visible: false },
    { label: 'Status', value: 'status', visible: true },
  ];

  const [columns, setColumns] = useLocalStorage<MetaDataTableColumn[]>(`config:metadata-columns-${type}`, defaultColumns);
  const defaultCheckBoxes: CheckboxFields[] = columns
    .map(({ label, value, visible }) => ({
      checked: visible,
      label,
      value,
      id: value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    onLocalStorageChangeColumns(columns);
  }, [columns]);

  const onMetadataColumnsApply = (columnsToDisplay: string[]) =>
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        const isColumnVisible = columnsToDisplay.includes(column.value);
        return { ...column, visible: isColumnVisible };
      });
    });

  return (
    <>
      {activeIndexListOrSquareView === 0 && <CheckboxGroup onApply={onMetadataColumnsApply} defaultCheckboxes={defaultCheckBoxes} />}
      <Toggle
        iconOnly={true}
        buttons={[{ ssrIcon: list }, { ssrIcon: squareGrid }]}
        activeIndex={activeIndexListOrSquareView}
        onClick={onToggleListOrSquareView}
        data-testid="meta-data-toggler"
      />
    </>
  );
};
