import { useState } from 'react';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { MarketProgress } from 'types/sitemaps';

export const useSitemapsMarkets = (): MarketProgress[] => {
  const [markets, setMarkets] = useState<MarketProgress[]>([]);
  useRequestWithTokenAndTransformer<{ markets: string[] }, MarketProgress[]>({ url: '/api/sitemaps/settings', method: 'GET' }, [], (response) => {
    const markets = response.markets.map((marketString) => {
      const [language, country] = marketString.split('-');
      return { market: { country, language } } as MarketProgress;
    });
    setMarkets(markets);
    return markets;
  });
  return markets;
};
