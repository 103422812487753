import styled from '@emotion/styled';
import { space100 } from '@ingka/variables/design-tokens';
import { FlexContainer } from 'components/FlexContainer';

export const EditorContainer = styled(FlexContainer)`
  margin: ${space100} auto;
  width: 60vw;
  max-width: 80vw;
  gap: 1rem;
`;
