import {
  ApolloClient,
  ApolloLink,
  concat,
  defaultDataIdFromObject,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { effects } from 'redux/reducers/login';
import store from 'redux/store';

export const cache = new InMemoryCache({
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'MetaDataEntry':
      case 'MetaDataVersion':
        return object._id;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  return fromPromise(store.dispatch(effects.refreshToken())).flatMap((token) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return forward(operation);
  });
});
export const logoutLink = onError((errorResponse) => {
  if ((errorResponse.networkError as ServerError)?.statusCode === 401) {
    const dispatchResult = store.dispatch(effects.logoutAsync()) as unknown as Promise<{}>;
    return fromPromise(dispatchResult);
  }
});
export const httpLink = new HttpLink({ uri: '/api/optimera/graphql' });
export const client = new ApolloClient({
  link: logoutLink.concat(concat(authMiddleware, httpLink)),
  cache: cache,
});

export const testClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: logoutLink.concat(concat(authMiddleware, httpLink)),
    cache: cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });
