import { DependencyList, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GaxiosOptions, GaxiosResponse, request } from 'gaxios';
import { effects } from 'redux/reducers/login';
import { AppDispatch } from 'redux/store';

const useWithToken = <T>(cb: (token: string) => Promise<T>, deps: DependencyList): { loading: boolean; error?: string; data?: T } => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<T>();
  const dispatch: AppDispatch = useDispatch();
  const refreshToken = dispatch(effects.refreshToken());
  useEffect(() => {
    setLoading(true);
    refreshToken
      .then((token) => cb(token))
      .then((data) => setData(data))
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, deps);
  return { loading, error, data };
};

export const useRequestWithTokenAndTransformer = <T, U>(
  options: GaxiosOptions,
  deps: DependencyList,
  transformer: (resp: T) => U
): { loading: boolean; error?: string; data?: U } => {
  return useWithToken((token): Promise<U> => {
    const req = request<T>({
      ...options,
      headers: { ...options.headers, authorization: `Bearer ${token}` },
    }).then((res: GaxiosResponse<T>) => res.data);
    return req.then(transformer);
  }, deps);
};
