import styled from '@emotion/styled';
import Select from '@ingka/select';
import IngkaText from '@ingka/text';

export const Selector = styled(Select)`
  width: 5.3em;
  option[value='Choose an option'] {
    display: none;
  }
`;

export const Text = styled(IngkaText)`
  display: flex;
  align-items: center;
  padding: 0.625em;
  color: #111111;
  margin-right: 2.5px;
`;

export const Icons = styled.div`
  display: flex;
  padding: 0.625em;
  .svg-icon {
    margin: 0.3em;
    cursor: pointer;
  }
`;
