import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@ingka/button';
import Modal, { Sheets } from '@ingka/modal';
import { groupBy, mapObjIndexed, pipe, values } from 'ramda';
import { SearchField } from 'components/Search';
import { FlexedModalFooter } from 'components/styles';
import { useCountry } from 'hooks/useCountry';
import { getCountryName } from 'opt-util/helpers';
import { CountryGrouped } from 'types';
import { GetLocalesQuery, useGetLocalesQuery } from '__generated__/types';
import * as Styled from './styles';

const groupCountries: (l: GetLocalesQuery['locales']['myLocales']) => CountryGrouped[] = pipe(
  groupBy((l: GetLocalesQuery['locales']['myLocales'][0]) => l.country),
  mapObjIndexed((val, k) => ({
    countryCode: k.toLowerCase(),
    countryName: getCountryName(k).toLowerCase(),
  })),
  values
);

type RadioButtonItem = { id: string; label: string; value: string };

const radioButtonList = (
  groupedCountries: CountryGrouped[],
  setSelectedCountry: (country: CountryGrouped) => void,
  selectedCountry: CountryGrouped
): RadioButtonItem[] =>
  groupedCountries.map((country) => ({
    id: country.countryCode,
    label: `${country.countryName}, ${country.countryCode.toUpperCase()}`,
    value: country.countryCode,
    onChange: () => setSelectedCountry(country),
    checked: country.countryCode === selectedCountry?.countryCode,
  }));

type CountrySelectModalProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
};
export const CountrySelectModal: React.FC<CountrySelectModalProps> = ({ isOpen, toggleIsOpen }) => {
  const { data } = useGetLocalesQuery();
  const [country, setCountry] = useCountry();
  const { pathname } = useLocation();
  const [selectedCountry, setSelectedCountry] = useState<CountryGrouped | null>(country);
  const [searchText, setSearchText] = useState<string>();
  const { markets, readOnlyMarkets } = useMemo<{ markets: RadioButtonItem[]; readOnlyMarkets: RadioButtonItem[] }>(() => {
    if (data) {
      const {
        locales: { myLocales, otherLocales },
      } = data;

      const filterCountries = (array: CountryGrouped[], searchText?: string) => {
        const filteredMarkets = searchText
          ? array.filter((elem) => Object.values(elem).some((value) => value.toLowerCase().includes(searchText.toLowerCase())))
          : array;
        return filteredMarkets.sort((a, b) => (a.countryName < b.countryName ? -1 : 1));
      };
      const groupedMarkets = filterCountries(groupCountries(myLocales), searchText);
      const groupedReadOnlyMarkets = filterCountries(groupCountries(otherLocales), searchText);
      const countryToSelect = selectedCountry || groupedMarkets[0] || groupedReadOnlyMarkets[0];
      if (!selectedCountry) {
        setSelectedCountry(countryToSelect);
      }
      return {
        markets: radioButtonList(groupedMarkets, setSelectedCountry, countryToSelect),
        readOnlyMarkets: radioButtonList(groupedReadOnlyMarkets, setSelectedCountry, countryToSelect),
      };
    }
    return { markets: [], readOnlyMarkets: [] };
  }, [data, selectedCountry, searchText]);
  const onCountrySelection = () => {
    const fallbackPathRegEx = /\/redirects(?:\/[^\/]+)?|\/[^\/]+/;
    const fallbackPathMatch = pathname.match(fallbackPathRegEx);
    const redirectFallbackPath = fallbackPathMatch && fallbackPathMatch[0].includes('redirects') && `/redirects/${selectedCountry?.countryCode}`;
    selectedCountry && setCountry(selectedCountry, redirectFallbackPath || (fallbackPathMatch && fallbackPathMatch[0]) || '/');
    toggleIsOpen();
  };
  return (
    <Modal visible={isOpen} handleCloseBtn={toggleIsOpen} onModalClosed={() => setSelectedCountry(country)}>
      <Sheets
        alignment={'right'}
        size={'small'}
        header={<Styled.CountryModalHeader title='Markets' floating={false} />}
        footer={
          <FlexedModalFooter renderBorder={true}>
            <Button text='Cancel' type='secondary' />
            <Button text='Done' type='primary' onClick={onCountrySelection} />
          </FlexedModalFooter>
        }>
        <Styled.CountryFlexedModalBody>
          <SearchField id='market_search_input' placeholder='Search' type='text' onLetterSearch={(_value) => setSearchText(_value)} />
          <Styled.CountryModalRadioButtonGroup subtle={true} list={markets} name={'My markets'} />
          <Styled.CountryModalRadioButtonGroup subtle={true} list={readOnlyMarkets} name={'Other markets (read-only)'} />
        </Styled.CountryFlexedModalBody>
      </Sheets>
    </Modal>
  );
};
