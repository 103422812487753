import * as React from 'react';
import styled from '@emotion/styled';
import { any, isEmpty } from 'ramda';
import Table, { Column } from 'components/common/Table';
import { Message } from 'types';
const Error = styled.span`
  color: red;
`;
const columns: Column[] = [
  { key: 'id', label: 'ID' },
  {
    key: 'pageTitle',
    label: 'Page title',
  },
  {
    key: 'id',
    label: '',
    render: (val) => <Error>{`Entry with id ${val} will be removed`}</Error>,
  },
];
interface ErrorMessagesProps {
  deletedFiles: Message[];
}

const DeletedFiles: React.FC<ErrorMessagesProps> = ({ deletedFiles }) => {
  const filteredDeletedFiles = deletedFiles.filter((m) => any((r) => r.includes('Entry will be removed'), m.messages));
  return isEmpty(filteredDeletedFiles) ? null : (
    <>
      <hr />
      <h4>Rows that will be deleted</h4>
      <Table columns={columns} data={deletedFiles} />
    </>
  );
};

export default DeletedFiles;
