import styled from '@emotion/styled';
import { colourNeutral7, colourNeutral4, colourSemanticInformative } from '@ingka/variables/colours-css';
import { FlexContainer } from 'components/FlexContainer';
import Button from '@ingka/button';

type EditorContainerProps = {
  compact?: boolean;
};
export const EditorContainer = styled.div<EditorContainerProps>`
  height: ${(props) => (props.compact ? '8rem' : '100%')};
  border-style: solid;
  border-width: 1px;
  border-color: ${colourNeutral4};
  text-align: left;
  padding: 0.2rem 0.2rem;
  &:focus-within {
    border-color: ${colourSemanticInformative};
  }
  &:not(:focus-within):hover {
    border-color: ${colourNeutral7};
    cursor: pointer;
  }
  .editor-link {
    color: inherit;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const LeftTextIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 6px;
`;

export const TranslationButtonContainer = styled(FlexContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LinkUrlEditorContainer = styled(FlexContainer)`
  width: 100%;
`;

export const LinkUrlActionButtonContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 0.7rem;
`;
