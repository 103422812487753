import React from 'react';
import * as Styled from './styles';

export const NoData: React.FC<{ title?: string }> = ({ title = 'We cannot find the item you are searching for' }) => (
  <Styled.NoDataFlex direction={'column'} align={'center'}>
    <img alt='No Data' src={'/no_data.png'} />
    <span>No data</span>
    <p>{title}</p>
  </Styled.NoDataFlex>
);
