import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import Tabs, { Tab, TabPanel, TabsTabPanels, TabsTabs } from '@ingka/tabs';
import { ComparisonRow } from 'components/common/ComparisonTable';
import Container from 'components/common/Container';
import { NoData } from 'components/common/NoData';
import { Pagination } from 'components/common/Pagination';
import { Provider as MetaDataProvider } from 'hooks/contexts/MetaDataContext';
import { Row } from 'components/common/Row';
import { View } from 'components/seo/metadataView/View';
import { usePaginator } from 'hooks/usePaginator';
import { paginateData } from 'opt-util/paginate';
import { MetadataType } from 'types';
import { MetaDataEntry, ModifiedEntries, useDiffQuery } from '__generated__/types';
import { ErrorMessage } from 'components/common/styles';

interface SectionProps<T> {
  title: string;
  data: T[];
  renderItem: (item: T) => React.ReactElement;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Section = <T extends {}>(props: SectionProps<T>) => {
  const paginated = paginateData(props.data);
  const paginator = usePaginator({ totalItems: paginated.totalItems, itemsPerPage: 20 });
  const data = paginated.data[paginator.page];
  return (
    <div>
      <Pagination paginator={paginator} />
      {data.map(props.renderItem)}
    </div>
  );
};

type DiffViewComponentProps = {
  type: string;
  country: string;
  language: string;
  added: MetaDataEntry[];
  updated: ModifiedEntries[];
  deleted: MetaDataEntry[];
};

const DiffViewComponent: React.FC<DiffViewComponentProps> = (props) => {
  const nonEmptyUpdated = props.updated.filter((u) => u.new !== null && u.old !== null) as {
    new: MetaDataEntry;
    old: MetaDataEntry;
  }[];
  const noData = props.added.length === 0 && props.updated.length === 0 && props.deleted.length === 0;

  const tabs: TabsTabs[] = [];
  const tabPanels: TabsTabPanels[] = [];
  if (props.added.length > 0) {
    tabs.push(<Tab key={'tab-1'} tabPanelId={'added'} text={'Added'} />);
    tabPanels.push(
      <TabPanel key={'tab-panel-1'} tabPanelId={'added'}>
        <Section
          data={props.added}
          renderItem={(i) => {
            return (
              <Row key={i.id}>
                <View
                  data={{
                    ...i,
                    isOptimized: false,
                    needsReview: false,
                    searchConsoleData: null,
                    ts: null,
                    categoryId: null,
                    filterId: null,
                    mdToHtml: null,
                    author: null,
                    diffVersionId: null,
                    homeFurnishingBusiness: null,
                    hasProducts: null,
                  }}
                  type={props.type as MetadataType}
                  hideBadges={true}
                />
              </Row>
            );
          }}
          title={'Added'}
        />
      </TabPanel>
    );
  }

  if (props.updated.length > 0) {
    tabs.push(<Tab key={'tab-2'} tabPanelId={'modified'} text={'Modified'} />);
    tabPanels.push(
      <TabPanel key={'tab-panel-2'} tabPanelId={'modified'}>
        <Section
          title={'Modified'}
          renderItem={(i) => {
            return (
              <Row key={i.new.id}>
                <ComparisonRow new={i.new} old={i.old} type={props.type} />
              </Row>
            );
          }}
          data={nonEmptyUpdated}
        />
      </TabPanel>
    );
  }

  if (props.deleted.length > 0) {
    tabs.push(<Tab key={'tab-3'} tabPanelId={'removed'} text={'Removed'} />);
    tabPanels.push(
      <TabPanel key={'tab-panel-3'} tabPanelId={'removed'}>
        <Section
          title={'Removed'}
          data={props.deleted}
          renderItem={(i) => {
            return (
              <Row key={i.id}>
                <View
                  data={{
                    ...i,
                    isOptimized: false,
                    needsReview: false,
                    searchConsoleData: null,
                    categoryId: null,
                    filterId: null,
                    ts: null,
                    mdToHtml: null,
                    author: null,
                    diffVersionId: null,
                    homeFurnishingBusiness: null,
                    hasProducts: null,
                  }}
                  type={props.type as MetadataType}
                  hideBadges={true}
                />
              </Row>
            );
          }}
        />
      </TabPanel>
    );
  }

  const getDefaultActiveTab = () => {
    if (props.added.length > 0) {
      return 'added';
    }
    if (nonEmptyUpdated.length > 0) {
      return 'modified';
    }
    if (props.deleted.length > 0) {
      return 'deleted';
    }
  };

  return (
    <Container page={'diff'} country={props.country} language={props.language}>
      {!noData && <Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab={getDefaultActiveTab()} />}
      {noData && <NoData title={'There are no differences between versions'} />}
    </Container>
  );
};

export const DiffViewContainer: React.FC<{ id: string }> = (props) => {
  const { loading, error, data } = useDiffQuery({
    variables: { id: props.id },
  });
  if (loading) {
    return (
      <Loading text={'Please wait...'}>
        <LoadingBall />
      </Loading>
    );
  } else if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  } else if (data && data.version && data.version.diff) {
    return (
      <MetaDataProvider>
        <DiffViewComponent
          type={data.version.type}
          added={data.version.diff.added}
          updated={data.version.diff.updated}
          deleted={data.version.diff.deleted}
          country={data.version.country}
          language={data.version.language}
        />
      </MetaDataProvider>
    );
  } else {
    return <div>No data</div>;
  }
};
