import React, { ChangeEvent } from 'react';
import FormField from '@ingka/form-field';
import { InputTextField } from './styles';

interface TextInputFieldProps {
  placeholder?: string;
  disabled?: boolean;
  label: string;
  shouldValidate?: boolean;
  errorMessage?: string;
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fieldHelper?: string;
  ariaLabelledby?: string;
  characterLimit?: number;
}

export const TextInputField: React.FC<TextInputFieldProps> = ({
  id,
  placeholder,
  disabled,
  label,
  shouldValidate,
  errorMessage,
  value,
  onChange,
  fieldHelper,
  ariaLabelledby,
  characterLimit,
}) => (
  <FormField
    validation={{
      msg: errorMessage,
      id: `${id}-error-message`,
    }}
    characterLimit={characterLimit}
    fieldHelper={fieldHelper && label !== 'URL Slug' ? { msg: fieldHelper } : undefined}
    valid={!errorMessage}
    shouldValidate={shouldValidate}
    disabled={disabled}
  >
    <InputTextField
      aria-labelledby={ariaLabelledby}
      readOnly={disabled}
      id={id}
      name={id}
      label={label}
      placeholder={placeholder}
      type="text"
      onChange={onChange}
      value={value}
    />
  </FormField>
);
