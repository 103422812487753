import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const FloatingMenuContent = styled.div<{ leftPosition?: string }>`
  position: absolute;
  border: 1px solid #ddd;
  left: ${(props) => (props.leftPosition ? props.leftPosition : '-150px')};
  z-index: 102;
  background-color: white;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
