import React from 'react';
import { useDispatch } from 'react-redux';
import { FileUploadForm } from 'components/seo/FileUploadForm';
import { effects } from 'redux/reducers/login';
import { AppDispatch } from 'redux/store';
import { ResultColumn, ResultContainer } from 'components/admin/styles';
import { MenuItem, MenuList } from 'components/seo/styles';

const postFile = async (file: File, dispatch: AppDispatch) => {
  const data = new FormData();
  data.append('file', file);
  const refreshToken = () => dispatch(effects.refreshToken());
  const token: string = await refreshToken();

  return await fetch(`/api/optimera/masterkeywords/upload`, {
    method: 'POST',
    body: data,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export interface UploadResult {
  rows: number;
  status: string;
  entriesWithoutMatch: [{ id: string; name: string }];
  entriesUpdated: [{ id: string; name: string }];
}

const CustomMessage: React.FC = () => (
  <div>
    <h3>Upload Keywords CSV File</h3>
    <p>
      The uploaded CSV file will update the entries in Country Zero with the given Primary, Secondary and Alternative Keywords.
      <br />
      The keywords will then be displayed in all countries as Suggested Keywords for each entry.
      <br />
      <br />
    </p>
  </div>
);

const customResult = (response: UploadResult): JSX.Element => (
  <ResultContainer>
    <ResultColumn>
      <h4>
        Updated rows: <strong data-testid={'updated-rows'}>{response.entriesUpdated.length}</strong>
      </h4>
      <MenuList>
        {response.entriesUpdated.map((e) => (
          <MenuItem key={e.id}>
            {e.id} - {e.name}
          </MenuItem>
        ))}
      </MenuList>
    </ResultColumn>

    <ResultColumn>
      <h4>
        Entries without match: <strong data-testid={'entries-without-match'}>{response.entriesWithoutMatch.length}</strong>
      </h4>
      <MenuList>
        {response.entriesWithoutMatch.map((e) => (
          <MenuItem key={e.id}>
            {e.id} - {e.name}
          </MenuItem>
        ))}
      </MenuList>
    </ResultColumn>
  </ResultContainer>
);

export const Keywords: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  return <FileUploadForm customMessage={<CustomMessage />} customResult={customResult} postFile={(file) => postFile(file, dispatch)} />;
};
