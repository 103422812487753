import React from 'react';
import { FormFields as FormFieldsType } from 'types';
import * as StyledCommon from 'components/common/styles';
import * as StylesMetaData from './styles';

const Styled = { ...StylesMetaData, ...StyledCommon };

interface FormFieldsProps {
  fields: FormFieldsType[];
}

export const EditSheetForm: React.FC<FormFieldsProps> = ({ fields }) => {
  const targetFields = ['pageTitle', 'ogTitle', 'description', 'ogDescription'];
  const urlSlugField = fields.find((field) => field.props.key === 'urlSlug') ?? fields[1];

  const targetFieldsOrder = (a: FormFieldsType, b: FormFieldsType) => {
    const indexA = targetFields.indexOf(a.props.id);
    const indexB = targetFields.indexOf(b.props.id);

    return indexA - indexB;
  };

  const isTargetFieldExists = (field: FormFieldsType) => targetFields.includes(field.props.key);
  const groupedFields = fields.filter(isTargetFieldExists).sort(targetFieldsOrder);
  return (
    <>
      <div key={urlSlugField.props.key}>
        {urlSlugField.props.fieldHelper}
        <urlSlugField.component {...urlSlugField.props} />
      </div>
      <Styled.FormGroup>
        {groupedFields.map((field) => (
          <Styled.FormGroupItem key={field.props.key}>
            <field.component {...field.props} />
            {field.props.fieldHelper && <Styled.Divider />}
          </Styled.FormGroupItem>
        ))}
      </Styled.FormGroup>
      {fields.map((field) => {
        if (targetFields.includes(field.props.key) || field.props.key === 'descriptionIsSynced' || field.props.key === 'urlSlug') return null;

        return (
          <div key={field.props.key}>
            <field.component {...field.props} />
            {field.props.fieldHelper && <Styled.Divider />}
          </div>
        );
      })}
    </>
  );
};
