import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import { Option } from '@ingka/select';
import Tabs, { Tab } from '@ingka/tabs';
import languages from 'iso-639-1';
import Container from 'components/common/Container';
import { DashboardTabPanel } from 'features/Dashboard/DashboardTabPanels';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { useCountry } from 'hooks/useCountry';
import { username } from 'redux/selectors/login';
import { LatestByCountryQuery, useLatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';

export type DashboardVersion = {
  id: string;
  type: string;
  languageCode: string;
  languageName: string;
  countryCode: string;
  size: number;
  needsReview: number;
  optimizedItems: number;
  triggerType?: string;
};
type Language = Pick<DashboardVersion, 'languageName' | 'languageCode' | 'countryCode'>;
type VersionsForCountry = LatestByCountryQuery['latestVersionsForCountry'];
type VersionForCountry = VersionsForCountry[0];
const formatVersion = (version: VersionForCountry): DashboardVersion => ({
  id: version._id,
  type: version.type.toLowerCase(),
  languageCode: version.language,
  languageName: languages.getName(version.language),
  countryCode: version.country,
  size: version.size,
  needsReview: version.needsReview,
  optimizedItems: version.optimizedItems,
});

const getSelectableLanguages = (versions: DashboardVersion[], countryCode: string): Language[] => {
  const uniqueLanguages = new Map<string, Language>();
  versions.forEach(({ languageCode, languageName }) => {
    if (!uniqueLanguages.has(languageCode)) {
      uniqueLanguages.set(languageCode, { languageCode, languageName, countryCode });
    }
  });
  return Array.from(uniqueLanguages.values());
};

const getSelectedVersionsForLanguage = (versions: DashboardVersion[], selectedLanguage: string): DashboardVersion[] => {
  if (selectedLanguage === '*') {
    const languageVersionMap = new Map<string, DashboardVersion>();
    versions.forEach((version) => {
      const prevVersion = languageVersionMap.get(version.type);
      if (!prevVersion) {
        languageVersionMap.set(version.type, version);
      } else {
        const aggregatedVersion: DashboardVersion = {
          ...prevVersion,
          size: prevVersion.size + version.size,
          optimizedItems: prevVersion.optimizedItems + version.optimizedItems,
          needsReview: prevVersion.needsReview + version.needsReview,
        };
        languageVersionMap.set(version.type, aggregatedVersion);
      }
    });
    return Array.from(languageVersionMap.values());
  }
  return versions.filter((version) => version.languageCode === selectedLanguage);
};

export const Dashboard: React.FC = () => {
  const [country] = useCountry();
  const countryCode = country?.countryCode;
  const history = useHistory();
  const email = useSelector(username);
  const userFirstName = email[0].toUpperCase() + email.slice(1).split('.').shift();
  const { type } = useParams<{ type?: string }>();
  const { setRefetchAfter } = useContext(RulesContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('*');
  const { data, loading } = useLatestByCountryQuery({ variables: { country: countryCode as string }, skip: !countryCode });
  const versionsForCountry = data?.latestVersionsForCountry;
  const possiblePageTypes = ['pip', 'plp', 'filter', 'redirect', 'shorturl'];
  const formattedVersions = versionsForCountry?.map(formatVersion) ?? [];
  const selectableLanguages = getSelectableLanguages(formattedVersions, countryCode ? countryCode : '');
  const selectedVersions = getSelectedVersionsForLanguage(formattedVersions, selectedLanguage === '*' ? '*' : selectedLanguage.substring(3, 5));
  const activeTab = type && possiblePageTypes.includes(type.toLowerCase()) ? type : 'pip';

  if (loading) {
    return (
      <Container page='Dashboard'>
        <Loading text={'Loading...'}>
          <LoadingBall />
        </Loading>
      </Container>
    );
  }

  return (
    <Container page='Dashboard'>
      <Styled.DashboardHeaderWrapper>
        <h1>Hej {userFirstName}</h1>
        <Styled.LanguageDropdown
          id='languageSelector'
          data-testid={'dashboard-language-select'}
          onChange={(e) => {
            setSelectedLanguage(e.target.value);
            setRefetchAfter(`Dashboard-${e.target.value}`);
          }}
          value={selectedLanguage}>
          <Option value={'*'} name={'All Languages'} />
          {selectableLanguages?.map(({ languageCode, languageName, countryCode }) => (
            <Option key={languageCode} value={`${countryCode}-${languageCode}`} name={languageName} />
          ))}
        </Styled.LanguageDropdown>
      </Styled.DashboardHeaderWrapper>
      <Tabs
        tabs={possiblePageTypes.map((type) => (
          <Tab key={type} tabPanelId={type} text={type.toUpperCase()} onClick={() => history.push(`/dashboard/${type.toLowerCase()}`)} />
        ))}
        tabPanels={
          <DashboardTabPanel
            possiblePageTypes={possiblePageTypes}
            activeTab={activeTab}
            selectedVersions={selectedVersions}
            selectedLanguage={selectedLanguage}
          />
        }
        activeTab={activeTab}
      />
    </Container>
  );
};
